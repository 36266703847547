<template>
	<div class="general-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<div class="content-wrapper">
				<div v-if="state === 'idle'">
					<div v-if="requests.length > 0">
						<div v-for="request in requests" :key="request.sessionId">
							Request from {{ request }}

							<button
								type="button"
								v-on:click="acceptInviteToConnect(request)"
								class="btn btn-outline-secondary"
								value="Accept"
							>
								Accept
							</button>
							<button
								type="button"
								v-on:click="declineInviteToConnect(request)"
								class="btn btn-outline-secondary"
								value="Accept"
							>
								Decline
							</button>
						</div>
					</div>
					<div v-else>No pending requests. My Id {{ this.videoChatService.currentUserId }}</div>
					<div>
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Id</th>
									<th>Name</th>
									<th>Options</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="user in users" :key="user.id">
									<td>{{ user.id }}</td>
									<td>{{ user.name }}</td>
									<td>
										<button
											type="button"
											v-on:click="inviteToConnect(user)"
											class="btn btn-outline-secondary"
											value="Connect"
										>
											Connect
										</button>
									</td>
								</tr>
							</tbody>
						</table>
						<div>
							Connect to existing:
							<div>
								<span>auth token</span>
								<input v-model="connectionDetails.jwt" placeholder="enter authorization" />
								<span>room name</span>
								<input v-model="connectionDetails.roomName" placeholder="enter room name" />
							</div>
							<button type="button" v-on:click="joinExisting()" class="btn btn-outline-secondary" value="Join">Join</button>
						</div>
					</div>
				</div>
				<div v-else-if="state === 'connecting'">
					Connecting
				</div>
				<div v-else class="player-wrapper">
					<jitsi-video-chat
						v-bind:config="config"
						v-bind:jwt="connectionDetails.jwt"
						v-bind:roomName="connectionDetails.roomName"
						v-on:exit="finishConnection"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// https://vue-view.com/how-to-load-an-external-script-in-vue-component/
import Background from "../components/Background";
import JitsiVideoChat from "../components/videochat/JitsiVideoChat";
import { log } from "@/logging";

const myLoggingName = "VideoChat";

export default {
	name: "VideoChatPage",
	components: { Background, JitsiVideoChat },
	inject: ["videoChatService"],
	data: () => {
		return {
			requests: [],
			state: "idle",
			connectionDetails: {},
			users: [],
			myAnalyticsContext: null
		};
	},
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	mounted() {
		// TODO The plan is to move the video chat stuff to it's own component and clean up the workflow
		// until then not that the establishConnection call is redundant
		this.videoChatService.setListener(this.handleEvent);
		this.transitToIdle();
		this.videoChatService.establishConnection();
		this.users = this.getAvailableUsers();
		this.myAnalyticsContext = this.analyticsContext?.addChild("videochat");
		log(myLoggingName, "mounted");
	},
	beforeDestroy() {
		this.videoChatService.disconnect();
	},
	methods: {
		getAvailableUsers() {
			// May have to use computed or watcher. May also use other source of users?
			return this.videoChatService.getOtherUsers();
		},
		inviteToConnect(user) {
			this.state = "connecting";
			this.videoChatService.inviteToConnect([user.id]);
		},
		transitToIdle() {
			this.state = "idle";
			this.connectionDetails = {};
			this.myAnalyticsContext?.egress("closed");
			//this.videoChatService.establishConnection();
		},
		transitToConnected(connectionDetails) {
			this.requests = [];
			this.connectionDetails = connectionDetails;
			this.state = "connected";
			this.myAnalyticsContext?.ingress(new Map());
			//this.videoChatService.disconnect();
		},
		acceptInviteToConnect(invite) {
			this.transitToConnected({ jwt: invite.authenticationToken, roomName: invite.sessionId });
		},
		joinExisting() {
			this.transitToConnected(this.connectionDetails);
		},
		declineInviteToConnect(invite) {
			// Remove the invite
			this.requests = this.requests.filter(request => request.hostId !== invite.hostId);
		},
		finishConnection() {
			this.transitToIdle();
		},
		handleEvent(event) {
			log(myLoggingName, "VideoChat Handling ", event);

			if (event.type === "VideoChatRequest") {
				switch (this.state) {
					case "connecting":
						log(myLoggingName, "handleEvent - connecting handler");
						if (event.data.hostId === this.videoChatService.currentUserId) {
							this.transitToConnected({ jwt: event.data.authenticationToken, roomName: event.data.sessionId });
						} else {
							this.requests.push(event.data);
						}
						break;
					case "connected":
						log(myLoggingName, "handleEvent - connected handler");
						break;
					default:
						log(myLoggingName, "handleEvent - default handler");
						this.requests.push(event.data);
				}
			} else if (event.type === "UsersUpdated") {
				this.users = this.getAvailableUsers();
			} else if (event.type === "ErrorMessage") {
				switch (this.state) {
					case "connecting":
						log(myLoggingName, "handleEvent - connecting handler");
						this.transitToIdle();
						break;
					default:
						log(myLoggingName, "handleEvent - default handler");
				}
			} else if (event.type === "ConnectionClosed") {
				switch (this.state) {
					case "connected":
						// do nothing, auto reconnect
						log(myLoggingName, "handleEvent - connected handler");
						break;
					case "connecting":
						// transit to idle as response may never arrive - may change this once video chat service supports stateful request management across connections.
						log(myLoggingName, "handleEvent - connecting handler");
						this.transitToIdle();
						break;
					default:
						log(myLoggingName, "handleEvent - default handler");
				}
			} else {
				log(myLoggingName, "handleEvent - Unsupported event type. Doing nothing");
			}
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.content-wrapper {
	width: 100%;
	height: 100%;
	max-width: 1300px;
	box-sizing: content-box;
	margin: auto;
}

.player-wrapper {
	position: relative;
	$player-width-percent: 100%;
	width: $player-width-percent;
	height: 0px;
	padding-bottom: calc(#{$player-width-percent} * (9 / 16)); // padding MUST be equal to width: forces 16:9 aspect ratio
}
</style>
