<template>
	<div>
		<!-- <video-player :options="videoOptions" :style="this.config.style" class="video-player vjs-fill" /> -->
		<video-js-player :config="config" :mode="mode" v-on:playerEvent="playerEvent" :key="redrawKey" />
	</div>
</template>
<script>
import VideoJsPlayer from "@/components/VideoJsPlayer.vue";

// A wrapper around the video-js-player that will re-render the player when there is an error - used as a kludge to get around the fact that the player freezes if play is called and the video stream is not ready
export default {
	name: "VideoJSPlayerWrapper",
	props: {
		config: Object
	},
	components: {
		VideoJsPlayer
	},
	data() {
		return {
			redrawKey: 0,
			currentMode: {},
			retryCount: 0
		};
	},
	computed: {
		mode() {
			return this.currentMode;
		}
	},
	methods: {
		playerEvent(eventType, ...args) {
			switch (eventType) {
				case "playerStartedOK":
					this.playerStartedOK();
					break;
				case "playerError":
					this.playerError(args[0]);
					break;
				case "playerEnded":
					this.playerEnded();
					break;
				default:
			}
		},
		playerStartedOK() {
			if (this.retryCount > 0) {
				this.retryCount = 0;
			}
		},
		playerEnded() {
			const currentMode = {};
			Object.assign(currentMode, this.config.mode);
			currentMode.autoPlay = false;
			this.currentMode = currentMode;
			this.redrawKey++;
		},
		playerError(retry) {
			// On error, re-render the playe
			// If there are retry instructions, and the retry count is less than max retries, then use them
			// else use the initial mode, but with auto play turned off.
			if (retry) {
				if (this.retryCount > retry.maxRetries || !retry.mode) {
					const currentMode = {};
					Object.assign(currentMode, this.config.mode);
					currentMode.autoPlay = false;
					this.currentMode = currentMode;
				} else {
					this.currentMode = retry.mode;
				}
			} else {
				const currentMode = {};
				Object.assign(currentMode, this.config.mode);
				currentMode.autoPlay = false;
				this.currentMode = currentMode;
			}
			this.retryCount++;
			this.redrawKey++;
		}
	},
	created() {
		const currentMode = {};
		Object.assign(currentMode, this.config.mode);
		this.currentMode = currentMode;
	}
};
</script>
<style lang="scss">
@import "../scss/constants";
</style>
