<template>
	<div class="signin-component">
		<div class="signin-component-wrapper">
			<b-form form id="signinForm">
				<div class="title">
					{{ text.getByKey("title", { default: "Sign-in", en: "Sign-in", fr: "Connexion" }) }}
				</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("instructions", {
							default: "Please start by entering your email address",
							en: "Please start by entering your email address",
							fr: "Veuillez commencer par saisir votre adresse de courriel"
						})
					}}
				</div>
				<br />
				<div class="form-group">
					<input
						class="form-control"
						type="email"
						autocomplete="username"
						id="emailInputSignin"
						:placeholder="
							text.getByKey('userName', {
								default: 'Email',
								en: 'Email',
								fr: 'E-mail'
							})
						"
						required
						v-model="userName"
					/>
					<div class="form-error" v-show="userNameError">
						{{ text.get(userNameError) }}
					</div>
				</div>
				<b-button id="signinButton" idtype="button" v-on:click="signin" variant="outline-dark" value="Sign-in">
					{{ text.getByKey("signinbutton", { default: "Sign-in", en: "Sign-in", fr: "Connexion" }) }}
				</b-button>
			</b-form>
			<br />
			<br />
			<div id="signinHelp" class="instructions">
				{{
					text.getByKey("needHelp", {
						default: "Need help? Please contact",
						en: "Need help? Please contact",
						fr: "Vous avez besoin d'aide ? Veuillez contacter"
					})
				}}

				<b-link v-bind:href="supportEmail">{{ supportHost }}</b-link>
			</div>
		</div>
	</div>
</template>

<script>
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			userName: "",
			userNameError: ""
		};
	},
	props: {
		common: Object,
		text: Object
	},
	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		}
	},
	computed: {
		supportEmail() {
			return "mailto:" + (this.common.supportEmail ?? "support@kingevents.ca");
		},
		supportHost() {
			return this.common.supportEmail ?? "support@kingevents.ca";
		}
	},

	name: "PasswordlessSignIn",
	methods: {
		signin: function() {
			let error = false;
			if (this.userName.length == 0) {
				this.userNameError = this.text.getByKey("userNameRequiredError", {
					default: "Email required",
					en: "Email required",
					fr: "Email requis"
				});
				error = true;
			}
			if (error) {
				return;
			}

			if (this.userName) {
				this.$emit("login", { userName: this.userName });
			}
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.signin-component-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.signin-component-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
