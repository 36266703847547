<template>
	<div class="registration-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="registration-wrapper">
			<div class="client-logo">
				<img class="logo-img" :src="common.logo" alt="" />
			</div>
			<div class="form-error" v-if="error">
				{{ error }}
			</div>
			<div class="self-register" v-if="activeComponent === 'Register'">
				<span v-show="registerMode === 'Active'">
					<self-register
						v-if="!this.authService.isPasswordlessMode()"
						v-bind:config="config.registration"
						v-bind:common="common"
						v-on:input="passwordfulUserRegistrationGathered"
						:text="text.shiftContext('passwordful')"
					>
					</self-register>
					<passwordless-self-register
						v-else
						v-bind:config="config.registration"
						v-bind:common="common"
						v-on:input="passwordlessUserRegistrationGathered"
						v-on:loginIfExists="passwordlessLoginIfExists"
						:text="text.shiftContext('passwordless')"
					></passwordless-self-register>
				</span>
				<span v-show="registerMode === 'Waiting'">
					<b-spinner variant="primary" label="Waiting" style="position: absolute; top: calc(50%)"></b-spinner>
				</span>
			</div>
			<!-- <div class="post-registration-wrapper" v-if="activeComponent === 'PostRegister'"> -->
			<!-- optionally display a page with stuff. If there is no stuff to display, display registration success! with button to send person to default page-->
			<!-- Registration Completed!!!
				<br />
				<button type="button" v-on:click="finished" class="btn btn-outline-secondary" value="Go to Venue">
					Go to Venue
				</button>
			</div> -->
			<div class="confirmation-code-wrapper" v-if="activeComponent === 'ConfirmationCode'">
				<div class="instructions">
					{{ completeRegistrationInstructions }}
				</div>
				<br />
				<br />
				<div class="form-group">
					<div class="form-row" v-if="completionMode === 'external'">
						<input
							class="form-control"
							type="text"
							id="emailForVerificationCode"
							placeholder="Email address"
							v-model="userName"
							pattern=".*"
						/>
					</div>
				</div>
				<br />
				<div class="form-group">
					<div class="form-row">
						<input
							class="form-control"
							type="text"
							id="verificationCode"
							placeholder="Verification Code"
							v-model="verificationCode"
							pattern=".*"
						/>
					</div>
					<div class="form-error" v-show="verificationCodeError">{{ verificationCodeError }}</div>
				</div>
				<br />

				<b-button-group>
					<b-button
						type="button"
						v-on:click="completeRegistration"
						class="btn btn-outline-secondary"
						variant="outline-dark"
						value="Complete Registration"
					>
						{{
							text.getByKey("completeRegistrationButton", {
								default: "Complete Registration",
								en: "Complete Registration",
								fr: "Enregistrement complet"
							})
						}}
					</b-button>
					<b-button
						type="button"
						v-on:click="restartRegistration"
						class="btn btn-outline-secondary"
						variant="outline-dark"
						value="Restart Registration"
					>
						{{
							text.getByKey("restartRegistrationButton", {
								default: "Restart Registration",
								en: "Restart Registration",
								fr: "Redémarrer l'enregistrement"
							})
						}}
					</b-button>
				</b-button-group>
			</div>

			<br />
			<span v-show="activeComponent === 'Waiting'">
				<b-spinner variant="primary" label="Waiting" style="position: absolute; top: calc(50%)"></b-spinner>
			</span>
		</div>
	</div>
</template>
<script>
import Background from "../components/Background";
import SelfRegister from "../components/SelfRegister";

import { log } from "@/logging";
import PasswordlessSelfRegister from "@/components/PasswordlessSelfRegister.vue";

const myLoggingName = "Registration";

export default {
	name: "Registration",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object,
		homePage: String
	},
	data: () => ({
		activeComponent: "Register",
		registerMode: "Active",
		error: "",
		userName: "",
		password: "",
		verificationCode: "",
		verificationCodeError: "",
		wasMounted: false,
		myAnalyticsContext: null,
		completionMode: "internal"
	}),
	inject: ["authService", "notificationService", "textService"],
	components: {
		Background,
		SelfRegister,
		PasswordlessSelfRegister
	},
	mounted() {
		log(myLoggingName, "mounted");

		// workflow
		// gather email and password
		// optionally gather attributes
		// user clicks register
		// attempt to create account
		// if initial step OK, switch to ask for validation code (need to possibly deal with email link - if same email used for validate code and admin password reset then workflow needs to be tweaked)
		// confirm validation code
		// if OK, optionally display post regstration with button to send user to default page. If no post registration page, then send to default page.
		this.initialState();
		if (!this.wasMounted && this.$route.params.event === "completeRegistration" && !this.authService.isPasswordlessMode()) {
			this.switchToConfirmationCode(this.$route.params.code ?? null);
		}
		this.wasMounted = true;
		this.myAnalyticsContext = this.analyticsContext;
	},

	beforeUpdate() {
		// Likely unnecessary sanity check

		if (this.activeComponent !== "Register" && this.activeComponent !== "ConfirmationCode" && this.activeComponent !== "Waiting") {
			this.activeComponent = "Register";
		}
	},
	computed: {
		completeRegistrationInstructions() {
			if (this.userName) {
				return this.text.getByKey("completeRegistrationInstructionsUserName", {
					default: "Please enter the validation code from your registration confirmation email.",
					en: "Please enter the validation code from your registration confirmation email.",
					fr: "Veuillez entrer le code de validation de votre e-mail de confirmation d'inscription."
				});
				//return "Please enter the validation code from your registration confirmation email.";
			} else {
				return this.text.getByKey("completeRegistrationInstructionsNoUserName", {
					default: "Please enter your email address and the validation code from your registration confirmation email.",
					en: "Please enter your email address and the validation code from your registration confirmation email.",
					fr: "Veuillez entrer votre adresse e-mail et le code de validation de votre e-mail de confirmation d'inscription."
				});
				//return "Please enter your email address and the validation code from your registration confirmation email.";
			}
		},
		text() {
			return this.textService.copy(this.config.text, ["root", this.$route.name]);
		}
	},
	methods: {
		clearError: function() {
			this.error = "";
		},
		resetData: function() {
			this.userName = null;
			this.password = null;
			this.verificationCode = null;
			this.completionMode = "internal";
		},
		initialState: function() {
			this.activeComponent = "Register";
			this.registerMode = "Active";
			this.clearError();
			this.resetData();
			this.verificationCodeError = "";
		},
		restartRegistration() {
			this.initialState();
			return;
		},
		// Handle registration information gathered
		passwordfulUserRegistrationGathered: function(credentials) {
			let capturedVue = this;
			capturedVue.clearError();
			log(myLoggingName, "userRegistrationGathered - entry");
			this.authService
				.signUp(credentials.userName, credentials.attributes, credentials.password)
				.then(() => {
					capturedVue.userName = credentials.userName;
					capturedVue.password = credentials.password;
					this.switchToConfirmationCode();
				})
				.catch(err => {
					log(myLoggingName, "Registration - Error " + err);
					// capturedVue.error =
					// 	"Registration failed. Please try again. If you have already registered with this email address, please login instead.";
					capturedVue.error = this.text.getByKey("genericRegistrationError", {
						default:
							"Registration failed. Please try again. If you have already registered with this email address, please login instead.",
						en:
							"Registration failed. Please try again. If you have already registered with this email address, please login instead.",
						fr:
							"Échec de l'enregistrement. Veuillez réessayer. Si vous êtes déjà inscrit avec cette adresse e-mail, veuillez vous connecter à la place."
					});
					capturedVue.resetData();
				});
		},
		passwordlessUserRegistrationGathered: function(credentials) {
			let capturedVue = this;
			capturedVue.clearError();
			log(myLoggingName, "userRegistrationGathered - entry");
			this.authService
				.passwordlessSignup(credentials.userName, credentials.attributes, credentials.password)
				.then(() => {
					capturedVue.autoLoginPostPasswordChange(credentials);
				})
				.catch(err => {
					log(myLoggingName, "Registration - Error " + err);
					// capturedVue.error =
					// 	"Registration failed. Please try again. If you have already registered with this email address, please login instead.";
					capturedVue.error = this.text.getByKey("genericRegistrationError", {
						default:
							"Registration failed. Please try again. If you have already registered with this email address, please login instead.",
						en:
							"Registration failed. Please try again. If you have already registered with this email address, please login instead.",
						fr:
							"Échec de l'enregistrement. Veuillez réessayer. Si vous êtes déjà inscrit avec cette adresse e-mail, veuillez vous connecter à la place."
					});

					capturedVue.resetData();
				});
		},
		passwordlessLoginIfExists: function(credentials) {
			const capturedVue = this;
			this.registerMode = "Waiting";
			this.authService.passwordlessLogin(credentials.userName).then(isLoggedIn => {
				if (isLoggedIn) {
					log(myLoggingName, "passwordlessLoginIfExists", "exists");
					capturedVue.resetData();
					capturedVue.finished();
				} else {
					capturedVue.registerMode = "Active";
				}
			});
		},

		trackLogin() {
			try {
				const values = new Map();
				if (this.config.analytics.captureAttributes?.length > 0) {
					const user = this.authService.user;
					this.config.analytics.captureAttributes.forEach(key => {
						const value = {
							type: "String",
							value: user.attributes[key]
						};
						values.set("userAttribute" + key, value);
					});

					const event = this.myAnalyticsContext.makeEvent("login", "event", values);
					this.myAnalyticsContext.recordEvent(event);
				}
			} catch (err) {
				log(myLoggingName, "analytics", "error", err);
			}
		},

		// User is registering for passwordless and requested (or forced) to supply attributes
		switchToConfirmationCode: function(code = null) {
			this.clearError();
			log(myLoggingName, "switchToConfirmationCode ");
			this.activeComponent = "ConfirmationCode";
			this.error = "";
			this.verificationCode = code;
			this.verificationCodeError = "";
			if (code) {
				this.completionMode = "external";
			}
		},

		// Complete the password reset - send code and new password to Authentication service
		completeRegistration: function() {
			this.clearError();
			let capturedVue = this;
			log(myLoggingName, "completeRegistration");
			this.authService
				.confirmSignUp(capturedVue.userName, capturedVue.verificationCode)
				.then(() => {
					log(myLoggingName, "completeNewCodeAndPassword - password set");
					this.autoLoginPostPasswordChange({ userName: capturedVue.userName, password: capturedVue.password });
				})
				.catch(err => {
					log(myLoggingName, "completeNewCodeAndPassword - exception ", err);
					capturedVue.error = "Confirmation code failed. Please try again.";
					capturedVue.error = this.text.getByKey("confimationCodeError", {
						default: "Confirmation code failed. Please try again.",
						en: "Confirmation code failed. Please try again.",
						fr: "Le code de confirmation a échoué. Veuillez réessayer."
					});
				});
		},
		createPostRegistrationNotificiation: function(configuration) {
			let tags = new Map();
			Object.keys(configuration.miscTags).forEach(key => {
				tags.set(key, this.text.get(configuration.miscTags[key]));
			});
			const action = this.text.get(configuration.name);
			let sendNotice = {
				action: action,
				attributeNames: configuration.attributeNames,
				miscTags: tags
			};
			return sendNotice;
		},
		sendPostRegistrationNotice: function() {
			if (this.config.registration.notification.postRegistrationAction) {
				let sendNotice = this.createPostRegistrationNotificiation(this.config.registration.notification.postRegistrationAction);
				this.notificationService.process(sendNotice, this.authService.token);
			}
		},
		autoLoginPostPasswordChange: function(credentials) {
			this.activeComponent = "Waiting";
			// Try to login. Whether successful or not send to the post registration page.
			// The reason - users get here when successfully registered. It's a helper method than in certain circumstances can auto log the user in, but not always.
			// If we can't login them in, it may be a hiccup OR may indicate some other error. It may be the case that the error can be resolved later or will clear itself up.
			// AND The post registration page may not require authenticated access.
			// If it does require access, then it will force the user to attempt a manual login - which will either work or put the user in the login workflow which can better deal with login errors.
			// Since solving the problem here involves duplicating login access logic, it makes sense to not try to solve it here.
			// So - redirect to post registration on success of error - i.e. call finished.
			if (
				!this.config.registration.isAutoLoginAfterPasswordChange ||
				(!this.authService.isPasswordlessMode() && !credentials.password)
			) {
				this.finished();
				return;
			}
			let capturedVue = this;
			this.clearError();
			log(myLoggingName, "autoLoginPostPasswordChange - Attempting to validate credentials", {
				passwordless: this.authService.isPasswordlessMode()
			});
			if (this.authService.isPasswordlessMode()) {
				this.authService
					.passwordlessLogin(credentials.userName)
					.then(() => {
						capturedVue.sendPostRegistrationNotice();
						// if (capturedVue.config.notification) {
						// 	let tags = new Map();
						// 	Object.keys(capturedVue.config.notification.registration.postRegistrationAction.miscTags).forEach(key => {
						// 		//tags[key] = capturedVue.config.notification.registration.postRegistrationAction.miscTags[key];
						// 		tags.set(key, capturedVue.config.notification.postRegistrationAction.miscTags[key]);
						// 	});

						// 	let sendNotice = {
						// 		action: capturedVue.config.registration.notification.postRegistrationAction.name,
						// 		attributeNames: capturedVue.config.registration.notification.postRegistrationAction.attributeNames,
						// 		miscTags: tags
						// 	};
						// 	capturedVue.notificationService.process(sendNotice, capturedVue.authService.token);
						// }

						// success, go to root
						log(myLoggingName, "login - Validated credentials");
						capturedVue.resetData();
						capturedVue.finished();
					})
					.catch(err => {
						log(myLoggingName, "login - Error", err);
						capturedVue.resetData();
						capturedVue.finished();
					});
			} else {
				this.authService
					.login(credentials.userName, credentials.password)
					.then(() => {
						// success, go to root
						log(myLoggingName, "login - Validated credentials");
						capturedVue.resetData();
						capturedVue.finished();
					})
					.catch(err => {
						log(myLoggingName, "login - Error", err);
						capturedVue.resetData();
						capturedVue.finished();
					});
			}
		},
		finished: function() {
			this.resetData();
			let location = this.config.postRegistration?.routeName ?? this.homePage;
			this.$router.replace({ name: location });
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";
.client-logo {
	display: block;
	margin: 0 auto;
	max-width: 300px;
	padding-bottom: 40px;
}

.registration-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.registration-wrapper {
	position: relative;
	width: 100%;
	max-width: 600px;
	padding: 60px;

	display: flex;
	flex-direction: column;

	color: #000;

	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.bg {
		width: 100%;
		max-width: none;
		border-radius: 0;
		box-shadow: none;
	}
}
</style>
