<template>
	<div class="chat-component" v-bind:class="chatClass">
		<king-events-chat
			v-if="chatType === 'king-events'"
			:config="this.config.kingEventsChatParameters"
			:analyticsContext="analyticsContext"
		/>
		<span v-else-if="chatType === 'iframe'">
			<div class="chat-iframe-wrapper" v-if="chatOpen">
				<iframe v-bind="processedConfig.iframe"></iframe>
			</div>
		</span>
		<div v-else-if="chatType === 'externalLink'" class="chat-link-button-wrapper" v-bind:style="chatLinkButtonWrapperStyle">
			<b-link target="_blank" v-bind:href="processedConfig.url" class="chat-link-button" v-bind:style="chatButtonStyle">
				{{ processedConfig.urlText }}
			</b-link>
		</div>
		<span v-else />
	</div>
</template>

<script>
import KingEventsChat from "./chat/KingEventsChat.vue";
import { formatString } from "@/stringutilities";
import { log } from "@/logging";

const myLoggingName = "Chat";

export default {
	name: "Chat",
	props: {
		config: Object,
		resourceKeyBase: String,
		analyticsContext: Object
	},
	components: { KingEventsChat },
	data: function() {
		let initiallyOpen = false;
		switch (this.config.type) {
			case "iframe":
				initiallyOpen = this.config.fixedOpen || this.config.initiallyOpen;
				break;
			case "king-events":
				initiallyOpen = true;
				break;
			default:
				initiallyOpen = false;
		}
		return {
			chatOpen: initiallyOpen,
			roomId: null,
			userId: null,
			processedConfig: null
		};
	},
	computed: {
		chatClass: function() {
			return {
				collapsed: !this.chatOpen
			};
		},
		chatLinkButtonWrapperStyle: function() {
			return this.processedConfig.chatDivStyle ?? "";
		},
		chatButtonStyle: function() {
			return this.processedConfig.chatButtonStyle ?? "";
		},
		chatType: function() {
			if (this.processedConfig) {
				switch (this.processedConfig.type) {
					case "king-events":
						return "king-events";
					case "iframe":
						return "iframe";
					case "externalLink":
						return "externalLink";
					default:
						log(myLoggingName, `Unsupported chat type ${this.config.type}`);
						return null;
				}
			}
			return null;
		}
	},
	inject: ["authService", "protectedResource"],
	methods: {
		toggleChat: function() {
			switch (this.processedConfig.type) {
				case "iframe":
					this.chatOpen = !this.chatOpen;
					break;
				default:
					this.chatOpen = false;
			}
		},
		// formatString(format, ...parms) {
		// 	// based on https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/18234317#18234317
		// 	var str = format;
		// 	if (parms.length) {
		// 		var t = typeof parms[0];
		// 		var key;
		// 		var args = "string" === t || "number" === t ? Array.prototype.slice.call(parms) : parms[0];

		// 		for (key in args) {
		// 			str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
		// 		}
		// 	}

		// 	return str;
		// },
		isChatResourceRequired(config) {
			if (config.provider) {
				if (config.provider.type === "slido" || config.provider.type === "webvideocore") {
					return true;
				}
			}
			return false;
		},
		processConfig: function(config) {
			let rc = JSON.parse(JSON.stringify(config));
			//rc.iframe.src = "//cdn.iubenda.com/cookie_solution/empty.html";
			//rc.iframe.class = "_iub_cs_activate";
			if (this.roomId) {
				switch (rc.provider.type) {
					case "slido":
						rc.iframe.src = formatString(rc.iframe.src, this.roomId);
						//rc.iframe.suppressedsrc = this.formatString(rc.iframe.src, this.roomId);
						break;
					default:
						rc.iframe.src = rc.iframe.src + this.roomId;
					//rc.iframe.suppressedsrc = rc.iframe.src + this.roomId;
				}
			}
			if (this.userId) {
				switch (rc.provider.type) {
					case "slido":
						rc.iframe.src = rc.iframe.src + "?" + rc.provider.propagateUserKey + "=" + encodeURIComponent(this.userId);
						// rc.iframe.suppressedsrc =
						// 	rc.iframe.suppressedsrc + "?" + rc.provider.propagateUserKey + "=" + encodeURIComponent(this.userId);
						break;
					default:
						break;
				}
			}
			return rc;
		}
	},
	mounted() {
		let capturedVue = this;
		this.authService
			.getToken()
			.then(async token => {
				return await this.protectedResource.getProtectedConfigurationData(token);
			})
			.then(data => {
				return data;
			})
			.then(data => {
				const protectedResourceKey = capturedVue.resourceKeyBase;
				if (capturedVue.isChatResourceRequired(capturedVue.config)) {
					capturedVue.roomId = data[protectedResourceKey].chatId;
				}
				if (capturedVue.config.propagateUser) {
					// This may be refactored - it's likely that parsing auth service user attributes to user data may be common
					if (capturedVue.authService.user.attributes["given_name"] || capturedVue.authService.user.attributes["family_name"]) {
						capturedVue.userId =
							capturedVue.authService.user.attributes["given_name"] +
							" " +
							capturedVue.authService.user.attributes["family_name"];
					}
				}
				capturedVue.processedConfig = capturedVue.processConfig(capturedVue.config);
				//capturedVue.mounted = true;
			});
	}
};
</script>

<style scoped lang="scss">
@import "../scss/constants";

.chat-component {
	.chat-link-button {
		color: white;
	}

	.chat-link-button-wrapper {
		position: relative;
		width: 100%;
		height: 36px;
	}

	.chat-iframe-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}
}

@media (max-width: $screen-mobile-max) {
}
</style>
