var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mounted && _vm.playerDisplayOrder && _vm.playerDisplayOrder.length > 1)?_c('div',[(_vm.standardPlayers.length > 0)?_c('span',[_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
					_vm.text.getByKey('toolTip', {
						default: 'Choose alternate video source',
						en: 'Choose alternate video source',
						fr: 'Choisir une autre source vidéo'
					})
				),expression:"\n\t\t\t\t\ttext.getByKey('toolTip', {\n\t\t\t\t\t\tdefault: 'Choose alternate video source',\n\t\t\t\t\t\ten: 'Choose alternate video source',\n\t\t\t\t\t\tfr: 'Choisir une autre source vidéo'\n\t\t\t\t\t})\n\t\t\t\t",modifiers:{"hover":true,"right":true}}],attrs:{"variant":"outline"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('svg',{staticClass:"bi bi-display",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":_vm.pickerIconColour,"viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"}})])]},proxy:true}],null,false,652638139)},_vm._l((_vm.standardPlayers),function(value,index){return _c('b-dropdown-item-button',{key:index,on:{"click":function($event){return _vm.selectPlayer(value)}}},[(value.name === _vm.selectedPlayer.name)?_c('span',[_c('svg',{staticClass:"bi bi-check",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"}})])]):_c('span',[_c('b-icon',{attrs:{"type":"blank","aria-hidden":"true"}})],1),_vm._v(" "+_vm._s(_vm.text.get(value.name))+" ")])}),1)],1):_vm._e(),(_vm.alternateAudioPlayers.length > 0)?_c('span',[_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
					_vm.text.getByKey('alternateAudioToolTip', {
						default: 'Choose alternate audio video source',
						en: 'Choose alternate audio video source',
						fr: 'Choisir une autre source audio vidéo'
					})
				),expression:"\n\t\t\t\t\ttext.getByKey('alternateAudioToolTip', {\n\t\t\t\t\t\tdefault: 'Choose alternate audio video source',\n\t\t\t\t\t\ten: 'Choose alternate audio video source',\n\t\t\t\t\t\tfr: 'Choisir une autre source audio vidéo'\n\t\t\t\t\t})\n\t\t\t\t",modifiers:{"hover":true,"right":true}}],attrs:{"variant":"outline"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('svg',{staticClass:"bi bi-headphones",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":_vm.pickerIconColour,"viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z"}})])]},proxy:true}],null,false,762185712)},_vm._l((_vm.alternateAudioPlayers),function(value,index){return _c('b-dropdown-item-button',{key:index,on:{"click":function($event){return _vm.selectPlayer(value)}}},[(value.name === _vm.selectedPlayer.name)?_c('span',[_c('svg',{staticClass:"bi bi-check",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"}})])]):_c('span',[_c('b-icon',{attrs:{"type":"blank","aria-hidden":"true"}})],1),_vm._v(" "+_vm._s(_vm.text.get(value.name))+" ")])}),1)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }