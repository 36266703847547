<template>
	<div>
		<!-- <video ref="videoPlayer" class="video-js vjs-fill" v-bind:poster="options.poster"></video> -->

		<video-js ref="videoPlayer" class="vjs-big-play-centered"></video-js>
	</div>
</template>

<script>
import videojs from "video.js";
import { log } from "@/logging";

const myLoggingName = "VideoJsPlayer";

// For debugging
// const EVENTS = [
// 	"loadstart",
// 	"progress",
// 	"suspend",
// 	"abort",
// 	"emptied",
// 	"stalled",
// 	"loadedmetadata",
// 	"loadeddata",
// 	"canplay",
// 	"canplaythrough",
// 	"playing",
// 	"waiting",
// 	"seeking",
// 	"seeked",
// 	"ended",
// 	"durationchange",
// 	"retryplaylist",
// 	"timeupdate",
// 	"play",
// 	"pause",
// 	"ratechange",
// 	"resize",
// 	"volumechange"
// ];

const EVENTSTHATINDICATESUCCESSFULSTART = ["canplay", "playing"];

const NORMALMODE = "normal";
const DELAYEDMODE = "delayed";
const PSEUDOLIVEMODE = "pseudoLive";

const DEFAULTMODE = {
	type: "normal",
	autoPlay: false
};

export default {
	name: "VideoJsPlayer",
	props: {
		config: {
			type: Object
		},
		mode: {
			type: Object
		}
	},
	data() {
		return {
			player: null,
			startedLive: false,
			pseudoLiveCheckerTaskId: null,
			derivedOptions: {},
			currentMode: {}
		};
	},
	inject: ["textService"],
	computed: {
		text() {
			return this.textService.copy(this.config.text);
		}
	},
	methods: {
		setupPlayerEventHandlers() {
			const capturedVue = this;
			// For debugging
			// capturedVue.player.on(EVENTS, function(x) {
			// 	log(myLoggingName, "event", x);
			// });
			capturedVue.player.one(EVENTSTHATINDICATESUCCESSFULSTART, function() {
				log(myLoggingName, "playerStartedOK");
				//				capturedVue.$emit("playerStartedOK");
				capturedVue.$emit("playerEvent", "playerStartedOK");
			});
			capturedVue.player.on("error", function(e) {
				const error = this.error();
				log(myLoggingName, "error", e, error);
				// TODO Maybe restrict the error types to 3 and 4
				//				capturedVue.$emit("playerError", capturedVue.config.retry);
				capturedVue.$emit("playerEvent", "playerError", capturedVue.config.retry);
				this.error(null);
			});
			capturedVue.player.on("ended", function() {
				// Have yet to see this be called for a video that ended (at least in VOD mode)
				log(myLoggingName, "ended", {
					duration: this.duration(),
					currentTime: this.currentTime(),
					networkState: this.networkState(),
					readyState: this.readyState(),
					isSafari: videojs.browser.IS_ANY_SAFARI
				});
				// When safari (desktop) detects an error for video that had started OK, it fires an
				// ended event. In testing the currentTime is less than the duration and the networkstate is 2 (loading)
				// For the normal ended event (based on a review of the videojs code - https://docs.videojs.com/player.js.html)
				// the currentTime should equal the duration and yet the lower level Html5 tech class provides a different defintiaion
				// of ended https://docs.videojs.com/tech_html5.js.html
				// so, it's a bit of a mess.
				// For now, if player is any safari and currentTime is less than duration, will treat as an error,
				// otherwise will treat as ended.

				if (videojs.browser.IS_ANY_SAFARI && this.currentTime() < this.duration()) {
					capturedVue.$emit("playerEvent", "playerError", capturedVue.config.retry);
				} else {
					capturedVue.$emit("playerEvent", "playerEnded");
				}
				this.error(null);
			});
			// a possible option for playing an alternate poster at the end of a video - would need to set the new poster URL
			// player.on("ended", function () {
			//   player.currentTime(0);
			//   player.hasStarted(false);
			//   player.trigger("ready");
			// });
			// capturedVue.player.on("timeupdate", function() {
			// 	capturedVue.$store.dispatch(`kingEventsCaptions/updatePlayerPosition`, this.currentTime());
			// 	capturedVue.$store.dispatch(`kingEventsCaptions/updatePlayerDuration`, this.liveTracker.liveCurrentTime());

			// });
			// capturedVue.player.on("durationchange", function() {
			// 					log(myLoggingName, "durationchange");

			// 	capturedVue.$store.dispatch(`kingEventsCaptions/updatePlayerDuration`, this.liveTracker.liveCurrentTime());

			// });
		},
		setupPlayerPseudoLiveEventHandlers(startTime) {
			const capturedVue = this;
			capturedVue.player.on("seeking", function() {
				capturedVue.checkProgress(startTime);
			});

			capturedVue.player.on("seeked", function() {
				capturedVue.checkProgress(startTime);
			});

			capturedVue.player.on("play", function() {
				log(myLoggingName, "playPseudoLive");
				capturedVue.checkProgress(startTime);
			});

			capturedVue.player.on("pause", function() {
				log(myLoggingName, "pausedPseudoLive");
				capturedVue.checkProgress(startTime);
			});
		},
		/**
		 * This is used for both error mode and pseudolive
		 */
		delayedInitialization(startTime, modalMessage) {
			const capturedVue = this;
			const currentTime = new Date();

			capturedVue.startedLive = currentTime.getTime() - startTime.getTime() >= 0;

			// Scheduled timer to run each second, and check if we should unpause/autostart the player
			const interval = 1000;
			if (!this.startedLive) {
				// Display a video not started modal and shut off controls if this is pseudoLive and it hasn't "started" yet
				const notStartedModal = this.player.createModal(capturedVue.text.get(modalMessage));
				notStartedModal.closeable(false);
				const originalControlSetting = this.player.controls();
				notStartedModal.on("modalclose", () => {
					if (originalControlSetting) {
						capturedVue.player.controls(true);
					}
					capturedVue.startedLive = true;

					if (capturedVue.currentMode.autoPlay) {
						capturedVue.player.play();
					}
					// IT looks like event handlers have to be re-added after the pseudolive starts (if not the standard event handlers were not called.)
					capturedVue.setupPlayerEventHandlers();

					if (capturedVue.currentMode.type === PSEUDOLIVEMODE) {
						capturedVue.setupPlayerPseudoLiveEventHandlers(startTime);
					}

					// we also want to check the progress here in case the user has opened the page in the middle of the stream
					// in order to jump ahead to where the stream is now
					capturedVue.checkProgress(startTime);
					// Video can start, no need to keep running this task
					clearInterval(capturedVue.pseudoLiveCheckerTaskId);
					capturedVue.pseudoLiveCheckerTaskId = null;
				});
				capturedVue.player.controls(false);
				capturedVue.pseudoLiveCheckerTaskId = setInterval(() => {
					const currentTime = new Date();

					// only log this when debugging
					// 	log(myLoggingName,"timerStep", {startTime, currentTime, startedLive, player: this.player});
					// Start the player if we've been sitting with the tab opened, and the livestream has started while we have had it paused
					if (!capturedVue.startedLive && capturedVue.player.paused() && currentTime.getTime() > startTime.getTime()) {
						log(myLoggingName, "timerStep", "autostarting", {
							startTime,
							currentTime,
							startedLive: capturedVue.startedLive
						});
						notStartedModal.close();
					}
				}, interval);
			} else {
				capturedVue.startedLive = true;

				if (capturedVue.currentMode.autoPlay) {
					capturedVue.player.play();
				}
				if (capturedVue.currentMode.type === PSEUDOLIVEMODE) {
					capturedVue.setupPlayerPseudoLiveEventHandlers(startTime);
				}
			}
		},

		initializePlayer() {
			// Notes error.code=3 or 4 indicate failure to find video when loading and failure with an on going video
			// The current error handling is to restart in both cases by sending an error to the parent (the parent will re-render this element which seems to be the best way to re-intialize)
			const capturedVue = this;

			this.player = videojs(this.$refs.videoPlayer, this.derivedOptions);

			let startTime = new Date();
			switch (this.currentMode.type) {
				case DELAYEDMODE: {
					if (this.currentMode.delay) {
						startTime.setTime(startTime.getTime() + this.currentMode.delay);
					}
					this.delayedInitialization(startTime, this.currentMode.modalText);
					break;
				}
				case PSEUDOLIVEMODE: {
					startTime = this.currentMode.startTime ? new Date(this.currentMode.startTime) : new Date();
					this.delayedInitialization(
						startTime,
						this.text.getByKey("pseudoLiveNotStartedYet", {
							default: "Video has not started",
							en: "Video has not started",
							fr: "La vidéo n'a pas démarré"
						})
					);
					break;
				}
				case NORMALMODE:
				default:
					if (this.currentMode.autoPlay) {
						this.player.play();
						this.setupPlayerEventHandlers();
					} else {
						this.setupPlayerEventHandlers();
					}
			}
			// The duration is not available until the loadedmetadata event is fired.
			// We need the duration for pseudolive mode to determine whether the current time is past the end of the end of "live" video as:
			//  - we switch to "VOD" mode when a video is done and need to know that the video is done
			//  - desktop safari becomes non-responsive if the currenTime is set to the end (or past the end) when the video is loaded so we need to avoid that.
			//  Net impact, when the video is played, it is played from the start, we should almost immediately get a loadedmetadata event with the duration at which point
			//  we, if it is pseudolive and the current time is between the start and end of the video, we go to the "live" point in the video.
			this.player.one("loadedmetadata", function() {
				let duration = this.duration();
				let liveTimeInSeconds = (new Date().getTime() - startTime.getTime()) / 1000;
				log(myLoggingName, "loadedmetadata", duration, liveTimeInSeconds, duration);
				// set player's current position to the "live" position, unless instructed to start at the beginning or video is over
				if (capturedVue.currentMode.type === PSEUDOLIVEMODE && !capturedVue.currentMode.startAtBeginning) {
					if (liveTimeInSeconds < duration) {
						log(myLoggingName, "loadedmetadataMovingToLive", this.duration(), liveTimeInSeconds, duration);
						capturedVue.player.currentTime(liveTimeInSeconds);
					}
				}
			});
		},
		checkProgress(startTime) {
			const currentTime = new Date();
			// kept around incase we need to debug log
			//log(myLoggingName, "checkProgress", { player: player, startTime });
			// If video has not started yet, set time to 0 and set as not started
			if (currentTime.getTime() < startTime.getTime()) {
				this.player.currentTime(0);
				this.player.pause();
				this.player.hasStarted(false);
			} else {
				// else, set the player to the actual progress in the video
				const diff = (currentTime.getTime() - startTime.getTime()) / 1000;
				if (this.player.currentTime() > diff) {
					log(myLoggingName, "checkProgress", "clipping", { startTime, currentTime, diff });

					this.player.currentTime((currentTime.getTime() - startTime.getTime()) / 1000);
				}
			}
		}
	},
	created() {
		// if IVS, maybe try their player - https://docs.aws.amazon.com/ivs/latest/userguide/player-videojs.html

		// Make a safe copy of the playerOptions and then override those that are not used - autoplay
		const derivedOptions = {};
		Object.assign(derivedOptions, this.config.playerOptions);
		derivedOptions.autoplay = false;
		this.derivedOptions = derivedOptions;

		const derivedMode = {};
		let sourceMode = this.mode;
		if (!sourceMode && !sourceMode.type) {
			sourceMode = DEFAULTMODE;
		}
		Object.assign(derivedMode, sourceMode);
		this.currentMode = derivedMode;
	},
	mounted() {
		// if IVS, maybe try their player - https://docs.aws.amazon.com/ivs/latest/userguide/player-videojs.html

		log(myLoggingName, "mounted", "mode", this.currentMode, DEFAULTMODE, this.mode);
		this.initializePlayer();
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
		if (this.pseudoLiveCheckerTaskId) {
			clearInterval(this.pseudoLiveCheckerTaskId);
		}
	}
};
</script>
<style lang="scss" scoped>
@import "../scss/constants";
@import "../css/video-js.css";

.video-js .vjs-big-play-button {
	line-height: 1.4em;
	height: 1.5em;
	width: 1.5em;
}
</style>
