var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authentication-page"},[_c('background',{attrs:{"src":_vm.config.bg,"mobileSrc":_vm.config.mobileBg}}),_c('div',{staticClass:"authentication-wrapper"},[_c('div',{staticClass:"client-logo"},[_c('img',{staticClass:"logo-img",attrs:{"src":_vm.common.logo,"alt":""}})]),(_vm.error)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e(),(_vm.activeComponent === 'Signin')?_c('div',{staticClass:"signin-wrapper"},[_c('signin',{attrs:{"config":_vm.config.signin,"common":_vm.common},on:{"login":_vm.login,"forgotPassword":_vm.switchToForgotPassword,"completeAccountConfirmation":_vm.switchToCompleteAccountConfirmation}})],1):_vm._e(),(_vm.activeComponent === 'PasswordlessSignin')?_c('div',{staticClass:"signin-wrapper"},[_c('passwordless-signin',{attrs:{"common":_vm.common,"text":_vm.text.shiftContext('passwordlessSignin')},on:{"login":_vm.passwordlessLogin}})],1):_vm._e(),(_vm.activeComponent === 'PasswordlessRegistrationAttributeGatherer')?_c('div',[_c('user-attributes',{ref:"attributes",attrs:{"config":_vm.config.attributes,"common":_vm.common,"userData":_vm.passwordlessRegistrationAttributeGathererProps,"text":_vm.text.shiftContext('userAttributes')},on:{"update":_vm.passwordlessRegistration},scopedSlots:_vm._u([{key:"changed",fn:function(ref){
var changed = ref.changed;
return [_c('b-button',{staticClass:"btn mr-1",attrs:{"type":"button","variant":"primary","value":"Update User Information"},on:{"click":changed}},[_vm._v(" "+_vm._s(_vm.text.getByKey("userAttributesChangeSlot", { default: "Update", en: "Update", fr: "Réviser" }))+" ")])]}}],null,false,1562079846)}),_c('br'),_c('need-help',{attrs:{"common":_vm.common,"text":_vm.text.shiftContext('needHelp')}})],1):_vm._e(),(_vm.activeComponent === 'UserPasswordReset')?_c('div',{staticClass:"user-password-reset-wrapper"},[_c('user-password-reset',{attrs:{"title":_vm.forgotPasswordTitle,"buttonText":_vm.forgotPasswordButtonText,"text":_vm.text.shiftContext('userPasswordReset')},on:{"requestPasswordReset":_vm.sendForgotPasswordRequest,"cancel":_vm.initialState}})],1):_vm._e(),(_vm.activeComponent === 'AdminPasswordReset')?_c('div',{staticClass:"admin-password-reset-wrapper"},[_c('admin-password-reset',{attrs:{"code":_vm.adminResetCode,"text":_vm.text.shiftContext('adminPasswordReset')},on:{"requestPasswordReset":_vm.startAdminPasswordReset,"cancel":_vm.initialState}})],1):_vm._e(),(_vm.activeComponent === 'NewPassword')?_c('div',{staticClass:"new-password-wrapper",attrs:{"userName":_vm.id}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.text.getByKey("newPasswordWrapperTitle", { default: "Change Password", en: "Change Password", fr: "Changer le Mot de Passe" }))+" ")]),_c('br'),_c('div',{staticClass:"instructions"},[_vm._v(" "+_vm._s(_vm.text.getByKey("newPasswordWrapperInstructions", { default: "Please create your new password", en: "Please create your new password", fr: "Veuillez créer votre nouveau mot de passe" }))+" ")]),_c('new-password',{attrs:{"text":_vm.text.shiftContext('newPassword')},on:{"changePassword":_vm.completeNewPassword},scopedSlots:_vm._u([{key:"password1Error",fn:function(ref){
var error = ref.error;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(error.length > 0),expression:"error.length > 0"}],staticClass:"form-error"},[_c('ul',_vm._l((error),function(passwordError){return _c('li',{key:passwordError,staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(passwordError))])}),0)])]}},{key:"password2Error",fn:function(ref){
var error = ref.error;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(error),expression:"error"}],staticClass:"form-error"},[_vm._v(_vm._s(error))])]}},{key:"changed",fn:function(ref){
var changed = ref.changed;
return [_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","value":"Change Password"},on:{"click":changed}},[_vm._v(" "+_vm._s(_vm.text.getByKey("newPasswordChangeSlot", { default: "Change Password", en: "Change Password", fr: "Changer le Mot de Passe" }))+" ")])]}}],null,false,3865548005)})],1):_vm._e(),(_vm.activeComponent === 'CodeAndNewPassword')?_c('div',{staticClass:"new-codeandpass-word-wrapper"},[_c('code-and-new-password',{attrs:{"initialVerificationCode":_vm.adminResetCode,"text":_vm.text.shiftContext('codeAndNewPassword')},on:{"changePassword":_vm.completeNewCodeAndPassword}})],1):_vm._e(),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeComponent === 'Waiting'),expression:"activeComponent === 'Waiting'"}]},[_c('b-spinner',{staticStyle:{"position":"absolute","top":"calc(50%)"},attrs:{"variant":"primary","label":"Waiting"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }