<template>
	<div>
		<div v-if="renderless">
			<slot name="generate" v-bind:generate="generate"></slot>
		</div>
		<div v-else class="add-to-calendar-component" :style="applyStyleVariables()" v-on:click="generate()">
			<span v-if="notIcs()" class="add-to-calendar-item">
				<svg
					v-if="type === 'google'"
					xmlns:dc="http://purl.org/dc/elements/1.1/"
					xmlns:cc="http://creativecommons.org/ns#"
					xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="186 38 76 76"
					width="2em"
					height="2em"
					role="img"
					aria-label="google calendar"
				>
					<metadata id="metadata126">
						<rdf:RDF>
							<cc:Work rdf:about="" cc:attributionName="Google Inc., Public domain, via Wikimedia Commons">
								<dc:format>image/svg+xml</dc:format>
								<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
								<dc:title>Google Calendar icon (2020)</dc:title>
							</cc:Work>
						</rdf:RDF>
					</metadata>

					<path fill="#fff" d="M244 56h-40v40h40V56z" />
					<path fill="#EA4335" d="M244 114l18-18h-18v18z" />
					<path fill="#FBBC04" d="M262 56h-18v40h18V56z" />
					<path fill="#34A853" d="M244 96h-40v18h40V96z" />
					<path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z" />
					<path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z" />
					<path fill="#4285F4" d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z" />
					<path
						fill="#4285F4"
						d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"
					/>
				</svg>
				<svg
					v-else-if="type === 'microsoftlive'"
					xmlns:dc="http://purl.org/dc/elements/1.1/"
					xmlns:cc="http://creativecommons.org/ns#"
					xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
					xmlns:svg="http://www.w3.org/2000/svg"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
					xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
					width="2em"
					height="2em"
					viewBox="0 0 88.026056 86"
					version="1.1"
					id="svg2"
					inkscape:version="0.48.3.1 r9886"
					sodipodi:docname="popwe.svg"
					role="img"
					aria-label="outlook calendar"
				>
					<metadata id="metadata126">
						<rdf:RDF>
							<cc:Work rdf:about="" cc:attributionName="Micrososft, Public domain, via Wikimedia Commons">
								<dc:format>image/svg+xml</dc:format>
								<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
								<dc:title>Microsoft_Outlook_2013-2019_logo.svg</dc:title>
							</cc:Work>
						</rdf:RDF>
					</metadata>
					<defs id="defs124" />
					<sodipodi:namedview
						pagecolor="#ffffff"
						bordercolor="#666666"
						borderopacity="1"
						objecttolerance="10"
						gridtolerance="10"
						guidetolerance="10"
						inkscape:pageopacity="0"
						inkscape:pageshadow="2"
						inkscape:window-width="1280"
						inkscape:window-height="960"
						id="namedview122"
						showgrid="false"
						inkscape:zoom="1.0905983"
						inkscape:cx="258.62288"
						inkscape:cy="53.75"
						inkscape:window-x="0"
						inkscape:window-y="0"
						inkscape:window-maximized="1"
						inkscape:current-layer="svg2"
						fit-margin-top="0"
						fit-margin-left="0"
						fit-margin-right="0"
						fit-margin-bottom="0"
					/>
					<path
						d="m 46.01,0 5.98,0 c 0,6.67 0,13.33 0,20 10.36,0.02 20.71,-0.03 31.06,0 2.04,-0.1 4.83,0.49 4.79,3.06 0.38,12.65 0.01,25.33 0.18,37.99 0.04,2.24 -0.03,5.77 -3.08,5.8 -10.97,0.38 -21.97,0.03 -32.95,0.15 0,6.33 0,12.67 0,19 l -6.2,0 C 30.55,83.23 15.27,80.68 0.01,78 0,54.67 0,31.34 0,8.02 15.34,5.33 30.68,2.73 46.01,0 z"
						id="path18"
						inkscape:connector-curvature="0"
						style="fill:#0071c5"
					/>
					<path
						d="m 51.99,23 c 11.08,0.01 22.15,-0.03 33.23,0.02 -0.19,1.31 -0.59,2.6 -1.69,3.42 C 76.91,32.61 70.33,38.81 63.73,45 59.76,41.41 55.9,37.7 51.99,34.05 c 0,-3.69 0,-7.37 0,-11.05 z"
						id="path64"
						inkscape:connector-curvature="0"
						style="fill:#ffffff"
					/>
					<path
						d="m 21.44,26.66 c 5.64,-2.13 12.23,0.86 14.62,6.31 3.26,7.5 2.8,17.76 -3.68,23.43 -5.97,5.11 -15.98,2.2 -18.7,-5.06 -3.58,-8.37 -1.84,-21.25 7.76,-24.68 z"
						id="path76"
						inkscape:connector-curvature="0"
						style="fill:#ffffff"
					/>
					<path
						d="m 66.87,45.89 c 6.01,-5.71 12.07,-11.38 18.11,-17.06 0.03,11.72 0,23.45 0.01,35.17 -11,0 -22,0 -33,0 0,-8.72 -0.01,-17.44 0.01,-26.15 3.73,3.3 7.07,7.06 11.03,10.07 1.63,0.3 2.73,-1.11 3.84,-2.03 z"
						id="path92"
						inkscape:connector-curvature="0"
						style="fill:#ffffff"
					/>
					<path
						d="m 22.41,32.67 c 2.66,-1.27 5.99,0.02 7.24,2.65 2,3.96 1.99,8.79 0.68,12.97 -0.93,2.97 -4.18,5.42 -7.33,4.25 -3.93,-1.24 -5.12,-5.9 -5.16,-9.55 -0.05,-3.78 0.82,-8.49 4.57,-10.32 z"
						id="path96"
						inkscape:connector-curvature="0"
						style="fill:#0071c5"
					/>
				</svg>
				<svg
					v-else
					data-v-41be6633=""
					viewBox="0 0 16 16"
					width="2em"
					height="2em"
					focusable="false"
					role="img"
					aria-label="calendar2 plus"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					class="bi-calendar2-plus mx-auto b-icon bi"
				>
					<g data-v-41be6633="">
						<path
							d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"
						></path>
						<path
							d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"
						></path>
					</g>
				</svg>
				&nbsp; {{ description }}
			</span>
			<span v-else class="add-to-calendar-item">
				<svg
					data-v-41be6633=""
					viewBox="0 0 16 16"
					width="2em"
					height="2em"
					focusable="false"
					role="img"
					aria-label="calendar2 plus"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					class="bi-calendar2-plus mx-auto b-icon bi"
				>
					<g data-v-41be6633="">
						<path
							d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"
						></path>
						<path
							d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"
						></path>
					</g>
				</svg>
				&nbsp; {{ description }}
			</span>
		</div>
	</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";

const uidDomain = "virtualvenue";
const prodId = "addToCal";
const defaultStyle = {
	"hover-opacity": "0.75"
};

// https://github.com/nicolasbeauvais/vue-add-to-calendar
// https://github.com/evildvl/vue-ics/blob/master/src/index.js
// https://www.litmus.com/blog/how-to-create-an-add-to-calendar-link-for-your-emails/

const calendars = {
	google: {
		url: "http://www.google.com/calendar/render?action=TEMPLATE&trp=false",
		parameters(event) {
			const parameters = {
				text: event.title,
				location: event.location,
				details: event.details
			};

			if (event.start && event.end) {
				parameters.dates = `${event.start}/${event.end}`;
			}

			return parameters;
		},
		formatDate: function(date) {
			return date ? date.toISOString().replace(/-|:|\.\d+/g, "") : null;
		}
	},

	microsoftlive: {
		url: "https://outlook.office.com/calendar/0/deeplink/compose/?path=/calendar/action/compose&rru=addevent",
		//		url: "https://outlook.live.com/owa/?rru=addevent",
		parameters(event) {
			return {
				subject: event.title,
				location: event.location,
				body: event.details,
				startdt: event.start,
				enddt: event.end
			};
		},
		formatDate: function(date) {
			if (date) {
				let truncatedDate = new Date(date);
				truncatedDate.setMilliseconds(0);
				return encodeURIComponent(truncatedDate.toISOString()).replace(/\.\d+/g, "");
			} else {
				throw "Invalid date";
			}
		}
	},
	// https://outlook.office.com/calendar/0/deeplink/compose/?path=/calendar/action/compose&rru=addevent&subject=Cool+Event&location=401+Logan+Ave+Suite+203%2C+Toronto%2C+ON%2C+CA&body=test&startdt=2022-01-12T18%3A00%3A00%2B00%3A00&enddt=2022-01-12T20%3A00%3A00%2B00%3A00
	microsoftoffice365: {
		url: "https://outlook.office.com/calendar/0/deeplink/compose/?path=/calendar/action/compose&rru=addevent",
		//		url: "https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent",
		parameters(event) {
			return {
				subject: event.title,
				location: event.location,
				body: event.details,
				startdt: event.start,
				enddt: event.end
			};
		},
		formatDate: function(date) {
			if (date) {
				let truncatedDate = new Date(date);
				truncatedDate.setMilliseconds(0);
				return encodeURIComponent(truncatedDate.toISOString()).replace(/\.\d+/g, "");
			} else {
				throw "Invalid date";
			}
		}
	}
};
export default {
	data() {
		return {};
	},
	props: {
		type: String,
		eventData: Object,
		description: String,
		styleOverride: Object,
		renderless: { default: false, type: Boolean }
	},
	name: "AddToCalendar",
	inject: ["textService"],
	mounted() {},
	computed: {
		text() {
			//return this.textService.copy(this.config.text);
			return this.textService;
		},
		calendarUrl: function() {
			switch (this.type?.toLowerCase()) {
				case "google":
					return this.createGoogleURL();
				case "microsoftlive":
				case "microsoftoffice365":
					return this.createURL();
				default:
					return "";
			}
		}
	},
	methods: {
		notIcs: function() {
			return this.type?.toLowerCase() != "ics";
		},
		applyStyleVariables() {
			const styleVars = {};
			Object.keys(defaultStyle).forEach(key => {
				styleVars["--" + key] = defaultStyle[key];
			});
			if (this.styleOverride) {
				Object.keys(this.styleOverride).forEach(key => {
					styleVars["--" + key] = this.styleOverride[key];
				});
			}
			return styleVars;
		},
		//JM alter this function to use the outlook (microsoft) description text from siteconfig
		createURL: function() {
			let calendar = calendars[this.type];
			let url = calendar.url;
			const newEvent = {
				title: this.formatString(this.text.get(this.eventData.title)),
				location: this.formatString(this.text.get(this.eventData.location)),
				details: this.formatString(this.text.get(this.eventData.description.outlook)),
				start: calendar.formatDate(this.eventData.start),
				end: calendar.formatDate(this.eventData.end)
			};
			const parameters = calendar.parameters(newEvent);

			for (const key in parameters) {
				if (Object.prototype.hasOwnProperty.call(parameters, key) && parameters[key]) {
					url += `&${key}=${parameters[key]}`;
				}
			}

			return url;
		},
		//JM create new function based on createURL in order to put the correct description for a google calendar
		createGoogleURL: function() {
			let calendar = calendars[this.type];
			let url = calendar.url;
			const newEvent = {
				title: this.formatString(this.text.get(this.eventData.title)),
				location: this.formatString(this.text.get(this.eventData.location)),
				details: this.formatString(this.text.get(this.eventData.description.google)),
				start: calendar.formatDate(this.eventData.start),
				end: calendar.formatDate(this.eventData.end)
			};
			const parameters = calendar.parameters(newEvent);

			for (const key in parameters) {
				if (Object.prototype.hasOwnProperty.call(parameters, key) && parameters[key]) {
					url += `&${key}=${parameters[key]}`;
				}
			}

			return url;
		},
		generate: function() {
			if (this.notIcs()) {
				this.sendToCalendarService();
			} else {
				this.createIcs();
			}
		},
		sendToCalendarService: function() {
			//JM alter this function to check for type of calendar and choose how to create the URL
			switch (this.type?.toLowerCase()) {
				case "google": {
					const gurl = this.createGoogleURL();
					window.open(gurl);
					break;
				}
				case "microsoftlive":
				case "microsoftoffice365": {
					const ourl = this.createURL();
					window.open(ourl);
					break;
				}
				default: {
					const url = this.createURL();
					window.open(url);
					break;
				}
			}
		},

		formatString: function(string) {
			return encodeURIComponent(string).replace(/%20/g, "+");
		},

		/**
		 * Reccurence rule validation
		 * @function
		 * @param {RRule} rule - Reccurence rule
		 * @returns {boolean}
		 */
		validateIcsRepeatRule: function(rule) {
			const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
			if (rule.freq !== "YEARLY" && rule.freq !== "MONTHLY" && rule.freq !== "WEEKLY" && rule.freq !== "DAILY") {
				throw "Frequency must be provided and be one of the following: 'YEARLY', 'MONTHLY', 'WEEKLY', or 'DAILY'";
			}

			if (rule.until) {
				if (isNaN(Date.parse(rule.until))) {
					throw "until must be a valid date string";
				}
			}

			if (rule.interval) {
				if (isNaN(parseInt(rule.interval))) {
					throw "interval must be an integer";
				}
			}

			if (rule.count) {
				if (isNaN(parseInt(rule.count))) {
					throw "count must be an integer";
				}
			}

			if (typeof rule.byday !== "undefined") {
				if (Object.prototype.toString.call(rule.byday) !== "[object Array]") {
					throw "byday must be an array";
				}

				if (rule.byday.length > 7) {
					throw "byday array must not have more than 7 days";
				}

				rule.byday.forEach(it => {
					if (!days.find(it)) {
						throw "byday values must be a valid day of the week";
					}
				});
			}
		},
		/**
		 * Helper function for appending CRLF at start and end of file according to RFC rules.
		 * @function
		 * @param {string} string - iCalendar source string
		 * @return {string}
		 */
		addCRLF: function(string) {
			return `\n${string}\n`;
		},

		createIcs: function() {
			const event = this.createEvent(
				this.text.getSelectedLanguage()?.key,
				this.text.get(this.eventData.title),
				this.text.get(this.eventData.description.ical),
				this.text.get(this.eventData.location),
				this.eventData.start,
				this.eventData.end,
				this.eventData.url,
				null,
				this.eventData.recurrence
			);
			this.download(this.text.get(this.eventData.title), [event]);
		},

		createEvent: function(
			language = "en-ca",
			subject,
			description,
			location = "none",
			begin,
			stop,
			url = null,
			organizer = null,
			recurrence = null
		) {
			let recurrenceText;

			if (
				typeof subject === "undefined" ||
				typeof description === "undefined" ||
				typeof location === "undefined" ||
				typeof begin === "undefined" ||
				typeof stop === "undefined"
			) {
				throw "You need to specify function arguments";
			}
			// TODO - try to get rid of Date.parse as it is implementation dependent.
			if (recurrence && this.validateRepeatRule(recurrence)) {
				recurrenceText = `RRULE:FREQ=${recurrence.freq}`;
				if (recurrence.until) {
					let untilDate = new Date(Date.parse(recurrence.until)).toISOString();
					recurrenceText += `;UNTIL=${untilDate.substring(0, untilDate.length - 13).replace(/[-]/g, "")}000000Z`;
				}
				if (recurrence.interval) recurrenceText += `;INTERVAL=${recurrence.interval}`;
				if (recurrence.count) recurrenceText += `;COUNT=${recurrence.count}`;
				if (recurrence.byday && recurrence.byday.length > 0) recurrenceText += `;BYDAY=${recurrence.byday.join(",")}`;
			}

			let start_date = new Date(begin);
			let end_date = new Date(stop);
			let now_date = new Date();
			//const UID = `${now_date.getDay()}${now_date.getMonth()}${now_date.getFullYear()}-${now_date.getHours()}${now_date.getMinutes()}${now_date.getSeconds()}${getUniqueNumber()}`
			const UID = uuidv4();

			let start_year = `0000${start_date.getFullYear().toString()}`.slice(-4);
			let start_month = `00${(start_date.getMonth() + 1).toString()}`.slice(-2);
			let start_day = `00${start_date.getDate().toString()}`.slice(-2);
			let start_hours = `00${start_date.getHours().toString()}`.slice(-2);
			let start_minutes = `00${start_date.getMinutes().toString()}`.slice(-2);
			let start_seconds = `00${start_date.getSeconds().toString()}`.slice(-2);

			let end_year = `0000${end_date.getFullYear().toString()}`.slice(-4);
			let end_month = `00${(end_date.getMonth() + 1).toString()}`.slice(-2);
			let end_day = `00${end_date.getDate().toString()}`.slice(-2);
			let end_hours = `00${end_date.getHours().toString()}`.slice(-2);
			let end_minutes = `00${end_date.getMinutes().toString()}`.slice(-2);
			let end_seconds = `00${end_date.getSeconds().toString()}`.slice(-2);

			let now_year = `0000${now_date.getFullYear().toString()}`.slice(-4);
			let now_month = `00${(now_date.getMonth() + 1).toString()}`.slice(-2);
			let now_day = `00${now_date.getDate().toString()}`.slice(-2);
			let now_hours = `00${now_date.getHours().toString()}`.slice(-2);
			let now_minutes = `00${now_date.getMinutes().toString()}`.slice(-2);
			let now_seconds = `00${now_date.getSeconds().toString()}`.slice(-2);

			let start_time = "";
			let end_time = "";
			if (start_hours + start_minutes + start_seconds + end_hours + end_minutes + end_seconds != 0) {
				start_time = `T${start_hours}${start_minutes}${start_seconds}`;
				end_time = `T${end_hours}${end_minutes}${end_seconds}`;
			}
			let now_time = `T${now_hours}${now_minutes}${now_seconds}`;

			let start = start_year + start_month + start_day + start_time;
			let end = end_year + end_month + end_day + end_time;
			let now = now_year + now_month + now_day + now_time;

			const event = `
    BEGIN:VEVENT
    UID:${UID}@${uidDomain}
    ${url ? "URL:" + url : ""}
    DESCRIPTION:${description}${recurrenceText ? "\n" + recurrenceText : ""}
    DTSTAMP;VALUE=DATE-TIME:${now}
    DTSTART;VALUE=DATE-TIME:${start}
    DTEND;VALUE=DATE-TIME:${end}
    LOCATION:${location}
    ${organizer ? "ORGANIZER;CN=" + organizer.name + ":MAILTO:" + organizer.email : ""}
    SUMMARY;LANGUAGE=${language}:${subject}
    END:VEVENT
      `;
			return event;
		},
		/**
		 * Returns calendar
		 * @function
		 * @return {string} Calendar in iCalendar format
		 */
		createcalendar: function(events) {
			return `BEGIN:VCALENDAR
    VERSION:2.0
    PRODID:${prodId}
    ${events.join("\n")}
    END:VCALENDAR
      `
				.replace(/^\s*[\r\n]/gm, "")
				.replace(/^\s+/gm, "");
		},
		/**
		 * Download iCalendar file
		 * @function
		 * @param {string} filename  - Name of the file without extension
		 */
		download: function(filename, events) {
			const eventCalendar = this.createcalendar(events);
			//			var blob = new Blob([eventCalendar], { type: "text/x-vCalendar;charset=utf-8" });
			var blob = new Blob([eventCalendar], { type: "text/calendar" });
			saveAs(blob, `${filename}.ics`);
		}
	}
};
</script>
<style scoped lang="scss">
@import "../../scss/constants";

.add-to-calendar-component {
	border: 1px solid;
	border-radius: 3px;
	padding: 5px;
	background-color: var(--primary-colour);
	color: var(--primary-text-colour);
}
.add-to-calendar-item {
	display: inline-flex;
	align-items: center;
}
.add-to-calendar-component:hover {
	background-color: var(--secondary-colour);
	opacity: var(--hover-opacity);
}
</style>
