import { formatString } from "@/stringutilities";
import { log } from "@/logging";

const myLoggingName = "config";

export class Config {
	api = null;
	axios = null;
	hostName = null;
	modeKey = null;
	localConfig = null;
	isLocal = true;
	cacheLifetime = 600000;
	cachedRemoteConfig = {
		lastHit: null,
		data: null
	};

	constructor(axios, config, hostname, modeKey) {
		this.localConfig = config;
		this.modeKey = modeKey;
		if (config["serviceConfig"]) {
			let url = config["serviceConfig"]["url"];
			let resource = config["serviceConfig"]["resource"];
			let action = config["serviceConfig"]["configAction"];
			if (config["serviceConfig"]["cacheDuration"]) {
				this.cacheLifetime = config["serviceConfig"]["cacheDuration"];
			}
			//			this.api = url + resource + action;
			this.axios = axios;
			this.isLocal = false;
			this.hostName = hostname;
			const formattedUrl = this.addHostToUrl(url);
			this.api = formattedUrl + resource + action;
		} else if (config.domainToSiteMapping[hostname]) {
			this.hostName = hostname;
			// In local mode transform config here - once
			let site = this.localConfig.domainToSiteMapping[this.hostName];
			let tempConfig = this.localConfig.sites[site].config;
			tempConfig = this.transformConfig(tempConfig);
			this.localConfig.sites[site].config = tempConfig;
		} else {
			log(myLoggingName, "constructor - Configuration error for ${host}");
		}
		if (!(this.localConfig.splashPath && this.localConfig.splashPath.base && this.localConfig.splashPath.suffix)) {
			log(myLoggingName, "constructor - No splash");
		}
		this.hostName = hostname;
	}

	isCacheExpired(cache) {
		if (!cache.data || !cache.lastHit) {
			return true;
		}
		if (Date.now() - cache.lastHit > this.cacheLifeTime) {
			return true;
		}
		return false;
	}

	delay(t, v) {
		return new Promise(function(resolve) {
			setTimeout(resolve.bind(null, v), t);
		});
	}

	getHostName() {
		if (this.isLocal) {
			return this.localConfig.domainToSiteMapping[this.hostName];
		} else {
			return this.hostName;
		}
	}

	// It checks the siteMode element:
	// - it determines which modes are active and their url "keys"
	// - it filters pages as specified by pageInclusionMode and the pages array
	// - it filters the navbar elements based on the navBarKey

	// determines the active site mode. If there is no mode for the key, it defaults to the first
	// mode AND udpates the key to that mode's key.
	determineActiveSiteMode(siteConfig) {
		const siteMode = siteConfig.siteMode;
		const activeModes = siteMode.activeModes;
		if (!activeModes[this.modeKey]) {
			this.modeKey = Object.keys(activeModes)[0];
		}
		return activeModes[this.modeKey];
	}

	getActiveSiteMode(siteConfig, activeModeName) {
		const siteMode = siteConfig.siteMode;
		return siteMode.modes[activeModeName];
	}

	determinePageListNames(siteConfig, mode) {
		const pageMode = mode.pageInclusionMode;
		const pageFilter = mode.pages;
		let pages = new Array();
		const pageCandidates = siteConfig.pages;
		switch (pageMode) {
			case "explicit":
				pages = pages.concat(pageFilter);
				break;
			case "onList":
				pages = pageCandidates.filter(it => pageFilter.includes(it.routeName)).map(it => it.routeName);
				break;
			case "offList":
				pages = pageCandidates.filter(it => !pageFilter.includes(it.routeName)).map(it => it.routeName);
				break;
			default:
				break;
		}
		return pages;
	}

	determineList(siteConfig, listConfig) {
		const pageMode = listConfig.inclusionMode;
		const pageFilter = listConfig.items;
		let pages = new Array();
		const pageCandidates = siteConfig.pages;
		switch (pageMode) {
			case "explicit":
				pages = pages.concat(pageFilter);
				break;
			case "onList":
				pages = pageCandidates.filter(it => pageFilter.includes(it.routeName)).map(it => it.routeName);
				break;
			case "offList":
				pages = pageCandidates.filter(it => !pageFilter.includes(it.routeName)).map(it => it.routeName);
				break;
			default:
				break;
		}
		return pages;
	}

	merge(filterCriteria, items) {
		const rc = filterCriteria.map(filter => {
			let filteredItems = new Array();
			const pageMode = filter.inclusionMode;
			switch (pageMode) {
				case "explicit":
					filteredItems = filteredItems.concat(filter.items);
					break;
				case "onList":
					filteredItems = items.filter(it => filter.items.includes(it.routeName)).map(it => it.routeName);
					break;
				case "offList":
					filteredItems = items.filter(it => !filter.items.includes(it.routeName)).map(it => it.routeName);
					break;
				default:
					break;
			}
			const value = filter.value ?? null;
			return { value: value, items: filteredItems };
		});
		return rc;
	}

	// process navbar filters to create [navbartype][navbaritem]=filter.value.

	transformConfigPages(siteConfigPages, activePageList) {
		let pages = siteConfigPages.filter(it => activePageList.includes(it.routeName));
		return pages;
	}
	transformNavBar(navbar, modeName) {
		return navbar.modes[modeName];
	}

	getGuardedPageNames(siteConfig) {
		const activeMode = this.determineActiveSiteMode(siteConfig);
		const mode = this.getActiveSiteMode(siteConfig, activeMode.mode);
		const pageNames = this.merge(mode.pageGuards, siteConfig.pages)
			.map(it => it.items)
			.flat();
		return pageNames;
	}

	transformConfig(siteConfig) {
		const activeMode = this.determineActiveSiteMode(siteConfig);
		const mode = this.getActiveSiteMode(siteConfig, activeMode.mode);
		const activePages = this.merge(mode.pages, siteConfig.pages)
			.map(it => it.items)
			.flat();
		const pages = this.transformConfigPages(siteConfig.pages, activePages);
		const navbar = this.transformNavBar(siteConfig.common.navBar, mode.navBar.key);
		siteConfig.pages = pages;
		siteConfig.common.navBar = navbar;
		return siteConfig;
	}

	/**
	 * Get remote configuration
	 */
	async getConfig() {
		const headers = {};
		if (!this.isCacheExpired(this.cachedRemoteConfig)) {
			return new Promise(resolve => {
				resolve(this.cachedRemoteConfig.data);
			});
		}
		try {
			//await this.delay(10000);
			let rc = await this.axios.post(this.api, JSON.stringify({ host: this.hostName }), {
				headers: headers,
				contentType: "application/json"
			});
			let config = rc.data;
			let siteConfig = config.config;
			siteConfig = this.transformConfig(siteConfig);
			config.config = siteConfig;

			this.cachedRemoteConfig.data = config;
			this.cachedRemoteConfig.lastHit = Date.now();
			if (config["cacheDuration"]) {
				this.cacheLifeTime = config["cacheDuration"];
			}
			return config;
		} catch (error) {
			log(myLoggingName, "getConfig error " + error);
			throw "Error retrieving data";
		}
	}

	/**
	 * Get site configuration. If local, returns local configuration for host, else retrieves remote configuration service
	 */
	async getSiteConfig() {
		if (this.isLocal) {
			let site = this.localConfig.domainToSiteMapping[this.hostName];
			return new Promise(resolve => {
				let config = this.localConfig.sites[site].config;
				//config = this.transformConfig(config);
				resolve(config);
			});
		} else {
			let response = await this.getConfig();
			return response.config;
		}
	}

	/**
	 * Get site authorization. If local, returns local configuration for host, else retrieves remote configuration service
	 */
	async getAuth() {
		if (this.isLocal) {
			let site = this.localConfig.domainToSiteMapping[this.hostName];
			let siteConfig = this.localConfig.sites[site];

			let cognitoAuth = {
				Auth: {
					region: siteConfig["cognitoPoolRegion"],
					userPoolId: siteConfig["cognitoPoolId"],
					userPoolWebClientId: siteConfig["cognitoPoolClientId"]
				}
			};
			const isPasswordless = "isPasswordless" in siteConfig.config && siteConfig.config["isPasswordless"];
			let stubPassword = null;
			if (isPasswordless) {
				stubPassword = siteConfig.config["stubPassword"];
			}
			let passwordRules = siteConfig.config["passwordRules"];
			if (!passwordRules) {
				passwordRules = {
					rulesDescription: { en: "Passwords must be at least 8 characters long" },
					rules: {
						lowerCase: {
							minRequired: 0,
							type: "minRequiredLower",
							messages: { en: "Password must have at least {required} lower case letter(s)." }
						},
						upperCase: {
							minRequired: 0,
							type: "minRequiredUpper",
							messages: { en: "Password must have at least {required} upper case letter(s)." }
						},
						number: {
							minRequired: 0,
							type: "minRequiredNumber",
							messages: { en: "Password must have at least {required} number(s)." }
						},
						length: {
							minRequired: 8,
							type: "minLength",
							messages: { en: "Password must be at least {required} character(s) long." }
						},
						special: {
							minRequired: 0,
							type: "minRequiredInList",
							required: "^$*.[]{}()?\"!@#%&/\\,><':;|_~`=+-",
							messages: { en: "Password must have at least {required} special character(s)." }
						}
					}
				};
			}

			let authConfig = {
				isPasswordless: isPasswordless,
				stubPassword: stubPassword,
				serviceProviderConfig: {
					type: "Cognito",
					configuration: cognitoAuth,
					identityPoolId: siteConfig["cognitoIdentityPoolId"]
				},
				passwordRules: passwordRules
			};
			return new Promise(resolve => {
				resolve(authConfig);
			});
		} else {
			let response = await this.getConfig();
			const isPasswordless = "isPasswordless" in response.config && response.config["isPasswordless"];
			let stubPassword = null;
			if (isPasswordless) {
				stubPassword = response.config["stubPassword"];
			}
			let cognitoAuth = {
				Auth: {
					region: response["cognitoPoolRegion"],
					userPoolId: response["cognitoPoolId"],
					userPoolWebClientId: response["cognitoPoolClientId"]
				}
			};
			let passwordRules = response["passwordRules"];
			if (!passwordRules) {
				passwordRules = {
					rulesDescription: { en: "Passwords must be at least 8 characters long" },
					rules: {
						lowerCase: {
							minRequired: 0,
							type: "minRequiredLower",
							messages: { en: "Password must have at least {required} lower case letter(s)." }
						},
						upperCase: {
							minRequired: 0,
							type: "minRequiredUpper",
							messages: { en: "Password must have at least {required} upper case letter(s)." }
						},
						number: {
							minRequired: 0,
							type: "minRequiredNumber",
							messages: { en: "Password must have at least {required} number(s)." }
						},
						length: {
							minRequired: 8,
							type: "minLength",
							messages: { en: "Password must be at least {required} character(s) long." }
						},
						special: {
							minRequired: 0,
							type: "minRequiredInList",
							required: "^$*.[]{}()?\"!@#%&/\\,><':;|_~`=+-",
							messages: { en: "Password must have at least {required} special character(s)." }
						}
					}
				};
			}
			let authConfig = {
				isPasswordless: isPasswordless,
				stubPassword: stubPassword,
				serviceProviderConfig: {
					type: "Cognito",
					configuration: cognitoAuth,
					identityPoolId: response["cognitoIdentityPoolId"]
				},
				passwordRules: passwordRules
			};
			return authConfig;
		}
	}

	/**
	 * Get site protected resources. If local, returns local resources for host, else retrieves remote configuration service
	 */
	//TODO DEPRECATED? I think protectedResource uses getLocalResources. If not used, remove.
	async getResources() {
		if (this.isLocal) {
			let site = this.localConfig.domainToSiteMapping[this.hostName];
			//let siteConfig = this.localConfig.sites[site];
			let config = this.localConfig.sites[site];
			//config = this.transformConfig(config);
			return new Promise(resolve => {
				resolve(config["localResources"]["protectedResources"]);
			});
		} else {
			let response = await this.getConfig();
			return response["resources"];
		}
	}

	/**
	 * Get site local resourcses. This includes protected resources and some other resources that might otherwise be hosted remotely, e.g. arbitrary data.
	 *
	 * Only returns resources if site is in local mode, else throws error.
	 */
	getLocalResources() {
		if (this.isLocal) {
			let site = this.localConfig.domainToSiteMapping[this.hostName];
			//let siteConfig = this.localConfig.sites[site];
			let config = this.localConfig.sites[site];
			//config = this.transformConfig(config);
			return config["localResources"];
		}
		throw "Not in local mode";
	}

	getLoadingConfig() {
		const loadingConfig = this.localConfig.loadingConfig;
		if (!loadingConfig) {
			return null;
		}
		let splashConfig = null;
		if (loadingConfig.splashConfig) {
			splashConfig = {
				url: this.getSplashUrl(loadingConfig.splashConfig)
			};
		}
		return {
			type: loadingConfig.type,
			autoTransit: loadingConfig.autoTransit,
			splashConfig: splashConfig
		};
	}

	/**
	 * Determine the hostname based on real host name and site configuration - overrides host name if in local dev mode and an override is defined -
	 * and replaces it in the supplied template
	 * @param {*} urlFormatString
	 */
	addHostToUrl(urlFormatString) {
		let hostName = this.hostName.split(".")[0];
		if (!this.localConfig["serviceConfig"] && this.localConfig.domainToSiteMapping[this.hostName]) {
			hostName = this.localConfig.domainToSiteMapping[this.hostName];
		}
		return formatString(urlFormatString, { hostName: hostName });
	}

	getSplashUrl(splashConfig) {
		let hostBase = this.hostName.split(".")[0];
		if (splashConfig && splashConfig.base && splashConfig.suffix) {
			if (hostBase === "localhost" && splashConfig.localHostOverride) {
				hostBase = splashConfig.localHostOverride;
			}
			const rc = splashConfig.base + hostBase + splashConfig.suffix;
			return rc;
		} else {
			return null;
		}
	}
}
