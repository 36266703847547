<template>
	<div class="authentication-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="authentication-wrapper">
			<div class="client-logo">
				<img class="logo-img" :src="common.logo" alt="" />
			</div>
			<div class="form-error" v-if="error">
				{{ errorText }}
			</div>
			<div class="signin-wrapper" v-if="activeComponent === 'Signin'">
				<signin
					v-bind:config="config.signin"
					v-bind:common="common"
					v-on:login="login"
					v-on:forgotPassword="switchToForgotPassword"
					v-on:completeAccountConfirmation="switchToCompleteAccountConfirmation"
				>
				</signin>
			</div>
			<div class="signin-wrapper" v-if="activeComponent === 'PasswordlessSignin'">
				<passwordless-signin v-on:login="passwordlessLogin" v-bind:common="common" :text="text.shiftContext('passwordlessSignin')">
				</passwordless-signin>
			</div>
			<div v-if="activeComponent === 'PasswordlessRegistrationAttributeGatherer'">
				<user-attributes
					ref="attributes"
					v-on:update="passwordlessRegistration"
					v-bind:config="config.attributes"
					v-bind:common="common"
					v-bind:userData="passwordlessRegistrationAttributeGathererProps"
					:text="text.shiftContext('userAttributes')"
				>
					<template v-slot:changed="{ changed }">
						<b-button type="button" v-on:click="changed" class="btn mr-1" variant="primary" value="Update User Information">
							{{ text.getByKey("userAttributesChangeSlot", { default: "Update", en: "Update", fr: "Réviser" }) }}
						</b-button>
					</template>
				</user-attributes>
				<br />
				<need-help v-bind:common="common" :text="text.shiftContext('needHelp')" />
			</div>
			<div class="user-password-reset-wrapper" v-if="activeComponent === 'UserPasswordReset'">
				<user-password-reset
					v-on:requestPasswordReset="sendForgotPasswordRequest"
					v-on:cancel="initialState"
					v-bind:title="forgotPasswordTitle"
					v-bind:buttonText="forgotPasswordButtonText"
					:text="text.shiftContext('userPasswordReset')"
				>
				</user-password-reset>
			</div>
			<div class="admin-password-reset-wrapper" v-if="activeComponent === 'AdminPasswordReset'">
				<admin-password-reset
					v-on:requestPasswordReset="startAdminPasswordReset"
					v-on:cancel="initialState"
					v-bind:code="adminResetCode"
					:text="text.shiftContext('adminPasswordReset')"
				>
				</admin-password-reset>
			</div>
			<div class="new-password-wrapper" v-if="activeComponent === 'NewPassword'" v-bind:userName="id">
				<div class="title">
					{{
						text.getByKey("newPasswordWrapperTitle", {
							default: "Change Password",
							en: "Change Password",
							fr: "Changer le Mot de Passe"
						})
					}}
				</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("newPasswordWrapperInstructions", {
							default: "Please create your new password",
							en: "Please create your new password",
							fr: "Veuillez créer votre nouveau mot de passe"
						})
					}}
				</div>
				<new-password v-on:changePassword="completeNewPassword" :text="text.shiftContext('newPassword')">
					<template v-slot:password1Error="{ error }">
						<div class="form-error" v-show="error.length > 0">
							<ul>
								<li v-for="passwordError in error" :key="passwordError" style="text-align:left;">{{ passwordError }}</li>
							</ul>
						</div>
					</template>
					<template v-slot:password2Error="{ error }">
						<div class="form-error" v-show="error">{{ error }}</div>
					</template>
					<template v-slot:changed="{ changed }">
						<button type="button" v-on:click="changed" class="btn btn-outline-secondary" value="Change Password">
							{{
								text.getByKey("newPasswordChangeSlot", {
									default: "Change Password",
									en: "Change Password",
									fr: "Changer le Mot de Passe"
								})
							}}
						</button>
					</template>
				</new-password>
			</div>
			<div class="new-codeandpass-word-wrapper" v-if="activeComponent === 'CodeAndNewPassword'">
				<code-and-new-password
					v-on:changePassword="completeNewCodeAndPassword"
					v-bind:initialVerificationCode="adminResetCode"
					:text="text.shiftContext('codeAndNewPassword')"
				>
				</code-and-new-password>
			</div>
			<br />
			<span v-show="activeComponent === 'Waiting'">
				<b-spinner variant="primary" label="Waiting" style="position: absolute; top: calc(50%)"></b-spinner>
			</span>
		</div>
	</div>
</template>
<script>
import Background from "../components/Background";
import Signin from "../components/Signin";
import NeedHelp from "../components/NeedHelp";
import PasswordlessSignin from "../components/PasswordlessSignin";
import UserPasswordReset from "../components/UserPasswordReset";
import NewPassword from "../components/NewPassword";
import CodeAndNewPassword from "../components/CodeAndNewPassword";
import AdminPasswordReset from "../components/AdminPasswordReset";
//import SetUserAttributes from "../components/userattribute/SetUserAttributes";
import UserAttributes from "../components/userattribute/UserAttributes";
//import SendNotice from "../notification/model/sendNotice";

import { log } from "@/logging";
//import { getElementByPath } from "@/stringutilities";

const myLoggingName = "Authentication";

export default {
	name: "Authentication",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object,
		homePage: String
	},
	data: () => ({
		id: null,
		activeComponent: "Signin",
		error: {},
		passwordlessRegistrationAttributeGathererProps: {},
		forgotPasswordTitle: "",
		forgotPasswordType: "userInitiated",
		forgotPasswordButtonText: "",
		adminResetCode: "",
		wasMounted: false,
		myAnalyticsContext: null
	}),
	inject: ["authService", "notificationService", "textService"],
	components: {
		Background,
		Signin,
		UserPasswordReset,
		NewPassword,
		CodeAndNewPassword,
		PasswordlessSignin,
		UserAttributes,
		AdminPasswordReset,
		NeedHelp
	},
	computed: {
		text() {
			return this.textService.copy(this.config.text, ["root", this.$route.name]);
		},
		errorText() {
			return this.text.getByKey(this.error.key, this.error.text);
		}
	},
	mounted() {
		log(myLoggingName, "mounted");

		if (!this.wasMounted && this.$route.params.event === "completeRegistration" && !this.authService.isPasswordlessMode()) {
			this.switchToCompleteAccountConfirmation();
		} else if (
			!this.wasMounted &&
			this.$route.params.event === "adminPasswordReset" &&
			!this.authService.isPasswordlessMode() &&
			this.$route.params.code
		) {
			this.initialState();
			this.switchToAdminResetPassword(this.$route.params.code);
		} else {
			this.initialState();
		}
		this.wasMounted = true;
		this.myAnalyticsContext = this.analyticsContext;
	},
	beforeUpdate() {
		// Likely unnecessary sanity check

		if (
			this.activeComponent !== this.signInComponent() &&
			this.activeComponent !== "UserPasswordReset" &&
			this.activeComponent !== "AdminPasswordReset" &&
			this.activeComponent !== "NewPassword" &&
			this.activeComponent !== "CodeAndNewPassword" &&
			this.activeComponent !== "PasswordlessRegistrationAttributeGatherer" &&
			this.activeComponent !== "Waiting"
		) {
			this.activeComponent = this.signInComponent();
		}
	},
	methods: {
		clearError: function() {
			this.error = {};
		},
		signInComponent() {
			let signInComponent = "Signin";
			if (this.authService.isPasswordlessMode()) {
				signInComponent = "PasswordlessSignin";
			}
			return signInComponent;
		},
		initialStateData: function(clearError = true) {
			this.id = null;
			if (clearError) {
				this.clearError();
			}
			this.passwordlessRegistrationAttributeGathererProps = {};
			this.forgotPasswordTitle = this.text.getByKey("defaultforgotPasswordTitle", {
				default: "Reset Password",
				en: "Reset Passsword",
				fr: "Réinitialiser le Mot de Passe"
			});
			this.forgotPasswordType = "userInitiated";
			this.forgotPasswordButtonText = this.text.getByKey("defaultForgotPasswordButtonText", {
				default: "Request Password Reset",
				en: "Request Password Reset",
				fr: "Demander la Réinitialisation du Mot de Passe"
			});
			this.adminResetCode = "";
		},
		initialState: function(clearError = true) {
			//this.id = null;
			this.activeComponent = this.signInComponent();
			this.initialStateData(clearError);
		},
		// See
		// https://github.com/aws-amplify/amplify-js/blob/1795d461a5b6d2daee31d4e609d2d27fb5d37018/packages/aws-amplify-react/src/Auth/SignIn.jsx#L81
		// and friends for aws's version

		// Handle passwordless registration
		passwordlessRegistration: function(credentials) {
			let capturedVue = this;
			capturedVue.clearError();
			log(myLoggingName, "passwordlessRegistration - entry");
			const oldComponent = this.activeComponent;
			capturedVue.switchToWaiting();
			this.authService
				.passwordlessSignup(credentials.userName, credentials.attributes)
				.then(() => {
					capturedVue.authService.passwordlessLogin(credentials.userName).then(isLoggedIn => {
						if (isLoggedIn) {
							log(myLoggingName, "passwordlessRegistration - Validated credentials post registration");
							capturedVue.sendPostRegistrationNotice();
							capturedVue.trackLogin("implicit");
							capturedVue.$router.replace({ name: capturedVue.config.postSigninPage?.routeName ?? capturedVue.homePage }); // original was "'/"
							capturedVue.initialState();
						} else {
							capturedVue.activeComponent = oldComponent;
							throw "UnexpectedLoginFailure";
						}
					});
				})
				.catch(err => {
					log(myLoggingName, "passwordlessRegistration - Error " + err);
					capturedVue.error = {
						key: "genericAuthenticationError",
						text: {
							default: "Authentication failed. Please try again",
							en: "Authentication failed. Please try again",
							fr: "Échec de l'authentification. Veuillez réessayer"
						}
					};
					capturedVue.initialState(false);
				});
		},

		trackLogin(eventType) {
			try {
				const values = new Map();
				if (this.config.analytics.captureAttributes?.length > 0) {
					const user = this.authService.user;
					this.config.analytics.captureAttributes.forEach(key => {
						const value = {
							type: "String",
							value: user.attributes[key]
						};
						values.set("userAttribute" + key, value);
					});
					let value = {
						type: "String",
						value: eventType
					};
					values.set("type", value);

					const event = this.myAnalyticsContext.makeEvent("login", "event", values);
					this.myAnalyticsContext.recordEvent(event);
				}
			} catch (err) {
				log(myLoggingName, "analytics", "error", err);
			}
		},
		createPostRegistrationNotificiation: function(configuration) {
			let tags = new Map();
			Object.keys(configuration.miscTags).forEach(key => {
				tags.set(key, this.text.get(configuration.miscTags[key]));
			});

			const action = this.text.get(configuration.name);
			let sendNotice = {
				action: action,
				attributeNames: configuration.attributeNames,
				miscTags: tags
			};
			return sendNotice;
		},
		sendPostRegistrationNotice: function() {
			if (this.config.notification.postRegistrationAction) {
				let sendNotice = this.createPostRegistrationNotificiation(this.config.notification.postRegistrationAction);
				this.notificationService.process(sendNotice, this.authService.token);
			}
		},
		// Handle passwordless login
		passwordlessLogin: function(credentials) {
			let capturedVue = this;
			capturedVue.clearError();
			log(myLoggingName, "passwordlessLogin - Attempting to validate passwordless credentials");
			const oldComponent = this.activeComponent;
			this.switchToWaiting();
			this.authService
				.passwordlessLogin(credentials.userName)
				.then(isLoggedIn => {
					// success, go to root
					if (isLoggedIn) {
						log(myLoggingName, "passwordlessLogin - Validated credentials");
						capturedVue.trackLogin("explicit");
						//capturedVue.$router.replace({ name: capturedVue.homePage }); // original was "'/"
						capturedVue.$router.replace({ name: capturedVue.config.signin.postSigninPage?.routeName ?? capturedVue.homePage }); // original was "'/"
						capturedVue.switchToDone();
					} else {
						// check mode, if attributes are requested, then switch to register passwordless (in that state
						// the collect attributes component will be active and will attempt to signup and login user)
						// if attributes are not requested, then signup and login user
						if (capturedVue.config.attributes?.fields && capturedVue.config.attributes.fields.length > 0) {
							capturedVue.switchToRegisterPasswordlessWithAttributes(credentials.userName);
						} else {
							// this handles the case in which there are no attributes and we can immediately sign them up and
							// log them in - this may be a candidate for refactoring.
							// for analytics, even though this was a registration, because of the auto registration nature of
							// the passwordless login workflow, we treat the event as login instead of implicitLogin
							capturedVue.authService
								.passwordlessSignup(credentials.userName, { email: credentials.userName })
								.then(() => capturedVue.authService.passwordlessLogin(credentials.userName))
								.then(isLoggedIn => {
									if (isLoggedIn) {
										log(myLoggingName, "passwordlessLogin - Validated credentials post registration");
										capturedVue.sendPostRegistrationNotice();
										capturedVue.trackLogin("explicit");
										capturedVue.$router.replace({
											name: capturedVue.config.signin.postSigninPage?.routeName ?? capturedVue.homePage
										}); // original was "'/"
										capturedVue.initialState();
									} else {
										capturedVue.activeComponent = oldComponent;
										throw "UnexpectedLoginFailure";
									}
								});
							//;
						}
					}
				})
				.catch(err => {
					log(myLoggingName, "passwordlessLogin - Error " + err);
					capturedVue.activeComponent = oldComponent;
					capturedVue.error = {
						key: "genericAuthenticationError",
						text: {
							default: "Authentication failed. Please try again",
							en: "Authentication failed. Please try again",
							fr: "Échec de l'authentification. Veuillez réessayer"
						}
					};
				});
		},

		// Handle login
		login: function(credentials) {
			let capturedVue = this;
			this.clearError();
			log(myLoggingName, "login - Attempting to validate credentials");
			const oldComponent = this.activeComponent;
			this.switchToWaiting();

			this.authService
				.login(credentials.userName, credentials.password)
				.then(() => {
					// success, go to root
					log(myLoggingName, "login - Validated credentials");
					capturedVue.trackLogin("explicit");
					//capturedVue.$router.replace({ name: capturedVue.homePage }); // original was "'/"
					capturedVue.$router.replace({ name: capturedVue.config.signin.postSigninPage?.routeName ?? capturedVue.homePage }); // original was "'/"
					//capturedVue.initialState();
					capturedVue.switchToDone();
				})
				.catch(err => {
					if (err === "NEW_PASSWORD_REQUIRED") {
						log(myLoggingName, "login - Start change password for admin created account");
						capturedVue.activeComponent = "NewPassword";
						capturedVue.id = credentials.userName;
					} else if (err === "PasswordResetRequiredException") {
						log(myLoggingName, "login - Start change password for admin forced reset");
						capturedVue.activeComponent = "CodeAndNewPassword";
						capturedVue.id = credentials.userName;
					} else if (err === "NotAuthorizedException") {
						log(myLoggingName, "login - Not authorized");
						capturedVue.error = {
							key: "genericAuthenticationError",
							text: {
								default: "User name and password do not match our records. Please try again",
								en: "User name and password do not match our records. Please try again",
								fr: "Le nom d'utilisateur et le mot de passe ne correspondent pas à nos dossiers. Veuillez réessayer"
							}
						};
						capturedVue.activeComponent = oldComponent;
					} else {
						log(myLoggingName, "login - Error", err);
						capturedVue.error = {
							key: "genericAuthenticationError",
							text: {
								default: "Authentication failed. Please try again",
								en: "Authentication failed. Please try again",
								fr: "Échec de l'authentification. Veuillez réessayer"
							}
						};
						capturedVue.activeComponent = oldComponent;
					}
				});
		},

		// User is registering for passwordless and requested (or forced) to supply attributes
		switchToRegisterPasswordlessWithAttributes: function(userName) {
			this.clearError();
			log(myLoggingName, "switchToRegisterPasswordlessWithAttributes - switchToRegisterPasswordlessWithAttributes");
			this.activeComponent = "PasswordlessRegistrationAttributeGatherer";
			this.passwordlessRegistrationAttributeGathererProps = { userName: userName };
			this.forgotPasswordTitle = "";
			this.forgotPasswordButtonText = "";
			this.forgotPasswordType = "";
		},

		// User wants to start the forgot password workflow
		switchToForgotPassword: function() {
			this.clearError();
			log(myLoggingName, "switchToForgotPassword");
			this.activeComponent = "UserPasswordReset";
			this.passwordlessRegistrationAttributeGathererProps = {};
			this.forgotPasswordTitle = this.text.getByKey("switchToForgotPasswordTitle", {
				default: "Request Password Reset",
				en: "Request Password Reset",
				fr: "Demander la Réinitialisation du Mot de Passe"
			});
			this.forgotPasswordButtonText = this.text.getByKey("switchToForgotPasswordButtonText", {
				default: "Request Password Reset",
				en: "Request Password Reset",
				fr: "Demander la Réinitialisation du Mot de Passe"
			});

			this.forgotPasswordType = "userInitiated";
			this.adminResetCode = "";
		},
		// User wants to start the admin reset password workflow - triggered by an email sent by Cognito in response to
		// a forgot password request or an admin forced reset.
		switchToAdminResetPassword: function(code) {
			this.clearError();
			log(myLoggingName, "switchToAdminResetPassword");
			this.activeComponent = "AdminPasswordReset";
			this.passwordlessRegistrationAttributeGathererProps = {};
			this.adminResetCode = code;
		},
		switchToCompleteAccountConfirmation: function() {
			this.clearError();
			log(myLoggingName, "switchToCompleteAccountConfirmation - switchToForgotPassword for account confirmation");
			this.activeComponent = "UserPasswordReset";
			this.passwordlessRegistrationAttributeGathererProps = {};
			this.forgotPasswordType = "accountCreationInitiated";
			this.forgotPasswordTitle = this.text.getByKey("switchToCompletePasswordTitle", {
				default: "Complete Account Creation",
				en: "Complete Account Creation",
				fr: "Terminer la Création du Compte"
			});
			this.forgotPasswordButtonText = this.text.getByKey("switchToCompletePasswordButtonText", {
				default: "Create Password",
				en: "Create Password",
				fr: "Créer un Mot de Passe"
			});
		},
		// Start admin password reset for supplied email
		switchToWaiting: function() {
			this.clearError();
			log(myLoggingName, "switchToWaiting");
			this.activeComponent = "Waiting";
		},
		switchToDone: function() {
			this.initialStateData();
			log(myLoggingName, "switchToDone");
			this.activeComponent = "Waiting";
		},

		// Send forgot password request to Authentication service
		sendForgotPasswordRequest: function(request) {
			this.clearError();
			log(myLoggingName, "sendForgotPasswordRequest");
			let capturedVue = this;
			this.authService
				.forgotPassword(request.userName, capturedVue.forgotPasswordType)
				.then(() => {
					log(myLoggingName, "sendForgotPasswordRequest - request accepted");
					capturedVue.activeComponent = "CodeAndNewPassword";
					capturedVue.id = request.userName;
				})
				.catch(err => {
					log(myLoggingName, "sendForgotPasswordRequest - exception ", err);
					capturedVue.error = {
						key: "passwordChangedError",
						text: {
							default: "Change password failed. Please try again",
							en: "Change password failed. Please try again",
							fr: "La modification du mot de passe a échoué. Veuillez réessayer"
						}
					};
				});
		},
		// Start admin password reset for supplied email
		startAdminPasswordReset: function(request) {
			this.clearError();
			log(myLoggingName, "startAdminPasswordReset");
			this.activeComponent = "CodeAndNewPassword";
			this.id = request.userName;
		},
		// Complete the password reset - send code and new password to Authentication service
		completeNewCodeAndPassword: function(newCredentials) {
			this.clearError();
			let capturedVue = this;
			log(myLoggingName, "completeNewCodeAndPassword called");
			this.authService
				.changePasswordWithCode(capturedVue.id, newCredentials.verificationCode, newCredentials.password)
				.then(() => {
					log(myLoggingName, "completeNewCodeAndPassword - password set");
					capturedVue.switchToWaiting();
					capturedVue.autoLoginPostPasswordChange({ userName: capturedVue.id, password: newCredentials.password });
					//this.initialState();
					capturedVue.switchToDone();
				})
				.catch(err => {
					log(myLoggingName, "completeNewCodeAndPassword - exception ", err);
					capturedVue.error = {
						key: "passwordChangedError",
						text: {
							default: "Change password failed. Please try again",
							en: "Change password failed. Please try again",
							fr: "La modification du mot de passe a échoué. Veuillez réessayer"
						}
					};
				});
		},

		autoLoginPostPasswordChange: function(credentials) {
			if (!this.config.signin.isAutoLoginAfterPasswordChange) {
				return;
			}
			let capturedVue = this;
			this.clearError();
			log(myLoggingName, "autoLoginPostPasswordChange - Attempting to validate credentials");
			this.authService
				.login(credentials.userName, credentials.password)
				.then(() => {
					// success, go to root
					log(myLoggingName, "login - Validated credentials");
					capturedVue.trackLogin("implicit");
					capturedVue.$router.replace({ name: capturedVue.homePage }); // original was "'/"
					//capturedVue.initialState();
					capturedVue.switchToDone();
				})
				.catch(err => {
					log(myLoggingName, "login - Error", err);
					capturedVue.error = {
						key: "genericAuthenticationError",
						text: {
							default: "Authentication failed. Please try again",
							en: "Authentication failed. Please try again",
							fr: "Échec de l'authentification. Veuillez réessayer"
						}
					};
				});
		},

		// Complete the change password request - send new password to Authentication service
		completeNewPassword: function(newCredentials) {
			this.clearError();
			log(myLoggingName, "completeNewPassword");
			let capturedVue = this;
			// Need to implement this
			this.authService
				.changePassword(newCredentials.password)
				.then(() => {
					log(myLoggingName, "completeNewPassword - new password set");
					capturedVue.switchToWaiting();
					capturedVue.autoLoginPostPasswordChange({ userName: capturedVue.id, password: newCredentials.password });
					//this.initialState();
					capturedVue.switchToDone();
				})
				.catch(err => {
					log(myLoggingName, "completeNewPassword - exception ", err);
					capturedVue.error = {
						key: "passwordChangedError",
						text: {
							default: "Change password failed. Please try again",
							en: "Change password failed. Please try again",
							fr: "La modification du mot de passe a échoué. Veuillez réessayer"
						}
					};
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";
.client-logo {
	display: block;
	margin: 0 auto;
	max-width: 300px;
	padding-bottom: 40px;
}

.authentication-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.authentication-wrapper {
	position: relative;
	width: 100%;
	max-width: 600px;
	padding: 60px;

	display: flex;
	flex-direction: column;

	color: #000;

	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.bg {
		width: 100%;
		max-width: none;
		border-radius: 0;
		box-shadow: none;
	}
}
</style>
