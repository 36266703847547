<template>
	<div class="self-register-component">
		<div class="self-register-wrapper">
			<form id="selfRegisterForm">
				<div class="title">
					{{
						text.getByKey("title", {
							default: "Register",
							en: "Register",
							fr: "Enregistrer"
						})
					}}
				</div>
				<br />
				<div class="supply-credentials" v-show="activeComponent === 'Credentials'">
					<div class="instructions">
						<strong>
							{{
								text.getByKey("instructions", {
									default: "Please start by entering your email address and creating a password for your new account.",
									en: "Please start by entering your email address and creating a password for your new account.",
									fr:
										"Veuillez commencer par saisir votre adresse e-mail et créer un mot de passe pour votre nouveau compte."
								})
							}}
						</strong>
					</div>
					<br />
					<div class="form-group">
						<input
							class="form-control"
							type="email"
							autocomplete="username"
							id="emailInputRegister"
							:placeholder="
								text.getByKey('userName', {
									default: 'Email',
									en: 'Email',
									fr: 'E-mail'
								})
							"
							required
							v-model="userName"
						/>
						<slot name="userNameError">
							<div class="form-error" v-show="userNameError">{{ userNameError }}</div>
						</slot>
					</div>

					<new-password ref="password" :text="text.shiftContext('newPassword')">
						<template v-slot:password1Error="{ error }">
							<div class="form-error" v-show="error.length > 0">
								<ul>
									<li v-for="passwordError in error" :key="passwordError" style="text-align:left;">
										{{ passwordError }}
									</li>
								</ul>
							</div>
						</template>
						<template v-slot:password2Error="{ error }">
							<div class="form-error" v-show="error">{{ error }}</div>
						</template>
					</new-password>
					<b-button type="button" class="btn mr-1" v-on:click="gatheredCredentials" variant="primary" value="Next">
						{{ nextButtonText }}
					</b-button>
				</div>

				<div class="supply-attributes" v-show="activeComponent === 'Attributes'">
					<user-attributes
						ref="attributes"
						v-bind:config="config.attributes"
						v-bind:common="common"
						v-bind:userData="userData"
						:text="text.shiftContext('userAttributes')"
					>
					</user-attributes>
					<div>
						<b-button type="button" class="btn mr-1" v-on:click="register" variant="primary" value="Register">
							{{
								text.getByKey("registerButtonText", {
									default: "Register",
									en: "Register",
									fr: "Enregistrer"
								})
							}}
						</b-button>
						<b-button type="button" class="btn mr-1" v-on:click="back" variant="secondary" value="Back">
							{{
								text.getByKey("backButtonText", {
									default: "Back",
									en: "Back",
									fr: "Retour"
								})
							}}
						</b-button>
					</div>
				</div>
				<br />
				<need-help v-bind:common="common" :text="text.shiftContext('needHelp')" />
				<!-- <div id="signinHelp" class="instructions">
				Need help? Please contact
				<b-link v-bind:href="supportEmail">{{ supportHost }}</b-link>
			</div> -->
			</form>
		</div>
	</div>
</template>

<script>
import NewPassword from "./NewPassword";
import UserAttributes from "./userattribute/UserAttributes";
import NeedHelp from "../components/NeedHelp";

// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			userName: "",
			activeComponent: "Credentials",
			userNameError: ""
		};
	},
	props: {
		config: Object,
		common: Object,
		text: Object
	},
	//inject: ["languageService"],

	components: { NewPassword, UserAttributes, NeedHelp },
	name: "SelfRegister",
	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after submit so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		}
	},
	computed: {
		userData() {
			return { userName: this.userName };
		},
		nextButtonText() {
			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				return this.text.getByKey("nextButtonText", {
					default: "Next",
					en: "Next",
					fr: "Poursuivre"
				});
			} else {
				return this.text.getByKey("registerButtonText", {
					default: "Register",
					en: "Register",
					fr: "Enregistrer"
				});
			}
		}
	},
	mounted() {},
	beforeUpdate() {
		// Likely unnecessary sanity check

		if (this.activeComponent !== "Credentials" && this.activeComponent !== "Attributes") {
			this.activeComponent = "Credentials";
		}
	},

	methods: {
		initialState: function() {
			this.activeComponent = "Credentials";
			this.userNameError = "";
			this.userName = "";
		},
		back() {
			this.activeComponent = "Credentials";
			return;
		},

		gatheredCredentials() {
			this.userNameError = "";
			if (!this.userName || this.userName.length == 0) {
				this.userNameError = this.text.getByKey("userNameError", {
					default: "Please provide an email address",
					en: "Please provide an email address",
					fr: "Veuillez fournir une adresse de courriel"
				});
				this.activeComponent = "Credentials";
				return;
			}
			if (!this.$refs.password || !this.$refs.password.validate()) {
				return;
			}

			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				this.activeComponent = "Attributes";
			} else {
				this.register();
			}
		},
		register() {
			// if (!this.$refs.password || !this.$refs.password.validate()) {
			// 	return;
			// }
			let transformedAttributes = {};
			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				if (!this.$refs.attributes) {
					return;
				}
				const attributes = this.$refs.attributes.transformAndValidate();
				if (!attributes) {
					return;
				}
				transformedAttributes = attributes.attributes;
			}
			this.$emit("input", { userName: this.userName, password: this.$refs.password.password1, attributes: transformedAttributes });
			this.activeComponent = "Credentials";
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.self-register-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;

	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.self-register-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
