<template>
	<div class="code-and-newpassword-component">
		<div class="code-and-new-password-wrapper">
			<form id="codeAndNewPasswordForm">
				<div class="title">
					{{
						text.getByKey("title", {
							default: "Change Password",
							en: "Change Password",
							fr: "Changer le mot de passe"
						})
					}}
				</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("instructions", {
							default:
								"Please enter the validation code from your password reset confirmation email and then enter a new password.",
							en:
								"Please enter the validation code from your password reset confirmation email and then enter a new password.",
							fr:
								"Veuillez saisir le code de validation de votre e-mail de confirmation de réinitialisation de mot de passe, puis saisir un nouveau mot de passe."
						})
					}}
				</div>
				<br />
				<br />
				<div class="form-group">
					<div class="form-row">
						<input
							class="form-control"
							type="text"
							id="verificationCodePasswordChange"
							placeholder="Verification Code"
							v-model="verificationCode"
							pattern=".*"
						/>
					</div>
					<div class="form-error" v-show="verificationCodeError">{{ verificationCodeError }}</div>
				</div>

				<new-password v-on:changePassword="changePassword" :text="text.shiftContext('newPassword')">
					<template v-slot:password1Error="{ error }">
						<div class="form-error" v-show="error.length > 0">
							<ul>
								<li v-for="passwordError in error" :key="passwordError" style="text-align:left;">{{ passwordError }}</li>
							</ul>
						</div>
					</template>
					<template v-slot:password2Error="{ error }">
						<div class="form-error" v-show="error">{{ error }}</div>
					</template>
					<template v-slot:changed="{ changed }">
						<button type="button" v-on:click="changed" class="btn btn-outline-secondary" value="Change Password">
							{{
								text.getByKey("changePasswordButton", {
									default: "Change Password",
									en: "Change Password",
									fr: "Changer le mot de passe"
								})
							}}
						</button>
					</template>
				</new-password>
			</form>
		</div>
	</div>
</template>

<script>
import NewPassword from "./NewPassword";

// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			verificationCode: "",
			verificationCodeError: ""
		};
	},
	props: {
		initialVerificationCode: String,
		text: Object
	},
	inject: ["authService"],

	components: { NewPassword },
	watch: {
		verificationCode: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.verificationCodeError = "";
			}
		}
	},
	name: "CodeAndNewPassword",
	mounted() {
		this.verificationCode = this.initialVerificationCode;
	},
	methods: {
		changePassword: function(newCredentials) {
			this.$emit("changePassword", { password: newCredentials.password, verificationCode: this.verificationCode });
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.code-and-new-password-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.code-and-new-password-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
