import { log } from "@/logging";

const myLoggingName = "ProtectedResource";

export class ProtectedResource {
	protectedConfigApi = null;
	arbitraryDataApi = null;
	localData = null;
	axios = null;
	cachedArbitraryData = {};
	cachedResources = {
		lastHit: null,
		data: null
	};
	cacheLifeTime = 600000;

	constructor(axios, configService) {
		// Get the information that may or may not be remote, asynchronously
		let capturedThis = this;
		configService
			.getSiteConfig()
			.then(siteConfig => {
				if (siteConfig["protectedResource"] && siteConfig["protectedResource"]["cacheDuration"]) {
					capturedThis.cacheLifeTime = siteConfig["protectedResource"]["cacheDuration"];
				}
			})
			// .then(() => {
			// 	return configService.getAuth();
			// })
			// .then(authConfig => {
			// 	capturedThis.userPoolId = authConfig.serviceProviderConfig.configuration.Auth.userPoolId;
			// })
			.catch(error => {
				log(myLoggingName, "constructor - error getting config " + error);
			});

		// Get the synchronous details from the local configuration
		log(myLoggingName, "constructor - islocal " + configService.isLocal);
		if (configService.isLocal) {
			// if service is configured to run locally, get the local copy of resources
			this.localData = configService.getLocalResources();
		} else {
			// else, get the resource api details from the configuration
			let hostRoot = configService.hostName.split(".")[0];
			let apiInfo = configService.localConfig["serviceConfig"];
			let url = apiInfo["url"];
			let formattedUrl = configService.addHostToUrl(url);
			let resource = apiInfo["resource"];
			this.protectedConfigApi = formattedUrl + hostRoot + "/" + resource + apiInfo["resourceAction"];
			this.arbitraryDataApi = formattedUrl + hostRoot + "/" + resource + apiInfo["arbitraryDataAction"];

			this.axios = axios;
		}
	}

	isCacheExpired(cache) {
		if (!cache.data || !cache.lastHit) {
			return true;
		}
		if (Date.now() - cache.lastHit > this.cacheLifeTime) {
			return true;
		}
		return false;
	}

	async getArbitraryData(token, table) {
		const headers = {};
		if (token !== null) {
			headers.Authorization = token;
		}
		if (this.localData) {
			return new Promise(resolve => {
				resolve(this.localData.arbitraryData[table]);
			});
		}

		if (this.cachedArbitraryData[table] && !this.isCacheExpired(this.cachedArbitraryData[table])) {
			return new Promise(resolve => {
				resolve(this.cachedArbitraryData[table].data);
			});
		}
		try {
			let rc = await this.axios.post(
				this.arbitraryDataApi,
				//				JSON.stringify({ host: window.location.hostname, cognitoPoolId: this.userPoolId, table: table }),
				JSON.stringify({ host: window.location.hostname, table: table }),
				{
					headers: headers,
					contentType: "application/json"
				}
			);
			if (!this.cachedArbitraryData[table]) {
				this.cachedArbitraryData[table] = {};
			}
			this.cachedArbitraryData[table].data = rc.data;
			this.cachedArbitraryData[table].lastHit = Date.now();
			return rc.data;
		} catch (error) {
			//log(myLoggingName, "ProtectedResource - getArbitraryData error " + error);
			throw "Error retrieving data";
		}
	}

	async getProtectedConfigurationData(token) {
		const headers = {};
		if (token !== null) {
			headers.Authorization = token;
		}
		if (this.localData) {
			return new Promise(resolve => {
				resolve(this.localData.protectedResources);
			});
		}
		if (!this.isCacheExpired(this.cachedResources)) {
			return new Promise(resolve => {
				resolve(this.cachedResources.data);
			});
		}
		try {
			let rc = await this.axios.post(
				this.protectedConfigApi,
				//				JSON.stringify({ host: window.location.hostname, cognitoPoolId: this.userPoolId }),
				JSON.stringify({ host: window.location.hostname }),
				{
					headers: headers,
					contentType: "application/json"
				}
			);
			this.cachedResources.data = rc.data.resources;
			this.cachedResources.lastHit = Date.now();
			return rc.data.resources;
		} catch (error) {
			log(myLoggingName, "getProtectedConfigurationData error " + error);
			throw "Error retrieving data";
		}
	}
}
