<template>
	<div>
		<div v-if="mounted && playerDisplayOrder && playerDisplayOrder.length > 1">
			<span v-if="standardPlayers.length > 0">
				<b-dropdown
					v-b-tooltip.hover.right="
						text.getByKey('toolTip', {
							default: 'Choose alternate video source',
							en: 'Choose alternate video source',
							fr: 'Choisir une autre source vidéo'
						})
					"
					variant="outline"
				>
					<template #button-content>
						<!-- <b-icon icon="display"></b-icon> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							v-bind:fill="pickerIconColour"
							class="bi bi-display"
							viewBox="0 0 16 16"
						>
							<path
								d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"
							/>
						</svg>
					</template>
					<b-dropdown-item-button v-for="(value, index) in standardPlayers" v-bind:key="index" v-on:click="selectPlayer(value)">
						<span v-if="value.name === selectedPlayer.name">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-check"
								viewBox="0 0 16 16"
							>
								<path
									d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
								/>
							</svg>
						</span>
						<span v-else><b-icon type="blank" aria-hidden="true"></b-icon></span>
						{{ text.get(value.name) }}
					</b-dropdown-item-button>
				</b-dropdown>
			</span>
			<span v-if="alternateAudioPlayers.length > 0">
				<b-dropdown
					v-b-tooltip.hover.right="
						text.getByKey('alternateAudioToolTip', {
							default: 'Choose alternate audio video source',
							en: 'Choose alternate audio video source',
							fr: 'Choisir une autre source audio vidéo'
						})
					"
					variant="outline"
				>
					<template #button-content>
						<!-- <b-icon icon="display"></b-icon> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							v-bind:fill="pickerIconColour"
							class="bi bi-headphones"
							viewBox="0 0 16 16"
						>
							<path
								d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z"
							/>
						</svg>
					</template>
					<b-dropdown-item-button
						v-for="(value, index) in alternateAudioPlayers"
						v-bind:key="index"
						v-on:click="selectPlayer(value)"
					>
						<span v-if="value.name === selectedPlayer.name">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-check"
								viewBox="0 0 16 16"
							>
								<path
									d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
								/>
							</svg>
						</span>
						<span v-else><b-icon type="blank" aria-hidden="true"></b-icon></span>
						{{ text.get(value.name) }}
					</b-dropdown-item-button>
				</b-dropdown>
			</span>
		</div>
	</div>
</template>

<script>
import { BIcon } from "bootstrap-vue";
import { formatString } from "@/stringutilities";
import { log } from "@/logging";

const myLoggingName = "PlayerPicker";

export default {
	name: "PlayerPicker",
	props: {
		config: Object,
		common: Object,
		resourceKeyRoot: String,
		text: Object
	},
	inject: ["authService", "protectedResource", "configService"],
	data: () => ({
		mounted: false,
		selectedPlayer: null,
		playerElements: [],
		playerDisplayOrder: [],
		pickerIconColour: "currentColor"
	}),
	components: { BIcon },
	computed: {
		standardPlayers() {
			return this.playerDisplayOrder.filter(item => !item.type || item.type === "standard");
		},
		alternateAudioPlayers() {
			return this.playerDisplayOrder.filter(item => item.type && item.type === "alternateAudio");
		}
	},
	methods: {
		selectPlayer(selectedKey) {
			log(myLoggingName, "player selected", selectedKey);
			this.selectedPlayer = selectedKey;
			this.$emit(
				"selectPlayer",
				this.playerElements.find(e => e.key === this.selectedPlayer.playerKey)
			);
		},
		determineFillColor() {
			if (!this.config?.playerConfig?.styleVariables) {
				const globalStyle = this.common.globalStyleVariables;
				if (globalStyle && globalStyle["primary-text-colour"]) {
					this.pickerIconColour = globalStyle["primary-text-colour"];
				}
			} else {
				if (this.config.playerConfig.styleVariables.pickerIconColour) {
					this.pickerIconColour = this.config.playerConfig.styleVariables.pickerIconColour;
				}
			}
		}
	},
	mounted() {
		this.determineFillColor();
		let capturedVue = this;
		const protectedResourceKey = this.resourceKeyRoot + capturedVue.config.name;
		this.authService
			.getToken()
			.then(async token => {
				return await this.protectedResource.getProtectedConfigurationData(token);
			})
			.then(data => {
				return data;
			})
			.then(data => {
				// Copy and transform player configurations to player elements. Only copy configurations for players in the playerDisplayOrder list
				let activePlayers = [];
				for (const orderEntry of capturedVue.config.playerConfig.playerDisplayOrder) {
					const player = capturedVue.config.playerConfig.players[orderEntry.playerKey];
					if (player) {
						let playerConfig = {};
						let srcFormat = "";
						let playerConfigId = "";
						let src = "";

						let playerElement = null;
						switch (player.type) {
							case "simple-iframe":
								// copy player config and modify the src element
								Object.assign(playerConfig, player);
								playerConfig.playerParameters = {};
								Object.assign(playerConfig.playerParameters, player.playerParameters);
								srcFormat = player.playerParameters.src;
								playerConfigId = data[protectedResourceKey]["PlayerClipId" + orderEntry.playerKey];
								src = formatString(srcFormat, {
									playerClipId: playerConfigId
								});
								playerConfig.playerParameters.src = src;
								playerConfig.key = orderEntry.playerKey;
								playerConfig.aspectRatioAdjustment = player.aspectRatioAdjustment;
								capturedVue.playerElement = playerConfig;
								playerElement = playerConfig;
								break;
							case "video-js-player":
								// copy player config and modify the src element
								Object.assign(playerConfig, player);
								playerConfig.playerParameters = {};
								Object.assign(playerConfig.playerParameters, player.playerParameters);
								playerConfig.playerParameters.playerOptions.sources.forEach(source => {
									srcFormat = source.src;
									playerConfigId = data[protectedResourceKey]["PlayerClipId" + orderEntry.playerKey];
									src = formatString(srcFormat, {
										playerClipId: playerConfigId
									});
									source.src = src;
								});
								playerConfig.key = orderEntry.playerKey;
								playerConfig.aspectRatioAdjustment = player.aspectRatioAdjustment;
								capturedVue.playerElement = playerConfig;
								playerElement = playerConfig;
								break;
							default:
								playerElement = {
									elementId: capturedVue.config.name + orderEntry.playerKey,
									type: player.type,
									key: orderEntry.playerKey,
									resource: {
										name: capturedVue.config.name,
										PlayerClipId: data[protectedResourceKey]["PlayerClipId" + orderEntry.playerKey]
									},
									playerConfig: {
										title: capturedVue.config.name,
										id: 1,
										resourceId: capturedVue.config.name,
										descriptions: [],
										playerSource: player.playerSource,
										playerParameters: player.playerParameters
									}
								};
								capturedVue.playerElement = playerElement;
						}
						log(myLoggingName, "mounted - added", playerElement, orderEntry);
						capturedVue.playerElements.push(playerElement);
						activePlayers.push(orderEntry);
					} else {
						log(myLoggingName, "mounted - did not add", orderEntry);
					}
				}
				capturedVue.playerDisplayOrder = activePlayers;
				capturedVue.mounted = true;
				//capturedVue.selectedPlayer = capturedVue.config.playerConfig.playerDisplayOrder[0];
				if (activePlayers.length > 0) {
					capturedVue.selectPlayer(activePlayers[0]);
					log(myLoggingName, "mounted - assigned default selected player", capturedVue.selectedPlayer);
				} else {
					log(myLoggingName, "mounted - no active players for ", capturedVue.config.playerConfig.playerDisplayOrder);
				}
				if (activePlayers.length < capturedVue.config.playerConfig.playerDisplayOrder) {
					log(myLoggingName, "Mounted - activePlayers does not match ordered players ", {
						active: activePlayers,
						ordered: capturedVue.config.playerConfig.playerDisplayOrder
					});
				}
			});
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";
// https://stackoverflow.com/questions/51599545/how-can-i-change-variant-of-bootstrap-vue-button-style-checkboxes-when-clicked/51601312
// .my-buttons .active {
//     color: #fff !important;
//     background-color: #28a745 !important;
//     border-color: #28a745 !important;
//   }
</style>
