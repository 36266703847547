<template>
	<div>
		<div class="svp-player-component">
			<div v-bind:id="playerElement.elementId" style="position:relative;"></div>
		</div>
		<div v-bind:id="gistId"></div>
	</div>
</template>

<script>
/* eslint-disable no-useless-escape */
//import postscribe from "postscribe";

import { log } from "@/logging";

const myLoggingName = "SvpPlayer";

export default {
	props: {
		playerElement: Object,
		gistId: { type: String, required: true }
	},
	data: () => {
		return {
			videoApi: null
		};
	},
	name: "SvpPlayer",
	mounted() {
		if (this.playerElement) {
			this.turnPlayerOff(this.playerElement.elementId);
			this.turnPlayerOn(this.playerElement);
		}
	},
	beforeDestroy() {
		this.turnPlayerOff(this.playerElement.elementId);
		this.videoApi = null;
	},
	computed: {},
	watch: {
		playerElement: function(newVal, oldVal) {
			if (oldVal) {
				this.turnPlayerOff(oldVal.elementId);
			}
			if (newVal) {
				this.turnPlayerOn(newVal);
			}
		}
	},
	methods: {
		convertToParsedJSON: function(obj) {
			function stringify(obj) {
				if (typeof obj !== "object" || obj === null || obj instanceof Array) {
					return value(obj);
				}

				return (
					"{" +
					Object.keys(obj)
						.map(function(k) {
							return typeof obj[k] === "function" ? null : k + ":" + value(obj[k]);
						})
						.filter(function(i) {
							return i;
						}) +
					"}"
				);
			}

			function value(val) {
				switch (typeof val) {
					case "string":
						return '"' + val.replace(/\\/g, "\\\\").replace('"', '\\"') + '"';
					case "number":
					case "boolean":
						return "" + val;
					case "function":
						return "null";
					case "object":
						if (val instanceof Date) return '"' + val.toISOString() + '"';
						if (val instanceof Array) return "[" + val.map(value).join(",") + "]";
						if (val === null) return "null";
						return stringify(val);
				}
				return stringify(obj);
			}
			return stringify(obj);
		},
		turnPlayerOn(element) {
			//let playerElement = this.playerElements.find(i => i.elementId === id);
			this.addPlayerForItem(element);
		},
		turnPlayerOff(id) {
			const myNode = document.getElementById(id);
			if (myNode) {
				myNode.removeAttribute("data-initialized");
				while (myNode.firstChild) {
					myNode.removeChild(myNode.firstChild);
				}
			}
			this.clearPlayers();
		},
		addPlayerForItem(item) {
			let id = item.resource.PlayerClipId;
			let configuration = JSON.parse(JSON.stringify(item.playerConfig.playerParameters));
			configuration.clip_id = id;
			// const playerScript = `<script language="javascript" type="text/javascript" src="${item.playerConfig.playerSource}"><\/script>
			// 	<script language="javascript">
			// 		var vars = ${this.convertToParsedJSON(configuration)};
			//         var svp_player = new SVPDynamicPlayer("${item.elementId}", "", "100%", "100%",
			//         {use_div: "${item.elementId}",
			//          skin: "3"}, vars);
			// 		svp_player.execute();
			// 	<\/script>
			// 	`;
			// postscribe(`#${this.gistId}`, playerScript, {
			// 	done: function() {}
			// });
			const capturedVue = this;
			this.loadScript(item.playerConfig.playerSource, () => {
				const api = window.SVPDynamicPlayer;
				if (!api) throw new Error("SVP Player not loaded");
				log(myLoggingName, "addPlayerForImtem - in loadScript call back success");
				//const vars = this.convertToParsedJSON(configuration);
				//				const videoApi = new api(item.elementId, "","","100%","100%", `{ use_div: ${item.elementId}, skin: "3" }`, this.convertToParsedJSON(configuration));
				const options = { use_div: item.elementId, skin: "3" };
				const videoApi = new api(item.elementId, "", "100%", "100%", options, configuration);
				videoApi.execute();
				capturedVue.videoApi = videoApi;
			});
		},
		clearPlayers() {
			const myNode = document.getElementById(`${this.gistId}`);
			if (myNode) {
				while (myNode.firstChild) {
					myNode.removeChild(myNode.firstChild);
				}
			}
		},
		loadScript(src, callback) {
			const scriptEl = document.createElement("script");
			scriptEl.src = src;
			scriptEl.async = true;
			const container = document.getElementById(this.gistId);
			//document.querySelector("head").appendChild(scriptEl);
			container.appendChild(scriptEl);
			scriptEl.addEventListener("load", callback);
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";
</style>
