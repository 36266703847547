<template>
	<div id="app" :style="declareGlobalStyleVariables()">
		<div v-if="!pendingTaskDone">
			<pending-task
				v-bind:config="pendingTaskConfig"
				v-bind:isTaskComplete="isSiteReady"
				v-on:splashComplete="splashComplete"
			></pending-task>
		</div>
		<span v-else>
			<div class="nav-content" v-if="isNavBarActive()">
				<div id="navBar" :key="$route.name">
					<navBar :siteConfig="siteConfig" :analyticsContext="analyticsContext" />
				</div>
			</div>
			<!-- TODO still need language picker, even if navbar is not active -->

			<transition name="fade" mode="out-in">
				<router-view v-bind:key="$route.name" />
			</transition>
		</span>
		<div id="concierge-zendesk"></div>
	</div>
</template>

<script>
import { log } from "@/logging";
import PendingTask from "./components/PendingTask";
import navBar from "./components/navbar/NavBar.vue";

const myLoggingName = "App";

export default {
	name: "App",
	props: {
		analyticsContext: Object
	},
	data: () => ({
		authenticated: false,
		siteConfig: null,
		pendingTaskDone: false
	}),

	inject: ["authService", "configService", "zendeskManager", "textService"],
	components: { PendingTask, navBar },

	computed: {
		text() {
			return this.textService.copy({});
		},
		hostName() {
			return window.location.hostname;
		},
		isSiteReady() {
			return this.siteConfig && true;
		},
		pendingTaskConfig() {
			// If routes are not set up, the route path is set but the route name is not, so to check if somebody is
			// reloading and going directly to a page, check that path is not "/"
			// If so, use a spinner with auto transit (instead of making them, for e.g. revisit splash when reloading lounge)
			if (this.$route.path !== "/") {
				return {
					type: "spinner",
					autoTransit: true
				};
			}
			return this.configService.getLoadingConfig();
		}
	},
	methods: {
		isAuthenticated() {
			// const isGuarded = this.siteConfig.pages.find(it => this.$route.path.startsWith(it.routePath)).isGuarded;
			// return this.authService.authed() || !isGuarded;
			return this.authService.authed();
		},
		isFilterActive(filter) {
			switch (filter) {
				case "always":
					return true;
				case "never":
					return false;
				case "authenticated":
					return this.isAuthenticated();
				case "unauthenticated":
					return !this.isAuthenticated();
				case "anonymousMode":
					return this.isAnonymousMode();
				case "authMode":
					return !this.isAnonymousMode();
				default:
					log(myLoggingName, "isNavBarActive", "unsupportedfilter", filter);
					return false;
			}
		},
		isNavBarActive() {
			const activeMode = this.configService.determineActiveSiteMode(this.siteConfig);
			const filter = this.configService.getActiveSiteMode(this.siteConfig, activeMode.mode).navBar?.globalActiveFilter;
			if (filter) {
				return this.isFilterActive(filter);
			} else {
				return this.isFilterActive("authenticated");
			}
		},
		// Event handler for pending task component indicating that user has clicked the "Proceed to site" button
		splashComplete() {
			this.pendingTaskDone = true;
		},
		isAnonymousMode() {
			return this.authService.isAnonymousMode();
		},
		declareGlobalStyleVariables() {
			if (!this.siteConfig) return {};
			const styles = {};
			Object.keys(this.siteConfig.common.globalStyleVariables).forEach(key => {
				styles["--" + key] = this.siteConfig.common.globalStyleVariables[key];
			});
			return styles;
		}
		// addChildrenToAnalytics() {
		// 	const capturedVue = this;
		// 	this.siteConfig.common.navBar.navModalLinks.forEach((item, index) => {
		// 		let name = item.text.toString();
		// 		let id = "nav-modal" + index.toString();

		// 		capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
		// 	});
		// 	this.siteConfig.common.navBar.externalLinks.forEach((item, index) => {
		// 		let name = item.text.toString();
		// 		let id = "externallink" + index.toString();

		// 		capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
		// 	});
		// },
		// trackClick(index) {
		// 	//			log(myLoggingName,index);
		// 	let id = "externallink" + index.toString();
		// 	const roomAnalyitcsContext = this.analyticsContexts[id];
		// 	const values = new Map();
		// 	const event = roomAnalyitcsContext.makeEvent("clicked", "event", values);
		// 	roomAnalyitcsContext.recordEvent(event);
		// }
	},
	created() {
		this.created = true;
	},
	mounted() {
		this.mountedCount++;
		let capturedVue = this;
		this.configService.getSiteConfig().then(config => {
			// setTimeout(() => {
			// 	console.log("Start delay");
			// 	capturedVue.siteConfig = config;
			// 	console.log("End delay");
			// }, 5000);
			capturedVue.siteConfig = config;
			//capturedVue.addChildrenToAnalytics();
			capturedVue.authenticated = this.isAuthenticated();
		});
	},
	beforeUpdate() {
		this.authenticated = this.isAuthenticated();
	}
};
</script>

<style lang="scss">
@import "~normalize.css/normalize.css";
@import "scss/games-modal";
@import "scss/nav-modal";
@import "scss/common";

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 250ms linear;
}

.fade-enter,
.fade-leave-to {
	opacity: 0.5;
}

.logout-link {
	cursor: pointer;

	&:hover {
		text-decoration: underline !important;
	}
}

// #nav-modal-link {
// 	cursor: pointer;
// 	float: left;
// 	padding-right: 3px;

// 	&:hover {
// 		text-decoration: underline !important;
// 	}
// }

nav-modal-link {
	cursor: pointer;
	float: left;
	padding-right: 3px;

	&:hover {
		text-decoration: underline !important;
	}
}

#conciergeLink {
	cursor: pointer;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;

	color: var(--primary-text-colour);

	height: 100%;
}

body,
html {
	margin: 0;
	padding: 0;
}

body {
	object-position: 50% 50%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100vh;
}

// #nav {
// 	// width: 100%;
// 	// margin: 0 auto;

// 	// z-index: 10;
// 	// text-align: right;
// 	// font-weight: bold;
// 	// position: relative;
// 	color: var(--nav-text-colour);

// 	// .nav-content {
// 	// 	position: absolute;
// 	// 	padding: 19px;
// 	// 	right: 0;
// 	// 	//background-color: #ffffffbd;
// 	// 	background-image: linear-gradient(to right, #000a98, #00b8ff);
// 	// }

// 	a {
// 		&.router-link-exact-active {
// 			color: var(--active-route-link-colour);
// 		}
// 	}
// }

img {
	max-width: 100%;
}

@media (max-width: $screen-mobile-max) {
	.overlay {
		display: none;
	}
}

.home-bg {
	position: relative;
	transform-origin: top;
}

.bg-image {
	max-width: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.home-page {
	display: grid;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	box-sizing: content-box;

	background: linear-gradient(to top right, var(--primary-colour), var(--primary-gradient-colour));
}
</style>
