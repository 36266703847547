<template>
	<!-- <div style="height: 100%; width: 100%;">  -->
	<div>
		<div v-bind:id="contentId" class="content" style="position:relative;" />
		<div v-bind:id="scriptId" />
		<!-- <div id="jitsi-chat" class="content" />
		<div id="jitsi-script" /> -->
	</div>
</template>
<script>
/* eslint-disable no-useless-escape */
//import postscribe from "postscribe";

import { log } from "@/logging";

const myLoggingName = "JitsiVideoChat";

// https://blog.logrocket.com/how-to-write-a-vue-js-app-completely-in-typescript/

export default {
	name: "JitsiVideoChat",
	props: {
		contentId: { type: String, default: "jitsi-chat" },
		scriptId: { type: String, default: "jitsi-script" },
		jwt: { type: String },
		roomName: { type: String },
		config: Object
	},
	data: () => {
		return {
			jitsiApi: null
		};
	},
	mounted() {
		// alternate https://github.com/mycurelabs/vue-jitsi-meet/blob/master/src/JitsiMeet.vue
		// which I think is a variation on // https://vue-view.com/how-to-load-an-external-script-in-vue-component/

		const videoChatHostDomain = this.config?.hostDomain ? this.config?.hostDomain : "8x8.vc";
		const scriptSrc = this.config?.scriptSrc ? this.config?.scriptSrc : "https://8x8.vc/external_api.js";
		// const jwt =
		// 	"eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtZGU0MmVjOTAyNjYzNDYzNmIwYmMwZjBkMzBmZWY2NGUvZDhlMmYwLVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImV4cCI6MTYyMDc3NzQzMywibmJmIjoxNjIwNzcwMjI4LCJpc3MiOiJjaGF0Iiwicm9vbSI6IioiLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtZGU0MmVjOTAyNjYzNDYzNmIwYmMwZjBkMzBmZWY2NGUiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOmZhbHNlLCJvdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6ZmFsc2UsInJlY29yZGluZyI6ZmFsc2V9LCJ1c2VyIjp7Im1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6IlRlc3QgVXNlciIsImlkIjoiYXV0aDB8NjA3NzIwY2M3ZDI5YjIwMDZjMTk0NjFhIiwiYXZhdGFyIjoiIiwiZW1haWwiOiJ0ZXN0LnVzZXJAY29tcGFueS5jb20ifX19.PenpSce5WT4bt_Wi5BWTzjqFkp3Zo_vxu-kkV1EDzAr45Cc0cCJLdCWUmY5yYuzYj0ShEExiF-aqevOk54jMjLXlMMTOJh4ZdAhtgNj9wT7KfYtYE1PDW3qYA_OgaQUl6CRULpvIpUWA5JVQTQtzjkKaKxMvmml_Qin1Kx7BlJQPOMGsQoSxXjLXyojwqKeYUr6__538ozC7QSSQQHoTojbmPiZhwYZAoySNkiLd5cs5BCHKNBRT26Itm3dYqklndXfb-F9lJjxXgBXapEp0mXPDUOsQc55KDhs98sp6EAx5y9dIQvhhzDtX4PT0fo37qVC7lUbvGUq-zZBRqES9Bg";
		// const roomName = "vpaas-magic-cookie-de42ec9026634636b0bc0f0d30fef64e/SampleAppDestructiveLeadsCondemnConsiderably";
		const parentNode = document.getElementById(this.contentId);
		const options = {
			roomName: this.roomName,
			parentNode,
			jwt: this.jwt,
			configOverwrite: { TOOLBAR_BUTTONS: ["microphone", "camera", "fullscreen", "fodeviceselection", "hangup"] },
			interfaceConfigOverwrite: { TOOLBAR_BUTTONS: ["microphone", "camera", "fullscreen", "fodeviceselection", "hangup"] }
		};

		// old postscribe version - this code regularly resulted in the above elements NOT having the script nor content when the vue was entered via a route, but
		// did render OK on refresh and, at least for the times I recalled, when accessing the vue directy.
		// const jitsiSetupScript = `<script language="javascript" type="text/javascript" src="${scriptSrc}" async><\/script>
		// 		<script language="javascript">
		//         const api = new JitsiMeetExternalAPI("8x8.vc", {
		//             roomName: "vpaas-magic-cookie-de42ec9026634636b0bc0f0d30fef64e/SampleAppDisastrousCarpetsMateHere",
		//             parentNode: document.querySelector('#${this.contentId}'),
		//             jwt: '${jwt}'
		//             });
		// 		<\/script>
		// 		`;
		// debugger;
		// postscribe(`#${this.scriptId}`, jitsiSetupScript, {
		// 	done: function() {
		// 	}
		// });
		const capturedVue = this;
		this.loadScript(scriptSrc, () => {
			const api = window.JitsiMeetExternalAPI;
			if (!api) throw new Error("Jitsi Meet API not loaded");
			log(myLoggingName, "mounted - loadScript In videochat call back success");
			capturedVue.jitsiApi = new api(videoChatHostDomain, options);
			capturedVue.jitsiApi.addEventListener("readyToClose", () => {
				log(myLoggingName, "mounted - loadScript readyToClose called. Exiting");
				capturedVue.exit();
			});
			capturedVue.jitsiApi.addEventListener("videoConferenceLeft", eventData => {
				log(myLoggingName, "mounted - loadScript videoConferenceLeft called ", eventData);
			});
			// if (!window.JitsiMeetExternalAPI) throw new Error("Jitsi Meet API not loaded");
			// log(myLoggingName, "In videochat call back success");
			// capturedVue.jitsiApi = new window.JitsiMeetExternalAPI(videoChatHostDomain, options);
		});
		log(myLoggingName, "mounted exit");
	},
	beforeDestroy() {
		this.removeJitsiWidget();
		this.jitsiApi = null;
	},
	methods: {
		loadScript(src, callback) {
			const scriptEl = document.createElement("script");
			scriptEl.src = src;
			scriptEl.async = true;
			const container = document.getElementById(this.scriptId);
			//document.querySelector("head").appendChild(scriptEl);
			container.appendChild(scriptEl);
			scriptEl.addEventListener("load", callback);
		},

		// executeCommand (command, ...value) {
		//   this.jitsiApi.executeCommand(command, ...value);
		// },
		// addEventListener (event, fn) {
		//   this.jitsiApi.on(event, fn);
		// },
		// Misc
		removeJitsiWidget() {
			if (this.jitsiApi) {
				this.jitsiApi.dispose();
			}
		},
		exit() {
			log(myLoggingName, "exit");
			this.$emit("exit", {});
		}
	}
};
</script>
<style scoped lang="scss">
@import "../../scss/constants";

.content {
	height: 600px;
}
</style>
