<template>
	<div id="signinHelp" class="instructions">
		{{
			text.getByKey("instructions", {
				default: "Need help? Please contact",
				en: "Need help? Please contact",
				fr: "Vous avez besoin d'aide ? Veuillez contacter"
			})
		}}
		<b-link v-bind:href="supportEmail">{{ supportHost }}</b-link>
	</div>
</template>
<script>
export default {
	name: "NeedHelp",
	props: {
		common: Object,
		text: Object
	},
	computed: {
		supportEmail() {
			return "mailto:" + (this.common.supportEmail ?? "support@kingevents.ca");
		},
		supportHost() {
			return this.common.supportEmail ?? "support@kingevents.ca";
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.instructions {
	font-size: small;
}
</style>
