<template>
	<span id="nav">
		<b-navbar toggleable="lg" type="dark" variant="info" class="topnav">
			<b-navbar-brand
				v-if="this.siteConfig.common.navBar.brand"
				class="navbar-brand"
				target="_blank"
				:href="text.get(navBarBrandUrl)"
				>{{ text.get(this.siteConfig.common.navBar.brand.text) }}</b-navbar-brand
			>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav class="ml-auto">
					<span v-for="(item, index) in getActiveItems()" v-bind:key="index">
						<b-nav-item v-if="item.type === 'homeLink'" v-bind:to="getRoute(item)" class="itemadjust">{{
							text.get(item.text)
						}}</b-nav-item>
						<b-nav-item v-else-if="item.type === 'languagePicker'">
							<language-picker :text="languagePicker" />
						</b-nav-item>

						<b-nav-item-dropdown v-else-if="item.type === 'externalLinksDD'" :text="text.get(item.name)" class="dropdownadjust">
							<div aria-labelledby="navbarDropdown">
								<span v-for="(externalLink, innerIndex) in item.items" v-bind:key="innerIndex">
									<b-dropdown-item
										target="_blank"
										v-bind:href="text.get(externalLink.url)"
										v-on:click="trackExternalLinkGroupItemClick(index, innerIndex)"
										>{{ text.get(externalLink.text) }}</b-dropdown-item
									>
								</span>
							</div>
						</b-nav-item-dropdown>
						<b-nav-item
							v-else-if="item.type === 'externalLinks'"
							target="_blank"
							v-on:click="trackExternalLinkClick(index)"
							v-bind:href="text.get(item.url)"
							class="itemadjust"
							>{{ text.get(item.text) }}</b-nav-item
						>
						<!-- <b-nav-item-dropdown v-else-if="item.type === 'internalLinksDD'" id="wee" :text="text.get(item.name)" class="dropdownadjust"> -->
						<b-nav-item-dropdown v-else-if="item.type === 'internalLinksDD'" :text="text.get(item.name)" class="dropdownadjust">
							<div aria-labelledby="navbarDropdown">
								<span v-for="(internalLink, innerIndex) in item.items" v-bind:key="innerIndex">
									<b-dropdown-item v-bind:to="getRoute(internalLink)">{{ text.get(internalLink.text) }}</b-dropdown-item>
								</span>
							</div>
						</b-nav-item-dropdown>
						<b-nav-item v-else-if="item.type === 'internalLinks'" v-bind:to="getRoute(item)" class="itemadjust">{{
							text.get(item.text)
						}}</b-nav-item>

						<b-nav-item v-else-if="item.type === 'navModalLinks'" class="modaladjust">
							<span v-b-modal="'nav-modal' + index">
								<span class="nav-modal-link">{{ text.get(item.text) }}</span>
							</span>
						</b-nav-item>

						<b-nav-item v-else-if="item.type === 'navBarConcierge'" class="itemadjust">
							<span id="conciergeLink" v-on:click="turnConciergeOn">{{ text.get(item.text) }}</span>
						</b-nav-item>
						<b-nav-item v-else-if="item.type === 'authenticationLinks'" v-bind:to="getRoute(item)" class="itemadjust"
							>{{ text.get(item.text) }}
						</b-nav-item>
					</span>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<b-modal
			v-for="(navModal, index) in getActiveModalItems()"
			v-bind:key="index"
			v-bind:id="'nav-modal' + index"
			centered
			v-bind:size="modalSize(navModal.item)"
			v-on:show="onModalShow(navModal.analyticsId)"
			v-on:hide="onModalHide(navModal.analyticsId)"
			hide-footer
		>
			<div>
				<iframe
					v-if="navModal.item.iFrameSrc"
					id="iframe"
					width="100%"
					height="600px"
					v-bind:src="text.get(navModal.item.iFrameSrc)"
				>
				</iframe>
				<img v-else :src="text.get(navModal.item.url)" />
			</div>
		</b-modal>
	</span>
</template>

<script>
import LanguagePicker from "../../components/LanguagePicker.vue";
import { log } from "@/logging";

const myLoggingName = "navBar";

export default {
	name: "navbar",
	props: {
		siteConfig: Object,
		analyticsContext: Object
	},
	data: () => ({
		analyticsContexts: {}
	}),
	inject: ["authService", "configService", "zendeskManager", "textService"],
	components: { LanguagePicker },
	computed: {
		text() {
			return this.textService.copy({});
		},
		navBarBrandUrl() {
			return this.siteConfig.common.navBar.brand?.url ?? "#";
		},
		conciergeLinkText() {
			return this.siteConfig.common.navBar.navBarConcierge?.text ?? "help";
		},
		languagePicker() {
			return this.textService.copy(this.siteConfig.common.languagePicker?.text);
		}
	},
	methods: {
		turnConciergeOn() {
			this.zendeskManager.open();
		},
		isAuthenticated() {
			// const isGuarded = this.siteConfig.pages.find(it => this.$route.path.startsWith(it.routePath)).isGuarded;
			// return this.authService.authed() || !isGuarded;
			return this.authService.authed();
		},
		getActiveModalItems() {
			const rc = this.getActiveItems()
				.map((item, index) => {
					return { analyticsId: index, item: item };
				})
				.filter(item => {
					return item.item.type === "navModalLinks";
				});
			return rc;
		},
		getActiveItems() {
			let rc = [];
			for (const item of this.siteConfig.common.navBar.links) {
				switch (item.type) {
					case "externalLinksDD":
					case "internalLinksDD":
						if (this.isNavBarGroupActive(item.type, item)) {
							rc.push(item);
						}
						break;
					case "navModalLinksDD": // not supported
						break;
					case "navBarConcierge":
						if (this.isNavBarItemActive(item.type, item)) {
							let modifiedItem = {
								type: item.type,
								//text: this.siteConfig.common.navBar.navBarConcierge?.text ?? "help"
								//JM ked-242 fix how navBarConcierge language is set. The old way does
								//not work anymore and the text was always "help"
								text: this.text.get(item?.text ?? "help")
							};
							rc.push(modifiedItem);
						}
						break;
					case "homeLink":
					case "authenticationLinks":
					case "internalLinks":
					case "externalLinks":
					case "navModalLinks":
						if (this.isNavBarItemActive(item.type, item)) {
							rc.push(item);
						}
						break;
					default:
				}
			}
			// add language picker
			rc.push({ type: "languagePicker" });
			return rc;
		},
		isNavBarActive() {
			alert("siteConfig=" + this.siteConfig.common.logo);
			const activeMode = this.configService.determineActiveSiteMode(this.siteConfig);
			alert("activemode=" + activeMode);
			const filter = this.configService.getActiveSiteMode(this.siteConfig, activeMode.mode).navBar?.globalActiveFilter;
			if (filter) {
				alert("in filter is true");
				return this.isFilterActive(filter);
			} else {
				alert("in filter is false");
				return this.isFilterActive("authenticated");
			}
		},
		isNavBarItemActive(navBarItemType, navBarItem) {
			// get the mode's navbar filter configurations
			const activeMode = this.configService.determineActiveSiteMode(this.siteConfig);
			const navBar = this.configService.getActiveSiteMode(this.siteConfig, activeMode.mode).navBar;
			let filters = new Array();
			if (navBar && navBar.filters && navBar.filters[navBarItemType]) {
				filters = navBar.filters[navBarItemType];
			}
			// find those that match the supplied navar item (note that the merge method checks the routename property of the passed in items but the configuration maps filters based on the key so the
			// item text is mapped to the routeName field - slightly kludgy )
			const matches = this.configService.merge(filters, [{ routeName: navBarItem.key }]);

			// also note that the merge can be set to explicit, in which case it returns a list of items that may or may not match the navbar item, so check whether the returned items have a match
			const nonNullMatches = matches.filter(it => it.items.some(filterItem => filterItem === navBarItem.key));

			// if there are any, check whether the first one's value "isActive", else test against "authenticated"
			if (nonNullMatches.length > 0 && nonNullMatches[0].value != null) {
				const rc = this.isFilterActive(nonNullMatches[0].value);
				return rc;
			} else {
				const rc = this.isFilterActive("authenticated");
				return rc;
			}
		},
		isNavBarGroupActive(navBarItemType) {
			const activeMode = this.configService.determineActiveSiteMode(this.siteConfig);
			const navBar = this.configService.getActiveSiteMode(this.siteConfig, activeMode.mode).navBar;
			let filters = new Array();
			if (navBar && navBar.filters && navBar.filters[navBarItemType]) {
				filters = navBar.filters[navBarItemType];
			}
			const matches = this.configService.merge(filters, [{ routeName: navBarItemType }]);
			const nonNullMatches = matches.filter(it => it.items.some(filterItem => filterItem === navBarItemType));
			if (nonNullMatches.length > 0 && nonNullMatches[0].value != null) {
				const rc = this.isFilterActive(nonNullMatches[0].value);
				//alert('inNonNullMatches-' + rc);
				return rc;
			} else {
				const rc = this.isFilterActive("authenticated");
				//alert('inElse-' + rc);
				return rc;
			}
		},
		isFilterActive(filter) {
			switch (filter) {
				case "always":
					return true;
				case "never":
					return false;
				case "authenticated":
					return this.isAuthenticated();
				case "unauthenticated":
					return !this.isAuthenticated();
				case "anonymousMode":
					return this.isAnonymousMode();
				case "authMode":
					return !this.isAnonymousMode();
				default:
					log(myLoggingName, "isNavBarActive", "unsupportedfilter", filter);
					return false;
			}
		},
		// This was added to support adding parameters to internal routes as part of an early version of support for adding a concierge/help link to the navbar. A different solution was found for that link, but this may be useful for other links.
		// Leaving in for now.
		getRoute(item) {
			if (item.routeName.params) {
				// Obsolete - confirm this is not used and remove in next version
				return { name: item.routeName.name, params: item.routeName.params };
			}
			return { name: this.text.get(item.routeName) };
		},
		modalSize(navModalLink) {
			// eslint-disable-next-line prettier/prettier
			if (navModalLink?.size) {
				return navModalLink.size;
			} else {
				return "lg";
			}
		},
		onModalShow(itemConfig) {
			let id = "nav-modal" + itemConfig.toString();

			log(myLoggingName, "mounted Modal is about to be opened", { itemId: id });
			const context = this.analyticsContexts[id];
			context.ingress(new Map());
		},
		onModalHide(itemConfig) {
			let id = "nav-modal" + itemConfig.toString();
			log(myLoggingName, "mounted Modal is about to be closed", { itemId: id });
			const context = this.analyticsContexts[id];
			context.egress("closed");
		},
		addChildrenToAnalytics() {
			const capturedVue = this;
			const activeItems = this.getActiveItems();
			const transformedItems = activeItems
				.map((item, index) => {
					// we only track nav-modals, concierge, and external links. Because:
					// - Internal links (including authentication and logout) have their own analytics.
					// - We don't care at this point that the user clicked on a group, we care which item in the group was clicked and the existing analytics deal with that
					// We have to flatten the inner list of the groups in order to get the individual items
					const analyticsItems = [];
					switch (item.type) {
						case "externalLinksDD":
						case "navModalLinksDD":
							item.items.forEach((internalItem, subIndex) => {
								const modifiedItem = {};
								Object.assign(modifiedItem, internalItem);
								modifiedItem.type = item.type;
								analyticsItems.push({ index: index.toString() + "+" + subIndex.toString(), analyticsItem: modifiedItem });
							});
							break;
						case "internalLinksDD":
						case "navBarConcierge":
						case "homeLink":
						case "authenticationLinks":
						case "internalLinks":
							break;
						case "externalLinks":
						case "navModalLinks":
							analyticsItems.push({ index: index.toString(), analyticsItem: item });

							break;
						default:
							log(myLoggingName, "addChildrenToAnalyticsStep1", "unsupportedType", item.type);
					}
					return analyticsItems;
				})
				.flat();
			log(myLoggingName, "addChildrenToAnalyticsStep1.5", "transformedItems", transformedItems);
			transformedItems.forEach(item => {
				switch (item.analyticsItem.type) {
					case "internalLinksDD":
					case "navBarConcierge":
					case "homeLink":
					case "authenticationLinks":
					case "internalLinks":
						break;
					case "externalLinksDD":
					case "externalLinks": {
						let name = item.analyticsItem.key;
						let id = "externallink" + item.index;
						capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
						break;
					}
					case "navModalLinksDD":
					case "navModalLinks": {
						let name = item.analyticsItem.key;
						let id = "nav-modal" + item.index;
						capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
						break;
					}
					default:
						log(myLoggingName, "addChildrenToAnalyticsStep2", "unsupportedType", item.type);
				}
			});
			// this.siteConfig.common.navBar.navModalLinks.forEach((item, index) => {
			// 	let name = item.text.toString();
			// 	let id = "nav-modal" + index.toString();

			// 	capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
			// });
			// this.siteConfig.common.navBar.externalLinks.forEach((item, index) => {
			// 	let name = item.text.toString();
			// 	let id = "externallink" + index.toString();

			// 	capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
			// });
		},
		trackExternalLinkClick(index) {
			// only called for external links
			//			log(myLoggingName,index);
			let id = "externallink" + index;
			const roomAnalyticsContext = this.analyticsContexts[id];
			const values = new Map();
			const event = roomAnalyticsContext.makeEvent("clicked", "event", values);
			roomAnalyticsContext.recordEvent(event);
		},
		trackExternalLinkGroupItemClick(index, innerIndex) {
			this.trackExternalLinkClick(index.toString() + "+" + innerIndex.toString());
		}
	},
	mounted() {
		let capturedVue = this;
		this.configService.getSiteConfig().then(config => {
			capturedVue.siteConfig = config;
		});
		this.addChildrenToAnalytics();
	}
};
</script>

<style lang="scss">
#nav {
	a:hover {
		color: var(--nav-hover-color);
	}
	a {
		color: var(--primary-text-color);
		&.router-link-exact-active {
			color: var(--active-route-link-colour);
		}
	}
}
// example of how to override a specific item
// #wee {
// 	a:hover {
// 		color: blue;
// 	}
// 	a {
// 		color: yellow;
// 		&.router-link-exact-active {
// 			color: var(--active-route-link-colour);
// 		}
// 	}
// }
</style>
<style lang="scss" scoped>
//@import "../../scss/boostrap-config";
.itemadjust {
	padding-top: 6px;
}
.dropdownadjust {
	padding-top: 6px;
}
.modaladjust {
	padding-top: 6px;
}

.topnav {
	background-color: #333;
	//background-image: linear-gradient(to right, #000a98, #00b8ff);
	background-image: linear-gradient(to right, var(--nav-bg-imagecolor-start), var(--nav-bg-imagecolor-end));
	//ked-265 added this css styling to keep the navbar on top of everything else
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
</style>
