<template>
	<div id="musicPlayer">
		<input type="image" class="sound-button" v-bind:src="btnImage" v-on:click="btnClick()" />
		<audio v-bind:id="'myAudio' + config.id" type="audio/mpeg" loop>
			{{
				text.get({
					default: "Your browser does not support the audio element.",
					en: "Your browser does not support the audio element.",
					fr: "Votre navigateur ne supporte pas l'élément audio."
				})
			}}
		</audio>
	</div>
</template>

<script>
import { log } from "@/logging";
const myLoggingName = "musicPlayer";

export default {
	name: "MusicPlayer",
	props: {
		config: Object,
		common: Object
	},
	inject: ["textService"],
	data: () => ({
		myTrack: null,
		imgMuteOn: null,
		imgMuteOff: null,
		playing: false,
		btnImage: null,
		myAudio: null,
		windowWidth: window.innerWidth
	}),
	computed: {
		text() {
			return this.textService.copy(this.config.text);
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.myTrack = this.config.mp3;
		this.imgMuteOn = this.config.imgMuteOn;
		this.imgMuteOff = this.config.imgMuteOff;
		this.myAudio = document.getElementById("myAudio" + this.config.id);
		this.myAudio.volume = "0.1";
		this.myAudio.autoplay = false;
		this.myAudio.src = this.myTrack;
		this.playing = false;
		this.btnImage = this.imgMuteOff;
		this.myAudio.addEventListener("play", this.onPlay);
		this.toggleAudio();
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
		this.myAudio.removeEventListener("play", this.onPlay);
	},
	methods: {
		// event handlers
		onPlay() {
			this.playing = true;
			this.btnImage = this.imgMuteOn;
		},
		onPause() {
			this.playing = false;
			this.btnImage = this.imgMuteOff;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth < this.common.media.screenTabletMin) {
				this.pause();
			}
		},
		// commands
		play() {
			this.myAudio.loop = true;
			try {
				let capturedVue = this;
				this.myAudio
					.play()
					.then(() => {
						capturedVue.onPlay();
					})
					.catch(err => {
						log(myLoggingName, "play", "errorInPromise", err);
					});
			} catch (err) {
				log(myLoggingName, "play", "error", err);
			}
		},
		pause() {
			try {
				this.myAudio.pause();
				this.onPause();
			} catch (err) {
				log(myLoggingName, "pause", "error", err);
			}
		},
		btnClick() {
			this.toggleAudio();
		},
		toggleAudio() {
			if (this.windowWidth >= this.common.media.screenTabletMin) {
				this.myAudio.autoplay = true;
				this.playing ? this.pause() : this.play();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../scss/constants";
.sound-button {
	border: none;
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	cursor: pointer;
}
@media (max-width: $screen-mobile-max) {
	.sound-button {
		display: none;
	}
}
</style>
