import lifecycle from "page-lifecycle";

// Taken from https://daily-dev-tips.com/posts/vanilla-javascript-browser-detection/

/* eslint-disable no-undef */
// Opera 8.0+
export const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
	/constructor/i.test(window.HTMLElement) ||
	(function(p) {
		return p.toString() === "[object SafariRemoteNotification]";
	})(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
export const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;

//const lifecycle = new Lifecycle();

// A wrapper around page-lifecycle so that we can use it in typescript
// https://developers.google.com/web/updates/2018/07/page-lifecycle-api#observing-page-lifecycle-states-in-code

export class LifeCycleUtilities {
	addListener(eventHandler) {
		//Lifecycle.addEventListener("statechange", eventHandler);
		lifecycle.addEventListener("statechange", eventHandler);
	}
}
