<template>
	<div class="new-password-component">
		<div class="new-password-wrapper">
			<form id="newPasswordForm">
				<div class="instructions">
					<strong>
						{{
							text.getByKey("rulesDescription", {
								default: "Passwords must be at least 8 characters long",
								en: "Passwords must be at least 8 characters long",
								fr: "Les mots de passe doivent comporter au moins 8 caractères"
							})
						}}
					</strong>
				</div>
				<br />
				<div class="form-group">
					<password
						id="password1"
						v-model="password1"
						autoComplete="new-password"
						:text="text.shiftContext('password')"
					></password>
					<slot name="password1Error" v-bind:error="password1Error"> </slot>
				</div>

				<div class="form-group">
					<password
						id="password2"
						v-model="password2"
						autoComplete="new-password"
						:text="text.shiftContext('password')"
					></password>
					<slot name="password2Error" v-bind:error="password2Error"> </slot>
				</div>

				<slot name="changed" v-bind:changed="changePassword"> </slot>
			</form>
		</div>
	</div>
</template>

<script>
import Password from "./Password";
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			password1: "",
			password2: "",
			password1Error: [],
			password2Error: ""
		};
	},
	props: {
		text: Object
	},
	components: { Password },
	watch: {
		password1: function(newValue, oldValue) {
			// Clear out errors after submit so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.password1Error = [];
				this.password2Error = "";
			}
		},
		password2: function(newValue, oldValue) {
			// Clear out errors after submit so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.password1Error = [];
				this.password2Error = "";
			}
		}
	},
	name: "NewPassword",
	inject: ["authService"],
	methods: {
		validate: function() {
			let errorMessages = this.authService.validatePasswords(this.password1, this.password2);
			//this.password1Error = errorMessages.password1Error;
			const capturedVue = this;
			this.password1Error = errorMessages.password1Error.map(kv => {
				const key = kv.key;
				return capturedVue.text.getByKey(
					key,

					{
						default: "Unexpected Error",
						en: "Unexpected Error",
						fr: "Erreur inattendue"
					},
					kv.value
				);
			});

			//this.password2Error = errorMessages.password2Error;
			this.password2Error = errorMessages.password2Error
				? this.text.getByKey(
						errorMessages.password2Error.key,

						{
							default: "Unexpected Error",
							en: "Unexpected Error",
							fr: "Erreur inattendue"
						},
						errorMessages.password2Error.value
				  )
				: "";

			let error = false;
			if (this.password1Error.length > 0 || this.password2Error) {
				error = true;
			}
			return !error;
		},
		// validator: function() {
		// 	const capturedVue = this;
		// 	return () => { capturedVue.validate(capturedVue)};
		// },
		// update: function(key, e) {
		// 	switch (key) {
		// 		case "password1":
		// 			this.password1 = e?.target?.value;
		// 			break;
		// 		case "password2":
		// 			this.password2 = e?.target?.value;
		// 			break;
		// 	}

		// 	this.$emit("input", { password1: this.password1, password2: this.password2, validator: this.validator });
		// },
		changePassword: function() {
			const password = this.password1;
			if (this.validate()) {
				this.$emit("changePassword", { password });
			} else {
				return;
			}
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.new-password-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;

	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.new-password-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
