<template>
	<div>
		<div class="iframe-modal-component">
			<b-button v-b-modal="'iframemodal' + id">
				{{ text }}
			</b-button>
			<b-modal
				v-bind:id="'iframemodal' + id"
				size="xl"
				v-bind:title="title"
				v-on:show="onModalShow"
				v-on:hide="onModalHide()"
				hide-footer
			>
				<!-- <div>{{'HI!!!' + id}}</div> -->
				<iframe id="iframe" v-bind:title="title" v-bind:width="width" v-bind:height="height" v-bind:src="src"> </iframe>
			</b-modal>
		</div>
	</div>
</template>

<script>
/* eslint-disable no-useless-escape */

export default {
	props: {
		text: { type: String },
		title: { type: String },
		width: {
			type: String,
			default: function() {
				return "100%";
			}
		},
		height: {
			type: String,
			default: function() {
				return "100%";
			}
		},
		src: { type: String, required: true },
		id: { type: String, required: true },
		analyticsContext: Object
	},
	data: () => {
		return {
			myAnalyticsContext: null
		};
	},
	name: "IframeModal",
	mounted() {
		this.myAnalyticsContext = this.analyticsContext?.addChild("iframeModal" + this.id);
	},
	methods: {
		onModalShow() {
			this.myAnalyticsContext?.ingress(new Map());
		},
		onModalHide() {
			this.myAnalyticsContext?.egress("closed");
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.modal-header {
	border-bottom: none;
}
</style>
