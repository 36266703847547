<template>
	<div class="general-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<div class="arbitrary-data-wrapper" v-bind:style="wrapperStyle()">
				<div class="title" v-bind:style="titleStyle()">{{ config.title }}</div>
				<br />
				<arbitrary-data
					v-bind:fields="config.fields"
					v-bind:detailConfiguration="config.detailConfiguration"
					v-bind:source="config.source"
					v-bind:configName="config.name"
					v-bind:sortBy="config.sortBy"
					v-bind:searchOn="config.searchOn"
					v-bind:styles="config.componentStyle"
					v-bind:keyField="config.keyField"
					v-bind:analyticsContext="analyticsContext"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
import ArbitraryData from "../components/ArbitraryData";
export default {
	name: "ArbitraryDataPage",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	components: { Background, ArbitraryData },
	methods: {
		parsePredefinedStyle(style) {
			let rc = {};
			if (style) {
				const predefined = {
					"--background-color": style.backgroundColour ?? "rgba(11, 153, 235, 0.877)",
					"--background-color-sm-size": style.backgroundColourSmallSize ?? "black"
				};
				rc = Object.assign(rc, predefined);
			}
			return rc;
		},
		titleStyle() {
			// Can add custom style field and put in py-2
			let style = this.parsePredefinedStyle(this.config.style?.title?.styleOverride?.predefined);
			return style;
		},
		wrapperStyle() {
			let rc = {};
			let style = this.config.style?.wrapper?.styleOverride?.predefined;
			const predefined = {
				"--top": style?.top ?? "100px",
				"--min-width": style?.minWidth ?? "600px",
				"--max-width": style?.maxWidth ?? "90%",
				"--height": style?.height ?? "400px"
			};
			rc = Object.assign(rc, predefined);
			return rc;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

// Ryan: My preference is to make this 100% width & height
.arbitrary-data-wrapper {
	// horizontal centering
	margin: 0px auto;
	position: relative;

	min-width: var(--min-width);
	max-width: var(--max-width);
	height: var(--height);
	top: var(--top);
	padding: 20px;

	.title {
		font-size: large;
		background-color: var(--background-color);
		color: white;
		display: block;
		margin: 0 auto;
		width: 95%;
	}
}

@media (max-width: $screen-mobile-max) {
	.arbitrary-data-wrapper {
		min-width: unset;
		max-width: 90%;

		min-width: auto;
		.title {
			background-color: var(--background-color-sm-size);
		}
	}
}
</style>
