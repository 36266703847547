<template>
	<div class="user-password-reset-component">
		<div class="user-password-reset-wrapper">
			<form id="userPasswordResetForm">
				<div class="title">{{ title }}</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("instructions1", {
							default:
								"Forgot the password you created? Enter your email address below to receive a reset code with instructions for creating your new passsword.",
							en:
								"Forgot the password you created? Enter your email address below to receive a reset code with instructions for creating your new passsword.",
							fr:
								"Vous avez oublié le mot de passe que vous avez créé? Entrez votre adresse e-mail ci-dessous pour recevoir un code de réinitialisation avec des instructions pour créer votre nouveau mot de passe."
						})
					}}
				</div>
				<br />
				<div class="instructions">
					<strong>
						{{
							text.getByKey("instructions2", {
								default:
									"Do not use this, if you have not yet changed your temporary password as your account is not verified and a forgot password email will not be sent. Please contact support, if you have lost your temporary password.",
								en:
									"Do not use this, if you have not yet changed your temporary password as your account is not verified and a forgot password email will not be sent. Please contact support, if you have lost your temporary password.",
								fr:
									"Ne l'utilisez pas, si vous n'avez pas encore changé votre mot de passe temporaire car votre compte n'est pas vérifié et un oublié l'e-mail de mot de passe ne sera pas envoyé. Veuillez contacter le support si vous avez perdu votre mot de passe temporaire."
							})
						}}
					</strong>
				</div>
				<br />
				<div class="form-group">
					<input
						class="form-control"
						type="email"
						autocomplete="username"
						id="emailInputForgotPassword"
						v-bind:placeholder="
							text.getByKey('userName', {
								default: 'Email',
								en: 'Email',
								fr: 'E-mail'
							})
						"
						v-model="userName"
						required
					/>
					<div class="form-error" v-show="userNameError">{{ userNameError }}</div>
				</div>
				<button
					type="button"
					v-on:click="requestPasswordReset"
					class="btn btn-outline-secondary userPasswordResetForm-submit"
					value="Request Password Reset"
				>
					{{ buttonText }}
				</button>
				<br /><br />
				<button
					type="button"
					v-on:click="cancel"
					class="btn btn-outline-secondary userPasswordResetForm-cancel"
					value="Cancel Request"
				>
					{{
						text.getByKey("back", {
							default: "Back to Sign In Page",
							en: "Back to Sign In Page",
							fr: "Retour à la Page de Connexion"
						})
					}}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			userName: "",
			userNameError: ""
		};
	},
	props: {
		title: String,
		buttonText: String,
		text: Object
	},
	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		}
	},
	name: "UserPasswordReset",
	methods: {
		requestPasswordReset: function() {
			if (this.userName.length === 0) {
				this.userNameError = this.text.getByKey("userNameRequiredError", {
					default: "User name required",
					en: "User name required",
					fr: "Nom d'utilisateur requis"
				});
				return;
			}
			const { userName } = this;
			this.$emit("requestPasswordReset", { userName });
		},
		cancel: function() {
			this.$emit("cancel");
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.user-password-reset-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.user-password-reset-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
