<template>
	<div class="general-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<div class="content-wrapper">
				<div class="player-and-controls" :style="applyCCStyleVariables()">
					<div class="player-wrapper">
						<div v-if="currentPlayer">
							<iframe
								v-if="playerTypeIs('simple-iframe')"
								v-bind="currentPlayer.playerParameters"
								:key="playerRedrawKey"
							></iframe>
							<video-js-player-wrapper
								v-else-if="playerTypeIs('video-js-player')"
								v-bind:config="currentPlayer.playerParameters"
								:key="playerRedrawKey"
							></video-js-player-wrapper>
							<svp-player v-else v-bind:playerElement="currentPlayer" v-bind:gistId="config.name" :key="playerRedrawKey" />
						</div>
					</div>
					<div class="player-selectors">
						<player-picker
							class="player-selector"
							v-bind:config="config"
							v-bind:common="common"
							v-bind:resourceKeyRoot="'Main'"
							v-on:selectPlayer="selectPlayer"
							:text="text.shiftContext('playerPicker')"
						/>
						<closed-caption-picker
							class="player-selector"
							v-bind:config="config"
							v-bind:common="common"
							v-bind:resourceKeyRoot="'Main'"
							v-on:selectCC="selectCC"
							:text="text.shiftContext('ccPicker')"
						/>
					</div>
					<iframe
						class="player-cc-view"
						v-if="isCCType('rawIFrame')"
						id="cciframe"
						title="title"
						width="100%"
						height="100%"
						:src="currentCC.config.url"
						:style="applyCCStyleVariables()"
						:key="captionRedrawKey"
					>
					</iframe>
					<iframe
						v-else-if="isCCType('configBoundIFrame')"
						v-bind="currentCC.config.attributes"
						:src="currentCC.config.url"
						:key="captionRedrawKey"
					>
					</iframe>
					<captions v-else-if="isCCType('internalService')" :config="captionsConfig(currentCC.config)"> </captions>
					<iframe
						v-if="isHearMeCheerActive"
						title="HearMeCheer"
						:src="hearMeCheerUrl"
						width="400"
						height="125"
						frameborder="0"
						allow="microphone"
						id="hmc-app"
					></iframe>
				</div>
				<chat class="main-stage-chat" v-bind:config="config.chat" v-bind:resourceKeyBase="chatResourceKeyBase"></chat>
			</div>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
/* eslint-disable no-useless-escape */
import SvpPlayer from "../components/SvpPlayer";
import VideoJsPlayerWrapper from "@/components/VideoJsPlayerWrapper.vue";

import PlayerPicker from "../components/PlayerPicker";
import ClosedCaptionPicker, { ccOff } from "../components/ClosedCaptionPicker";
import Chat from "../components/Chat";
import Captions from "../components/captions/Captions.vue";
import { log } from "@/logging";

import { ValueType } from "../analytics/model/analyticsEvent";

const myLoggingName = "MainWithAdjacentChat";
const defaultCCHeight = 135;
const defaultCCPaddingAdjustment = defaultCCHeight + 100;

export default {
	name: "MainWithAdjacentChat",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	data: () => ({
		currentPlayer: null,
		chatResourceKeyBase: null,
		currentCC: null,
		ccContext: null,
		playerContext: null,
		playerRedrawKey: 0,
		captionRedrawKey: 0
	}),
	components: { Background, Chat, SvpPlayer, PlayerPicker, ClosedCaptionPicker, VideoJsPlayerWrapper, Captions },
	inject: ["textService"],
	computed: {
		isHearMeCheerActive() {
			return this.config.hearMeCheer && this.config.hearMeCheer.url;
		},
		hearMeCheerUrl() {
			return this.config.hearMeCheer.url;
		},
		text() {
			return this.textService.copy(this.config.text, ["root", this.$route.name]);
		}
	},
	methods: {
		captionsConfig(config) {
			return config?.config;
		},
		playerTypeIs(playerType) {
			const player = this.currentPlayer;
			log(myLoggingName, "playerTypeIs", player);
			return player.type === playerType;
		},
		selectPlayer(currentPlayer) {
			log(myLoggingName, "player selected", currentPlayer);
			this.playerContext.egress("picked");
			this.currentPlayer = currentPlayer;
			this.playerRedrawKey++;
			this.playerContext.egress("picked").then(() => {
				this.currentPlayer = currentPlayer;
				let values = new Map();
				let value = {
					type: ValueType.String,
					value: currentPlayer.key
				};
				values.set("type", value);
				// let valueArray = [];
				// values.forEach((v, k) => valueArray.push("key=" + k + ": value=" + v.value));
				// log(myLoggingName, "selectPlayer", "debug", "localValues", valueArray);

				this.playerContext.ingress(values);
			});
		},
		selectCC(ccConfig) {
			log(myLoggingName, "cc selected", ccConfig, ccConfig.name);
			if (ccConfig != ccOff) {
				this.currentCC = ccConfig;
			} else {
				this.currentCC = null;
			}
			this.captionRedrawKey++;
			this.ccContext.egress("picked").then(() => {
				let values = new Map();
				let value = {
					type: ValueType.String,
					value: ccConfig.key
				};
				values.set("type", value);
				value = {
					type: ValueType.String,
					value: this.text.get(ccConfig.name)
				};
				values.set("name", value);

				// let valueArray = [];
				// values.forEach((v, k) => valueArray.push("key=" + k + ": value=" + v.value));
				// log(myLoggingName, "selectCC", "debug", "localValues", valueArray);

				this.ccContext.ingress(values);
			});
		},
		applyCCStyleVariables() {
			let styleVars = {
				"--cc-view-height": "0px",
				"--cc-container-padding-adjustment": "0px"
			};
			if (this.currentCC) {
				styleVars = {
					"--cc-view-height": defaultCCHeight + "px",
					"--cc-container-padding-adjustment": defaultCCPaddingAdjustment + "px"
				};
				// override with config, if present
				if (this.config.styleVariables) {
					Object.keys(this.config.styleVariables).forEach(key => {
						styleVars["--" + key] = this.config.styleVariables[key];
					});
				}
			}
			return styleVars;
		},
		isCCType(type) {
			if (this.currentCC?.config?.type === type) {
				return true;
			}
			return false;
		}
	},
	mounted() {
		const protectedResourceKey = "Main" + this.config.name;
		this.chatResourceKeyBase = protectedResourceKey;
		this.ccContext = this.analyticsContext.addChild("cc");
		this.playerContext = this.analyticsContext.addChild("player");
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.content-wrapper {
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	display: flex;
	align-items: flex-start;
}

.player-and-controls {
	flex: 1;
	flex-direction: column;
	position: relative;
	// yes, there appears to be some redundancy here, but the iframe players went wonky if the padding (padding bottom, width, height)
	// was not specified in the player-wrapper class. Also the selector would be positioned over the player if the analogous (padding bottom, etc)
	// was not also specified in the player-and-controls
	$player-width-percent: 67%;
	width: $player-width-percent;
	height: 0px;
	padding-bottom: calc(#{$player-width-percent} * (9 / 16) + var(--cc-container-padding-adjustment));
	.player-wrapper {
		position: relative;
		flex: 22;
		$player-width-percent: 100%;
		width: $player-width-percent;
		height: 0px;
		padding-bottom: calc(#{$player-width-percent} * (9 / 16));
	}
	// Change this to player selectors with their own nested flex (row) - will contain both player and cc selected
	// As a first attempt, the out of band cc text will be placed after (below) or above (?) the selectors?
	.player-selectors {
		flex: 1;
		align-self: flex-start;
		//align-content: flex-end;
		position: relative;
		display: flex;
	}
	.player-cc-view {
		flex: 1 0 var(--cc-view-height);
		height: var(--cc-view-height);
	}
	.player-selector {
		//flex: 0 30px;
		color: white;
	}
}

.main-stage-chat {
	width: 33%;
	padding-left: 25px;
	height: 100%;
}

@media (max-width: $screen-tablet-max) {
	.content-wrapper {
		flex-direction: column;
	}

	.player-and-controls {
		$player-width-percent: 100%;
		width: $player-width-percent;
		padding-bottom: calc(#{$player-width-percent} * (9 / 16) + var(--cc-container-padding-adjustment));
		.player-cc-view {
			flex: 1 0 var(--cc-view-height);
			height: var(--cc-view-height);
		}
	}

	.main-stage-chat {
		padding: 0px;
		margin-top: 40px;
		width: 100%;
		height: 550px;
	}
}
</style>
