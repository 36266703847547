import Vue from "vue";
import VueRouter from "vue-router";
import DynamicHome from "../views/DynamicHome.vue";
import Lounge from "../views/Lounge";
import ArbitraryData from "../views/ArbitraryData";
import MainStageWithAdjacentChat from "../views/MainStageWithAdjacentChat";
import MainStage from "../views/MainStage";
import VideoLibrary from "../views/VideoLibrary";
import TableHall from "../views/TableHall";
import Authentication from "../views/Authentication";
import VideoChat from "../views/VideoChat";
import Registration from "../views/Registration";
import Iframe from "../views/Iframe";

import { log } from "@/logging";

const myLoggingName = "router";

Vue.use(VueRouter);

// https://github.com/vuejs/vue-router/blob/dev/examples/redirect/app.js

// and

// https://github.com/vuejs/vue-router/tree/dev/examples/auth-flow

export default class RouterConfigurer {
	createAuthNagivationGuard(auth) {
		if (!auth.isAnonymousMode()) {
			return function(to, from, next) {
				if (!auth.authed()) {
					next({
						name: "authentication"
					});
				} else {
					next();
				}
			};
		} else {
			return function(to, from, next) {
				next();
			};
		}
	}

	createDynamicHome(config, common, pages, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		let pageData = pages.map(p => {
			return {
				routeName: p.routeName,
				type: p.type
			};
		});
		config.config.clickAreas
			.filter(ca => ca.areaType === "route")
			.filter(ca => pageData.filter(pd => pd.routeName === ca.routeName).length == 0)
			.forEach(ca => log(myLoggingName, "createDyanmicHome - Page defintion not found for routed click area " + ca.routeName));
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common,
				pageData: pageData
			},
			component: DynamicHome,
			beforeEnter: authNavigationGuard
		};
	}

	createMainStage(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: MainStage,
			beforeEnter: authNavigationGuard
		};
	}

	createMainStageWithAdjacentChat(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: MainStageWithAdjacentChat,
			beforeEnter: authNavigationGuard
		};
	}

	createVideoLibrary(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: VideoLibrary,
			beforeEnter: authNavigationGuard
		};
	}

	createLounge(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: Lounge,
			beforeEnter: authNavigationGuard
		};
	}

	createArbitraryData(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: ArbitraryData,
			beforeEnter: authNavigationGuard
		};
	}

	createAuthentication(config, common, homePage) {
		return {
			path: config.routePath + "/:event?",
			name: config.routeName,
			props: {
				config: config.config,
				common: common,
				homePage: homePage
			},
			component: Authentication
		};
	}

	createAuthenticationAdminReset(config, common, homePage) {
		return {
			path: config.routePath + "/:event?/:code",
			name: config.routeName + "AdminPasswordReset",
			props: {
				config: config.config,
				common: common,
				homePage: homePage
			},
			component: Authentication
		};
	}

	createLogout(auth) {
		return {
			path: "/logout",
			name: "logout",
			beforeEnter(to, from, next) {
				auth.logout()
					.then(() => {
						next("/authentication");
					})
					.catch(() => {
						next("/authentication");
					});
			}
		};
	}

	createTableHall(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: TableHall,
			beforeEnter: authNavigationGuard
		};
	}
	createRegistration(config, common, homePage) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath + "/:event?",
			name: config.routeName,
			props: {
				config: config.config,
				common: common,
				homePage: homePage
			},
			component: Registration
		};
	}

	createCompleteRegistration(config, common, homePage) {
		return {
			path: config.routePath + "/:event?/:code?",
			name: config.routeName + "CompleteRegistration",
			props: {
				config: config.config,
				common: common,
				homePage: homePage
			},
			component: Registration
		};
	}

	createVideoChat(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: VideoChat,
			beforeEnter: authNavigationGuard
		};
	}

	createIframe(config, common, authNavigationGuard) {
		//let navigationGuard = config.isGuarded ? authNavigationGuard : null;
		return {
			path: config.routePath,
			name: config.routeName,
			props: {
				config: config.config,
				common: common
			},
			component: Iframe,
			beforeEnter: authNavigationGuard
		};
	}

	createDefaultRoute(config, siteConfig, authNavigationGuard, auth) {
		const configCopy = Object.assign({}, config);
		configCopy.routePath = "*";
		configCopy.routeName = "notfound";

		switch (configCopy.type) {
			case "dynamicHome":
				return this.createDynamicHome(configCopy, siteConfig.common, siteConfig.pages, authNavigationGuard);
			case "mainStage":
				return this.createMainStage(configCopy, siteConfig.common, authNavigationGuard);
			case "mainStageWithAdjacentChat":
				return this.createMainStageWithAdjacentChat(configCopy, siteConfig.common, authNavigationGuard);
			case "lounge":
				return this.createLounge(configCopy, siteConfig.common, authNavigationGuard);
			case "arbitraryData":
				return this.createArbitraryData(configCopy, siteConfig.common, authNavigationGuard);
			case "videoLibrary":
				return this.createVideoLibrary(configCopy, siteConfig.common, authNavigationGuard);
			case "tableHall":
				return this.createTableHall(configCopy, siteConfig.common, authNavigationGuard);
			case "videoChat":
				return this.createVideoChat(configCopy, siteConfig.common, authNavigationGuard);
			case "iframe":
				return this.createIframe(configCopy, siteConfig.common, authNavigationGuard);
			case "registration":
				if (!auth.isAnonymousMode()) {
					return this.createRegistration(configCopy, siteConfig.common, configCopy.routeName);
				}
				break;
			case "authentication":
				if (!auth.isAnonymousMode()) {
					return this.createAuthentication(configCopy, siteConfig.common, configCopy.routeName);
				}
				break;
			default:
				log(myLoggingName, `Unsupported page type of ${config.type}`);
		}
	}

	createRoutesForDynamicSite(siteConfig, authNavigationGuard, auth, siteMode, guardedPageNames) {
		const routes = [];
		siteConfig.pages.forEach(config => {
			const isGuarded = guardedPageNames.find(it => it === config.routeName);
			const guard = isGuarded ? authNavigationGuard : null;
			switch (config.type) {
				case "dynamicHome":
					routes.push(this.createDynamicHome(config, siteConfig.common, siteConfig.pages, guard));
					break;
				case "mainStage":
					routes.push(this.createMainStage(config, siteConfig.common, guard));
					break;
				case "mainStageWithAdjacentChat":
					routes.push(this.createMainStageWithAdjacentChat(config, siteConfig.common, guard));
					break;
				case "lounge":
					routes.push(this.createLounge(config, siteConfig.common, guard));
					break;
				case "arbitraryData":
					routes.push(this.createArbitraryData(config, siteConfig.common, guard));
					break;
				case "videoLibrary":
					routes.push(this.createVideoLibrary(config, siteConfig.common, guard));
					break;
				case "tableHall":
					routes.push(this.createTableHall(config, siteConfig.common, guard));
					break;
				case "videoChat":
					routes.push(this.createVideoChat(config, siteConfig.common, guard));
					break;
				case "iframe":
					return routes.push(this.createIframe(config, siteConfig.common, authNavigationGuard));
				case "registration":
					routes.push(this.createRegistration(config, siteConfig.common, siteMode.defaultPage));
					routes.push(this.createCompleteRegistration(config, siteConfig.common, siteMode.defaultPage));
					break;
				case "authentication":
					if (!auth.isAnonymousMode()) {
						routes.push(this.createAuthentication(config, siteConfig.common, siteMode.defaultPage));
						routes.push(this.createLogout(auth));
						routes.push(this.createAuthenticationAdminReset(config, siteConfig.common, siteMode.defaultPage));
					}
					break;
				default:
					log(myLoggingName, `Unsupported page type of ${config.type}`);
			}
		});
		// set up default page as the 404
		const defaultPage = siteConfig.pages.find(it => it.routeName === siteMode.defaultPage);
		if (defaultPage) {
			log(myLoggingName, "createRoutesForDynamicSite", { defaultRoute: defaultPage.routeName });
			const isGuarded = guardedPageNames.find(it => it === defaultPage.routeName);
			const guard = isGuarded ? authNavigationGuard : null;

			routes.push(this.createDefaultRoute(defaultPage, siteConfig, guard, auth));
		} else {
			log(myLoggingName, "createRoutesForDynamicSite", "No default route");
		}

		return routes;
	}

	createRoutes(siteConfig, auth, siteMode, guardedPageNames) {
		let routes = null;
		let authNavigationGuard = this.createAuthNagivationGuard(auth);
		if (siteConfig.type === "dynamic") {
			routes = this.createRoutesForDynamicSite(siteConfig, authNavigationGuard, auth, siteMode, guardedPageNames);
		} else {
			throw "Invalid site type " + siteConfig.type;
		}
		return routes;
	}

	createRouter(routes) {
		return new VueRouter({
			routes
		});
	}
}
