<template>
	<div class="user-attributes-component">
		<div class="user-attributes-component-wrapper">
			<b-form form id="userAttributesForm" :validated="validated">
				<div class="instructions">
					{{
						localText.getByKey("instructions", {
							default: "Please provide some information about yourself",
							en: "Please provide some information about yourself",
							fr: "Veuillez fournir quelques informations sur vous-même"
						})
					}}
				</div>
				<br />
				<div v-for="attribute in config.fields" v-bind:key="attribute.field">
					<span v-if="attribute.type === 'input'">
						<b-container fluid>
							<b-row>
								<b-col cols="12" class="text-left">
									<label :for="attribute.field">{{ localText.get(attribute.fieldLabel) }}</label>
									<span v-if="attribute.field === 'custom:pronouns'">
										<br><label>{{ localText.get(attribute.otherText) }}</label>
										<a
											v-bind:href="localText.get(attribute.otherTextLink)"
											target="_new"
											style="color:#FF001B">
											{{ localText.get(attribute.clickHereText) }}
										</a>
									</span>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12" class="text-left">
									<b-form-input
										:id="attribute.field"
										:type="attribute.config.subType"
										v-on:input="attributesUpdated(attribute.field, $event)"
										v-bind:placeholder="localText.get(attribute.fieldDescription)"
										v-bind:required="attribute.isRequired"
										v-bind:value="attributes[attribute.field]"
										:state="validationState(attribute.field)"
									/>
									<b-form-invalid-feedback :id="attribute.field">{{
										localText.get(fieldErrors[attribute.field])
									}}</b-form-invalid-feedback>
								</b-col>
							</b-row>
						</b-container>
					</span>
					<span v-else-if="attribute.type === 'radio'">
						<b-container fluid>
							<b-row>
								<b-col cols="12" class="text-left">
									<b-form-group :id="attribute.field" :label="localText.get(attribute.fieldLabel)">
										<b-form-radio
											v-for="value in attribute.config.allowedValues"
											:key="value.value"
											v-on:input="attributesUpdatedViaRadio(attribute.field, value.value)"
											:value="value.value"
											:state="validationState(attribute.field)"
											v-bind:required="attribute.isRequired"
											:name="attribute.field"
											>{{ localText.get(value.text) }}</b-form-radio
										>
									</b-form-group>
									<b-form-invalid-feedback :id="attribute.field">{{
										localText.get(fieldErrors[attribute.field])
									}}</b-form-invalid-feedback>
								</b-col>
							</b-row>
						</b-container>
					</span>
					<span v-else-if="attribute.type === 'select'">
						<b-container fluid>
							<b-row>
								<b-col cols="12" class="text-left">
									<label :for="attribute.field">{{ localText.get(attribute.fieldLabel) }}</label>
								</b-col>
							</b-row>
							<b-row align-v="center">
								<b-col cols="12" class="text-left">
									<b-form-select
										:id="attribute.field"
										:text="localText.get(attribute.fieldDescription)"
										:state="validationState(attribute.field)"
										v-on:input="attributesUpdatedViaSelect(attribute.field, $event)"
										v-bind:value="currentValueSelect(attribute.field)"
										v-bind:required="attribute.isRequired"
									>
										<b-form-select-option :value="null">{{
											localText.getByKey("selectInstructions", {
												default: "Please select an option",
												en: "Please select an option",
												fr: "Veuillez sélectionner une option"
											})
										}}</b-form-select-option>
										<b-form-select-option
											v-for="currentValue in attribute.config.allowedValues"
											:key="currentValue.value"
											v-bind:value="currentValue.value"
											>{{ localText.get(currentValue.text) }}</b-form-select-option
										>
									</b-form-select>
									<b-form-invalid-feedback :id="attribute.field">{{
										localText.get(fieldErrors[attribute.field])
									}}</b-form-invalid-feedback>
								</b-col>
							</b-row>
						</b-container>
					</span>
					<span v-else-if="attribute.type === 'multilinetext'">
						<b-container fluid>
							<b-row>
								<b-col cols="12" class="text-left">
									<label :for="attribute.field">{{ localText.get(attribute.fieldLabel) }}</label>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12" class="text-left">
									<b-form-textarea
										:id="attribute.field"
										v-on:input="attributesUpdated(attribute.field, $event)"
										v-bind:placeholder="localText.get(attribute.fieldDescription)"
										v-bind:required="attribute.isRequired"
										v-bind:value="attributes[attribute.field]"
										:state="validationState(attribute.field)"
										v-bind="multiTextAttributes(attribute.config)"
									/>
									<b-form-invalid-feedback :id="attribute.field">{{
										localText.get(fieldErrors[attribute.field])
									}}</b-form-invalid-feedback>
								</b-col>
							</b-row>
						</b-container>
					</span>
					<span v-else>
						<input
							class="form-control"
							type="text"
							v-bind:placeholder="attribute.fieldDescription"
							v-bind:required="attribute.isRequired"
							v-bind:value="attributes[attribute.field]"
							v-on:input="attributesUpdated(attribute.field, $event)"
						/>
					</span>
					<br />
				</div>
				<div class="form-error" v-show="errorText.length > 0" v-for="error in errorText" v-bind:key="errorKey(error)">
					{{ error }}
				</div>
				<br />
				<slot name="changed" v-bind:changed="update"></slot>
			</b-form>
			<br />
			<br />
		</div>
	</div>
</template>

<script>
import { log } from "@/logging";
const myLoggingName = "UserAttributes";

// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			errorText: [],
			attributes: {},
			componentKey: 0,
			fieldErrors: {},
			validated: false
		};
	},
	props: {
		config: Object,
		userData: Object,
		common: Object,
		text: Object
	},
	inject: ["textService"],
	name: "UserAttributes",
	computed: {
		localText() {
			return this.text.copyWithLocalScopeOveride(this.config.text);
		}
	},
	methods: {
		multiTextAttributes(config) {
			// If attributes have been specified for the multilinetext element then:
			// Create a safe copy of the attributes (so changes don't impact on the original configuration)
			// Validate key parameters and adjust as required (e.g. maxCharacters)
			let attributes = {};
			if (config) {
				attributes = Object.assign(attributes, config.attributes);
				if (attributes.maxLength && attributes.maxLength > 2048) {
					log(myLoggingName, "multiTextArea maxLength is too big ", attributes.maxLength);
					attributes.maxLength = 2048;
					log(myLoggingName, "multiTextArea reset maxLength to ", attributes.maxLength);
				}
			}
			return attributes;
		},
		forceRender() {
			this.componentKey += 1;
		},
		/**
		 * return a key for error text.
		 */
		errorKey(error) {
			return this.localText.get(error);
		},
		currentValueSelect: function(key) {
			return this.attributes[key] ?? "null";
		},
		attributesUpdated: function(key, value) {
			this.fieldErrors[key] = null;
			this.attributes[key] = value;
		},
		attributesUpdatedViaRadio: function(key, value) {
			this.fieldErrors[key] = null;
			this.attributes[key] = value;
		},
		attributesUpdatedViaSelect: function(key, value) {
			this.fieldErrors[key] = null;
			this.attributes[key] = value;
			this.forceRender();
		},
		validationState: function(key) {
			if (this.validated) {
				return this.fieldErrors[key] ? false : true;
			}
			return null;
		},
		transform(attributeConfig, attributes) {
			const userAttributes = {};
			for (var i = 0; i < attributeConfig.length; i++) {
				const attribute = attributeConfig[i];
				log(myLoggingName, "update processing", { attribute: attribute, field: attributes[attribute.field] });
				if (attributes[attribute.field]) {
					log(myLoggingName, "update adding ", attribute.field);
					userAttributes[attribute.field] = this.attributes[attribute.field];
				}
			}
			return userAttributes;
		},
		validation: function(attributeConfig, attributes) {
			if (attributeConfig.isRequired && (!attributes[attributeConfig.field] || attributes[attributeConfig.field] === undefined)) {
				// return this.languageService.concat(
				// 	attributeConfig.fieldDescription,
				// 	{ default: "is required", en: "is required", fr: "est requis(e)" },
				// 	" ",
				// 	true
				// );
				return this.localText.getByKey(
					"isRequiredErrorSuffix",
					{ default: "is required", en: "is required", fr: "est requis(e)" },
					this.localText.get(attributeConfig.fieldDescription)
				);
			} else {
				return null;
			}
		},

		groupValidation: function(rules, attributes) {
			const errors = [];
			log(myLoggingName, "groupValidation", attributes);

			if (rules.includes("FirstOrLastRequired")) {
				if (attributes["given_name"] !== undefined || attributes["family_name"] !== undefined) {
					return errors;
				}
				log(myLoggingName, "groupValidation Validation Failed");
				errors.push(
					this.localText.getByKey("firstOrLastNameRequiredError", {
						default: "One of first or last name must be provided",
						en: "One of first or last name must be provided",
						fr: "L'un des nom ou prénom doit être fourni"
					})
				);
			}
			return errors;
		},
		transformAndValidate: function() {
			this.errorText = [];
			this.fieldErrors = {};
			let userAttributes = this.transform(this.config.fields, this.attributes);
			this.validated = true;
			this.config.fields
				.map(it => {
					// TODO This could be refactored to not have side effects
					const errorText = this.validation(it, userAttributes);
					if (errorText) {
						this.fieldErrors[it.field] = errorText;
					}
					return errorText;
				})
				.filter(it => {
					return it != null;
				})
				.forEach(it => {
					this.errorText.push(it);
				});
			this.groupValidation(this.config.validationRules, userAttributes).forEach(it => {
				this.errorText.push(it);
			});

			if (this.errorText.length > 0) {
				return null;
			}

			return { userName: this.userData.userName, attributes: userAttributes };
		},
		update: function() {
			const updateData = this.transformAndValidate();
			if (updateData) {
				this.$emit("update", updateData);
			}
		}
	}
};
</script>
<style scoped lang="scss">
@import "../../scss/constants";

.user-attributes-component-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
}

.form-error {
	font-size: small;
	color: red;
}

@media (max-width: $screen-mobile-max) {
	.user-attributes-component-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
	}
}
</style>
