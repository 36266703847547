<template>
	<div class="signin-component">
		<div class="signin-component-wrapper">
			<b-form form id="signinForm">
				<div class="title">
					{{ text.getByKey("title", { default: "Sign-in", en: "Sign-in", fr: "Connexion" }) }}
				</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("instructions", {
							default: "Please provide your email address and password to enter the venue",
							en: "Please provide your email address and password to enter the venue",
							fr: "Veuillez fournir votre adresse e-mail et votre mot de passe pour entrer dans le lieu"
						})
					}}
				</div>
				<br />
				<div class="form-group">
					<div class="form-row">
						<input
							class="form-control"
							type="email"
							autocomplete="username"
							id="emailInputSignin"
							v-bind:placeholder="
								text.getByKey('userName', {
									default: 'Email',
									en: 'Email',
									fr: 'E-mail'
								})
							"
							required
							v-model="userName"
						/>
					</div>
					<div class="form-error" v-show="userNameError">{{ userNameError }}</div>
				</div>
				<div class="form-group">
					<password
						id="passwordInputSignin"
						v-model="password"
						autoComplete="current-password"
						:text="text.shiftContext('password')"
					></password>
					<div class="form-error" v-show="passwordError">{{ passwordError }}</div>
				</div>
				<b-button id="signinButton" idtype="button" v-on:click="signin" variant="outline-dark" value="Sign in">
					{{
						text.getByKey("signinbutton", {
							default: "Sign-in",
							en: "Sign-in",
							fr: "Connexion"
						})
					}}
				</b-button>
			</b-form>
			<br />
			<b-button-group v-if="config && config.isForgotPasswordOn" id="initiatePasswordResetGroup">
				<b-button id="forgotPasswordButton" type="button" v-on:click="forgotPassord" variant="outline-dark" value="Forgot Password">
					{{
						text.getByKey("forgotPasswordButton", {
							default: "Forgot Password",
							en: "Forgot Password",
							fr: "Mot de Passe Oublié"
						})
					}}
				</b-button>
				<b-button
					v-if="config && config.isCompleteAccountConfirmationOn"
					id="completeAccountConfirmationButton"
					type="button"
					v-on:click="completeAccountConfirmation"
					variant="outline-dark"
					value="Complete Account Confirmation"
				>
					{{
						text.getByKey("completeConfirmationButton", {
							default: "Complete Account Confirmation",
							en: "Complete Account Confirmation",
							fr: "Compléter la confirmation de compte"
						})
					}}
				</b-button>
			</b-button-group>
			<br />
			<br />
			<div id="signinHelp" class="instructions">
				{{
					text.getByKey("needHelp", {
						default: "Need help? Please contact",
						en: "Need help? Please contact",
						fr: "Vous avez besoin d'aide ? Veuillez contacter"
					})
				}}
				<b-link v-bind:href="supportEmail">{{ supportHost }}</b-link>
			</div>
			<br />
			<div class="instructions">
				<b>
					{{
						text.getByKey("firstTimeLoggingIn", {
							default: "First time logging in ?",
							en: "First time logging in ?",
							fr: "Première connexion ?"
						})
					}}
				</b>
			</div>
			<br />
			<div class="instructions">
				{{
					text.getByKey(
						"firstTimeLoggingInInstructions",
						{
							default:
								"Please use the username and password provided in your access email from {0}. If you did not receive this email, please check your spam folder or email us to have it reissued.",
							en:
								"Please use the username and password provided in your access email from {0}. If you did not receive this email, please check your spam folder or email us to have it reissued.",
							fr:
								"Veuillez utiliser le nom d'utilisateur et le mot de passe fournis dans votre e-mail d'accès de {0}. Si vous n'avez pas reçu cet e-mail, veuillez vérifier votre dossier spam ou envoyez-nous un e-mail pour qu'il soit réémis."
						},
						supportHost
					)
				}}
			</div>
		</div>
	</div>
</template>

<script>
import Password from "./Password";
//import { getElementByPath } from "@/stringutilities";

// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	props: {
		config: Object,
		common: Object
	},
	data() {
		return {
			userName: "",
			password: "",
			userNameError: "",
			passwordError: ""
		};
	},
	components: { Password },
	inject: ["textService"],
	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		},
		password: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.passwordError = "";
			}
		}
	},
	computed: {
		supportEmail() {
			return "mailto:" + (this.common.supportEmail ?? "support@kingevents.ca");
		},
		supportHost() {
			return this.common.supportEmail ?? "support@kingevents.ca";
		},
		text() {
			return this.textService.copy(this.config.text);
		}
	},

	name: "SignIn",
	methods: {
		signin: function() {
			let error = false;
			if (this.password.length == 0) {
				this.passwordError = this.text.getByKey("passwordRequiredError", {
					default: "Password required",
					en: "Password required",
					fr: "Mot de passe requis"
				});
				//				this.passwordError = "password required";
				error = true;
			}
			if (this.userName.length == 0) {
				this.userNameError = this.text.getByKey("userNameRequiredError", this.config.text, {
					default: "User name required",
					en: "User name required",
					fr: "Nom d'utilisateur requis"
				});
				//				this.userNameError = "username required";
				error = true;
			}
			if (error) {
				return;
			}

			const { userName, password } = this;

			if (userName && password) {
				this.$emit("login", { userName, password });
			}
		},
		forgotPassord: function() {
			this.$emit("forgotPassword");
		},
		completeAccountConfirmation: function() {
			this.$emit("completeAccountConfirmation");
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.signin-component-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.signin-component-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
