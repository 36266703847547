import { render, staticRenderFns } from "./UserInput.vue?vue&type=template&id=6823c708&scoped=true&"
import script from "./UserInput.vue?vue&type=script&lang=ts&"
export * from "./UserInput.vue?vue&type=script&lang=ts&"
import style0 from "./UserInput.vue?vue&type=style&index=0&id=6823c708&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6823c708",
  null
  
)

export default component.exports