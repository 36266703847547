<template>
	<div v-if="mobileSrc ? mobileSrc : src" class="bg-wrapper">
		<img class="bg" :src="src" />
		<img class="bg-mobile" :src="mobileSrc ? mobileSrc : src" />
	</div>
</template>

<script>
export default {
	name: "Background",
	props: {
		src: String,
		mobileSrc: String
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.bg-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bg,
.bg-mobile {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.bg-mobile {
	display: none;
}
@media (max-width: $screen-mobile-max) {
	.bg {
		display: none;
	}
	.bg-mobile {
		display: initial;
	}
}
</style>
