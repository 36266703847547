<template>
	<div class="home-page" ref="pageViewport" :style="applyStyleVariables()">
		<div class="client-logo">
			<img class="logo-img" :src="common.logo" alt="" />
		</div>
		<div class="home-bg" :style="bgStyle" ref="backgroundImage">
			<!-- <div class="client-logo">
			<img class="logo-img" :src="common.logo" alt="" />
		</div> -->
			<img class="bg-image" :src="text.get(config.bg)" v-on:load="scaleToFitScreen" />
			<div class="click-area-ctn">
				<div v-for="room in orderedClickAreas(config.clickAreas)" v-bind:key="room.id">
					<router-link
						v-if="elementTypeForRoom(room) === 'standard'"
						v-bind:to="{ name: room.routeName }"
						v-bind:style="styleForRoom(room)"
						:aria-label="text.get(room.displayName)"
					>
						<div class="click-area" v-bind:class="classForRoom(room)">{{ text.get(room.displayName) }}</div>
					</router-link>
					<a
						v-else-if="elementTypeForRoom(room) === 'externalLink'"
						v-bind:href="room.config.url"
						v-bind:style="styleForRoom(room)"
						class="click-area"
						v-bind:class="classForRoom(room)"
						target="_blank"
						v-on:click="trackClick(room)"
						:aria-label="text.get(room.displayName)"
					>
						{{ text.get(room.displayName) }}
					</a>
					<a
						v-else-if="elementTypeForRoom(room) === 'kingeventsLink'"
						v-bind:href="room.config.url"
						v-bind:style="styleForRoom(room)"
						class="click-area hide-on-small-screen"
						v-bind:class="classForRoom(room)"
						target="_blank"
						v-on:click="trackClick(room)"
						:aria-label="text.get(room.displayName)"
					>
						{{ text.get(room.displayName) }}
					</a>
					<div
						v-else-if="elementTypeForRoom(room) === 'localConcierge'"
						class="click-area"
						v-bind:class="classForRoom(room)"
						v-on:click="toggleConciergeState"
						v-bind:style="styleForRoom(room)"
						:aria-label="text.get(room.displayName)"
					>
						{{ text.get(room.displayName) }}
					</div>
					<span v-else-if="elementTypeForRoom(room) === 'gamesModal'">
						<div
							v-b-modal="'modal' + room.id"
							class="click-area"
							v-bind:class="classForRoom(room)"
							v-bind:style="styleForRoom(room)"
							:aria-label="text.get(room.displayName)"
						>
							{{ text.get(room.displayName) }}
						</div>
						<b-modal
							v-bind:id="'modal' + room.id"
							centered
							v-bind:size="modalSize(room.config.modal)"
							v-on:show="onModalShow(room)"
							v-on:hide="onModalHide(room)"
							hide-footer
						>
							<!-- {{ room.config.qr }} -->
							<div
								v-if="room.config.qr.title"
								class="modal-main"
								v-bind:class="room.config.qr.title.class"
								v-bind:style="room.config.qr.title.style"
							>
								{{ text.get(room.config.qr.title.text) }}
							</div>
							<div v-for="(title, index) in room.config.qr.mainText" v-bind:key="index">
								<div class="modal-main" v-bind:class="title.class" v-bind:style="title.style">
									{{ text.get(title.text) }}
								</div>
							</div>

							<div
								v-if="room.config.qr.image"
								class="modal-main"
								v-bind:class="room.config.qr.image.class"
								v-bind:style="room.config.qr.image.style"
							>
								<img :src="text.get(room.config.qr.image.url)" />
							</div>
							<div
								v-if="room.config.qr.imageURLText"
								class="modal-main"
								v-bind:class="room.config.qr.imageURLText.class"
								v-bind:style="room.config.qr.imageURLText.style"
							>
								{{ text.get(room.config.qr.imageURLText.text) }}
							</div>
							<br />
							<div
								v-if="room.config.qr.button"
								class="modal-main"
								v-bind:class="room.config.qr.button.class"
								v-bind:style="room.config.qr.button.style"
							>
								<b-button v-bind:href="room.config.qr.button.url" target="_blank">
									{{ text.get(room.config.qr.button.text) }}
								</b-button>
							</div>
							<br />
						</b-modal>
					</span>
					<span v-else-if="elementTypeForRoom(room) === 'iframeModal'">
						<div
							v-b-modal="'modal' + room.id"
							class="click-area"
							v-bind:class="classForRoom(room)"
							v-bind:style="styleForRoom(room)"
							:aria-label="text.get(room.displayName)"
						>
							{{ text.get(room.displayName) }}
						</div>
						<b-modal
							v-bind:id="'modal' + room.id"
							centered
							v-bind:size="modalSize(room.config.modal)"
							v-on:show="onModalShow(room)"
							v-on:hide="onModalHide(room)"
							hide-footer
						>
							<iframe id="iframe" width="100%" height="600px" v-bind="room.config.iframe" v-bind:src="iframeUrl(room)">
							</iframe>
						</b-modal>
					</span>
					<span v-else-if="elementTypeForRoom(room) === 'videojsModal'">
						<div
							v-b-modal="'modal' + room.id"
							class="click-area"
							v-bind:class="classForRoom(room)"
							v-bind:style="styleForRoom(room)"
							:aria-label="text.get(room.displayName)"
						>
							{{ text.get(room.displayName) }}
						</div>
						<b-modal
							v-bind:id="'modal' + room.id"
							centered
							v-bind:size="modalSize(room.config.modal)"
							v-on:show="onModalShow(room)"
							v-on:hide="onModalHide(room)"
							hide-footer
						>
							<video-js-player-wrapper v-bind:config="room.config.modal.playerParameters"></video-js-player-wrapper>
						</b-modal>
					</span>
					<span v-else-if="elementTypeForRoom(room) === 'addEventToCalendar'">
						<span v-if="Object.keys(room.config.types).length === 1 && room.config.autoComplete">
							<add-to-calendar
								style="width:100%"
								v-bind:eventData="calEvent(room.config.eventData)"
								v-bind:type="Object.keys(room.config.types)[0]"
								v-bind:styleOverride="room.config.styleVariables"
								renderless
							>
								<template v-slot:generate="{ generate }">
									<div
										class="click-area"
										v-bind:class="classForRoom(room)"
										v-bind:style="styleForRoom(room)"
										:aria-label="text.get(room.displayName)"
										v-on:click="generate"
									>
										{{ text.get(room.displayName) }}
									</div>
								</template>
							</add-to-calendar>
						</span>
						<span v-else>
							<div
								v-b-modal="'modal' + room.id"
								class="click-area"
								v-bind:class="classForRoom(room)"
								v-bind:style="styleForRoom(room)"
								:aria-label="text.get(room.displayName)"
							>
								{{ text.get(room.displayName) }}
							</div>
							<b-modal
								v-bind:title="itemOrDefault(text.get(room.config.modal.title), 'Add To Calendar')"
								v-bind:id="'modal' + room.id"
								centered
								v-bind:size="modalSize(room.config.modal)"
								hide-footer
							>
								<span v-for="(description, type) in room.config.types" v-bind:key="type">
									<add-to-calendar
										style="width:100%"
										v-bind:eventData="calEvent(room.config.eventData)"
										v-bind:type="type"
										v-bind:description="text.get(description)"
										v-bind:styleOverride="room.config.styleVariables"
									>
									</add-to-calendar>
									<br />
								</span>
							</b-modal>
						</span>
					</span>
					<span v-else-if="elementTypeForRoom(room) === 'countdownTimer'">
						<div class="visible-area" v-bind:style="styleForRoom(room)" :aria-label="text.get(room.displayName)">
							<count-down-timer :config="room.config" />
						</div>
					</span>

					<span v-else-if="elementTypeForRoom(room) === 'musicPlayer'">
						<div
							class="visible-area fixed-mobile-location"
							v-bind:style="styleForRoom(room)"
							:aria-label="text.get(room.displayName)"
						>
							>
							<music-player :config="room.config" :common="common" />
						</div>
					</span>

					<div v-else class="click-area click-main" v-bind:style="styleForRoom(room)">
						<!--  system message - not configurable -->
						{{
							text.get(
								{ default: "Room name not found", en: "Room name not found", fr: "Nom de la salle introuvable" },
								text.get(room.displayName)
							)
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { log } from "@/logging";
import VideoJsPlayerWrapper from "@/components/VideoJsPlayerWrapper.vue";
import CountDownTimer from "@/components/CountDownTimer.vue"; //JM Counter code
import AddToCalendar from "@/components/calendar/AddToCalendar.vue";
import MusicPlayer from "@/components/musicplayer/MusicPlayer.vue";

const myLoggingName = "MembersSection";

export default {
	name: "DynamicHome",
	props: {
		config: Object,
		common: Object,
		pageData: Array,
		analyticsContext: Object
	},
	data: () => {
		return {
			bgStyle: {
				transform: "scale(0, 0)" // This will be adjusted so the image fits in the screen
			},
			analyticsContexts: {},
			eventTime: "" //JM Counter code
		};
	},
	inject: ["zendeskManager", "textService", "authService"],
	components: { VideoJsPlayerWrapper, CountDownTimer, AddToCalendar, MusicPlayer },
	computed: {
		text() {
			return this.textService.copy({}, ["root", this.$route.name]);
		}
	},

	methods: {
		iframeUrl(room) {
			let url = new URL(room.config.iframe.src);
			if (room.config.modal.addAccessToken && this.authService.user?.signInUserSession?.refreshToken?.token) {
				url.searchParams.append("token", this.authService.user?.signInUserSession?.refreshToken?.token);
			}
			return url.toString();
		},
		// TODO confirm format of test file and copy changes to code
		// test on microsoft and google some more
		itemOrDefault(item, defaultItem) {
			return item ?? defaultItem;
		},
		calEvent(event) {
			const start = new Date(event.start);
			const end = new Date(event.end);
			return {
				title: event.title,
				location: event.location,
				description: event.description,
				start: start,
				end: end,
				url: event.url,
				recurrence: null
			};
		},
		scaleToFitScreen() {
			const pageElement = this.$refs.pageViewport;
			const backgroundElement = this.$refs.backgroundImage;
			const pageAspectRatio = pageElement.clientWidth / pageElement.clientHeight;
			const backgroundAspectRatio = backgroundElement.clientWidth / backgroundElement.clientHeight;

			// Scales to fit a maximum width or height (whatever comes first) for the page's viewport
			let scalingFactor =
				pageAspectRatio < backgroundAspectRatio
					? pageElement.clientWidth / backgroundElement.clientWidth
					: pageElement.clientHeight / backgroundElement.clientHeight;
			//this.bgStyle.transform = `scale(${scalingFactor}, ${scalingFactor})`;
			this.bgStyle = { "--scaling-factor": scalingFactor };
		},
		toggleConciergeState() {
			this.zendeskManager.toggleState();
		},
		orderedClickAreas(clickAreas) {
			let copy = clickAreas.map(i => i).sort((a, b) => a.id - b.id);
			return copy;
		},
		styleForRoom(room) {
			if (!room.dimensions.mobileTopLeftX) {
				return {
					"--click-area-top-left-x": room.dimensions.topLeftX + "px",
					"--click-area-top-left-y": room.dimensions.topLeftY + "px",
					"--click-area-width": room.dimensions.width + "px",
					"--click-area-height": room.dimensions.height + "px"
				};
			} else {
				return {
					"--click-area-top-left-x": room.dimensions.topLeftX + "px",
					"--click-area-top-left-y": room.dimensions.topLeftY + "px",
					"--click-area-width": room.dimensions.width + "px",
					"--click-area-height": room.dimensions.height + "px",
					"--mobile-click-area-top-left-x": room.dimensions.mobileTopLeftX + "px",
					"--mobile-click-area-top-left-y": room.dimensions.mobileTopLeftY + "px",
					"--mobile-click-area-height": room.dimensions.mobileHeight + room.dimensions.mobileUnits,
					"--mobile-click-area-width": room.dimensions.mobileWidth + room.dimensions.mobileUnits
				};
			}
		},
		classForRoom(room) {
			if (room.class) {
				return room.class;
			}
			return "click-main";
		},
		modalSize(roomConfig) {
			// eslint-disable-next-line prettier/prettier
			if (roomConfig?.size ) {
				return roomConfig.size;
			} else {
				return "lg";
			}
		},
		elementTypeForRoom(room) {
			switch (room.areaType) {
				case "concierge":
					return "localConcierge";
				case "gamesModal":
					return "gamesModal";
				case "iframeModal":
					return "iframeModal";
				case "externalLink":
					return "externalLink";
				case "videojsModal":
					return "videojsModal";
				case "addEventToCalendar":
					return "addEventToCalendar";
				case "countdownTimer":
					return "countdownTimer";
				case "musicPlayer":
					return "musicPlayer";
				case "kingeventsLink":
					return "kingeventsLink";
				default:
					break;
			}
			let roomType = this.pageData.filter(p => p.routeName === room.routeName)[0].type;
			switch (roomType) {
				case "mainStage":
				case "mainStageWithAdjacentChat":
				case "lounge":
				case "arbitraryData":
				case "videoLibrary":
				case "tableHall":
				case "videoChat":
				case "dynamicHome":
				case "iframe":
				case "registration":
					return "standard";
				default:
					return "unknown";
			}
		},
		applyStyleVariables() {
			if (!this.config?.styleVariables) return {};
			const styleVars = {}; //  No defaults
			Object.keys(this.config.styleVariables).forEach(key => {
				styleVars["--" + key] = this.config.styleVariables[key];
			});
			return styleVars;
		},
		onModalShow(itemConfig) {
			log(myLoggingName, "mounted Modal is about to be opened", { itemId: itemConfig.id });
			const context = this.analyticsContexts["modal" + itemConfig.id.toString()];
			context.ingress(new Map());
		},
		onModalHide(itemConfig) {
			log(myLoggingName, "mounted Modal is about to be closed", { itemId: itemConfig.id });
			const context = this.analyticsContexts["modal" + itemConfig.id.toString()];
			context.egress("closed");
		},
		addChildrenToAnalytics() {
			const capturedVue = this;
			let clickAreas = this.orderedClickAreas(this.config.clickAreas);
			clickAreas.forEach(clickArea => {
				let type = capturedVue.elementTypeForRoom(clickArea);
				const key = clickArea.id.toString();
				switch (type) {
					case "concierge":
					case "externalLink":
					case "kingeventsLink":
						capturedVue.analyticsContexts[key] = capturedVue.analyticsContext.addChild(clickArea.displayName.toString());
						break;
					case "gamesModal":
					case "iframeModal":
					case "videojsModal":
						capturedVue.analyticsContexts["modal" + key] = capturedVue.analyticsContext.addChild(
							clickArea.displayName.toString()
						);
						break;
					default:
						break;
				}
			});
		},
		trackClick(room) {
			const roomAnalyitcsContext = this.analyticsContexts[room.id];
			const values = new Map();
			const event = roomAnalyitcsContext.makeEvent("clicked", "event", values);
			roomAnalyitcsContext.recordEvent(event);
		}
	},
	mounted() {
		window.addEventListener("resize", this.scaleToFitScreen);
		this.addChildrenToAnalytics();
	},
	destroyed() {
		window.removeEventListener("resize", this.scaleToFitScreen);
	}
};
</script>
<style lang="scss" scoped>
@import "../scss/common";

.home-page {
	display: grid;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	box-sizing: content-box;

	background: linear-gradient(to top right, var(--primary-colour), var(--primary-gradient-colour));
}

.home-bg {
	position: relative;
	transform-origin: top;
	transform: scale(var(--scaling-factor), var(--scaling-factor));
}

.bg-image {
	max-width: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.modal-main {
	text-align: center;
}

.qr-code {
	text-align: center;
	> img {
		max-width: 200px;
	}
}
.fixed-mobile-location {
	color: transparent;
	opacity: 1;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: initial;
	left: var(--mobile-click-area-top-left-x);
	top: var(--mobile-click-area-top-left-y);
	width: var(--mobile-click-area-width);
	height: var(--mobile-click-area-height);
	cursor: default;
}

@media (max-width: $screen-mobile-max) {
	.client-logo {
		max-width: 100%;
		.logo-img {
			padding-top: 80px;
		}
	}

	.home-page {
		height: 100%;
		overflow-y: auto;
	}

	.bg-image {
		display: none;
	}

	.home-bg {
		width: 100%;
		transform: scale(0.6, 0.6);
	}

	.click-area-ctn {
		width: 100%;

		a {
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		.click-area {
			display: block;
			position: relative;
			opacity: 1;

			color: var(--secondary-text-colour);
			width: 100%;
			min-width: 350px;
			margin: 20px auto;
			padding: 20px;
			border-radius: 10px;
			box-shadow: 1px 3px 8px 2px rgba($color: #000000, $alpha: 0.4);
			font-size: 30px;

			&:empty {
				display: none;
			}

			&.click-main {
				background: linear-gradient(to top right, var(--secondary-colour), var(--secondary-gradient-colour));
			}

			&.click-block {
				background: linear-gradient(to top right, var(--secondary-colour), var(--secondary-gradient-colour));
			}
		}
		.visible-area {
			display: block;
			position: relative;
			opacity: 1;

			color: var(--secondary-text-colour);
			width: 100%;
			min-width: 350px;
			// margin: 20px auto;
			// padding: 20px;
			border-radius: 10px;
			box-shadow: 1px 3px 8px 2px rgba($color: #000000, $alpha: 0.4);

			cursor: default;

			//font-size: 30px;

			&:empty {
				display: none;
			}
		}
		.fixed-mobile-location {
			//color: transparent;
			//opacity: 1;
			//position: absolute;
			//display: flex;
			//justify-content: center;
			//align-items: center;
			//min-width: initial;
			//left: var(--mobile-click-area-top-left-x);
			//top: var(--mobile-click-area-top-left-y);
			//width: var(--mobile-click-area-width);
			//height: var(--mobile-click-area-height);
			//cursor: default;
			display: none;
		}

		.hide-on-small-screen {
			display: none;
		}
	}
}

@media (min-width: $screen-tablet-min) {
	.client-logo {
		display: none;
	}
	.home-bg {
		width: 100%;
		transform: scale(var(--scaling-factor), var(--scaling-factor));
	}

	.click-area-ctn {
		.click-area {
			color: transparent;

			display: flex;
			justify-content: center;
			align-items: center;

			&.click-main {
				left: var(--click-area-top-left-x);
				top: var(--click-area-top-left-y);
				width: var(--click-area-width);
				height: var(--click-area-height);
			}

			&.click-block {
				left: var(--click-area-top-left-x);
				top: var(--click-area-top-left-y);
				width: var(--click-area-width);
				height: var(--click-area-height);
				opacity: 1;
				font-size: 28px;
				background-color: rgba(11, 153, 235, 0.877);
				color: white;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		.click-area:hover {
			background-color: rgb(129, 144, 112);
		}
		.visible-area {
			color: transparent;
			opacity: 1;

			display: flex;
			justify-content: center;
			align-items: center;
			left: var(--click-area-top-left-x);
			top: var(--click-area-top-left-y);
			width: var(--click-area-width);
			height: var(--click-area-height);
			cursor: default;
		}
	}
}
</style>
