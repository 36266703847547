var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"self-register-component"},[_c('div',{staticClass:"self-register-wrapper"},[_c('form',{attrs:{"id":"selfRegisterForm"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.text.getByKey("title", { default: "Register", en: "Register", fr: "Enregistrer" }))+" ")]),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeComponent === 'Credentials'),expression:"activeComponent === 'Credentials'"}],staticClass:"supply-credentials"},[_c('div',{staticClass:"instructions"},[_c('strong',[_vm._v(" "+_vm._s(_vm.text.getByKey("instructions", { default: "Please start by entering your email address and creating a password for your new account.", en: "Please start by entering your email address and creating a password for your new account.", fr: "Veuillez commencer par saisir votre adresse e-mail et créer un mot de passe pour votre nouveau compte." }))+" ")])]),_c('br'),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],staticClass:"form-control",attrs:{"type":"email","autocomplete":"username","id":"emailInputRegister","placeholder":_vm.text.getByKey('userName', {
								default: 'Email',
								en: 'Email',
								fr: 'E-mail'
							}),"required":""},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}}),_vm._t("userNameError",function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userNameError),expression:"userNameError"}],staticClass:"form-error"},[_vm._v(_vm._s(_vm.userNameError))])]})],2),_c('new-password',{ref:"password",attrs:{"text":_vm.text.shiftContext('newPassword')},scopedSlots:_vm._u([{key:"password1Error",fn:function(ref){
							var error = ref.error;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(error.length > 0),expression:"error.length > 0"}],staticClass:"form-error"},[_c('ul',_vm._l((error),function(passwordError){return _c('li',{key:passwordError,staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(passwordError)+" ")])}),0)])]}},{key:"password2Error",fn:function(ref){
							var error = ref.error;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(error),expression:"error"}],staticClass:"form-error"},[_vm._v(_vm._s(error))])]}}])}),_c('b-button',{staticClass:"btn mr-1",attrs:{"type":"button","variant":"primary","value":"Next"},on:{"click":_vm.gatheredCredentials}},[_vm._v(" "+_vm._s(_vm.nextButtonText)+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeComponent === 'Attributes'),expression:"activeComponent === 'Attributes'"}],staticClass:"supply-attributes"},[_c('user-attributes',{ref:"attributes",attrs:{"config":_vm.config.attributes,"common":_vm.common,"userData":_vm.userData,"text":_vm.text.shiftContext('userAttributes')}}),_c('div',[_c('b-button',{staticClass:"btn mr-1",attrs:{"type":"button","variant":"primary","value":"Register"},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.text.getByKey("registerButtonText", { default: "Register", en: "Register", fr: "Enregistrer" }))+" ")]),_c('b-button',{staticClass:"btn mr-1",attrs:{"type":"button","variant":"secondary","value":"Back"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.text.getByKey("backButtonText", { default: "Back", en: "Back", fr: "Retour" }))+" ")])],1)],1),_c('br'),_c('need-help',{attrs:{"common":_vm.common,"text":_vm.text.shiftContext('needHelp')}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }