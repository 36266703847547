import { render, staticRenderFns } from "./ConversationsSection.vue?vue&type=template&id=7a29bae7&scoped=true&"
import script from "./ConversationsSection.vue?vue&type=script&lang=ts&"
export * from "./ConversationsSection.vue?vue&type=script&lang=ts&"
import style0 from "./ConversationsSection.vue?vue&type=style&index=0&id=7a29bae7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a29bae7",
  null
  
)

export default component.exports