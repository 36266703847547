<template>
	<div class="general-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<chat
				class="lounge-chat"
				v-bind:config="config.chat"
				v-bind:resourceKeyBase="chatResourceKeyBase"
				v-bind:fixed-open="true"
				v-bind:analyticsContext="analyticsContext"
			></chat>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
import Chat from "../components/Chat";

export default {
	name: "Lounge",
	data: () => ({
		mounted: false,
		chatResourceKeyBase: null
	}),
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	components: { Background, Chat },
	mounted() {
		let capturedVue = this;
		const protectedResourceKey = "Lounge" + capturedVue.config.name;
		this.chatResourceKeyBase = protectedResourceKey;
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.lounge-chat {
	width: 100%;
	height: 100%;
	box-sizing: content-box;
}
</style>
