<template>
	<div class="arbitrary-data-component">
		<div class="arbitrary-data-wrapper">
			<form v-if="searchOn" id="arbitrary-data-filter">
				<b-form inline class="attendees-filter-form-group">
					<label class="sr-only" for="inline-form-input-name">Search</label>
					<b-input
						class="form-control"
						type="search"
						id="arbitrary-data-filter-input"
						v-model="filter"
						placeholder="Type to Search"
						style="width:80%;"
					/>
					<b-button type="reset" v-bind:disabled="!filter" v-on:click="filter = ''" class="btn btn-primary" style="width:20%;">
						Clear
					</b-button>
				</b-form>
			</form>
			<b-table
				sticky-header
				headVariant="light"
				responsive="md"
				borderless="borderless"
				v-bind:busy="isBusy"
				v-bind:fields="displayFields"
				v-bind:items="arbitraryData"
				v-bind:filter="filter"
				v-on:row-clicked="clickHandler"
				v-bind:sort-by="sortBy"
				v-bind:style="tableAndRowStyle()"
				class="row-background"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<template #cell()="data">
					<div v-bind:style="getCellStyle()">
						<img v-if="data.field.renderType === 'image'" :src="urlForImage(data)" width="75px" />
						<b-link
							target="_blank"
							v-else-if="data.field.renderType === 'link'"
							v-bind:href="data.value"
							v-on:click="trackACellClick(data.value)"
						>
							data.value
						</b-link>
						<div v-else style="text-align: left;" class="h5 mt-4 mb-0">{{ data.value }}</div>
					</div>
				</template>

				<template v-slot:row-details="row">
					<!-- <div v-on:click="row.toggleDetails" style="cursor: pointer;text-decoration: none;"> -->
					<div v-on:click="clickHandler(row.item)" v-bind:style="getCellStyle()">
						<b-card v-if="detailConfiguration.type === 'keyValue'" class="card-background" v-bind:style="cardStyle()">
							<div v-for="detail in detailConfiguration.detailFields" v-bind:key="detail.key">
								<b-row class="mb-2">
									<b-col v-if="detail.labelType === 'static'" sm="6" class="text-sm-right"
										><b>{{ detail.labelData.label }}:</b>
									</b-col>
									<b-col v-else-if="dynamicDetail(detail, row.item)" sm="6" class="text-sm-right"
										><b>{{ getDetailLabel(detail, row.item) }} :</b>
									</b-col>
									<b-col v-else sm="6" class="text-sm-right" />

									<b-col v-if="detail.renderType === 'link' && row.item[detail.key]" sm="6" class="text-sm-left">
										<b-link
											target="_blank"
											v-bind:href="getDetailLink(detail, row.item)"
											v-on:click="trackAClick(detail, row.item)"
											>{{ row.item[detail.key] }}</b-link
										>
									</b-col>
									<b-col v-else sm="6" class="text-sm-left"> {{ row.item[detail.key] }}</b-col>
								</b-row>
							</div>
						</b-card>
						<b-card
							v-if="detailConfiguration.type === 'imageCard'"
							v-bind:img-src="getImageUrlForDetail(row.item, detailConfiguration.image)"
							img-left
							v-bind:img-width="detailConfiguration.image.size"
							v-bind:img-height="detailConfiguration.image.size"
							class="border-0 card-background"
							v-bind:style="cardStyle()"
						>
							<!-- <b-card-img v-bind:src="https://placekitten.com/480/210" alt="Image" left></b-card-img> -->
							<b-card-body class="py-0">
								<div v-for="detail in detailConfiguration.detailFields" v-bind:key="detail.key">
									<div v-if="detail.renderType === 'link'" class="text-left">
										<b-row v-if="row.item[detail.key]">
											<b-col v-if="detail.labelType === 'static'" sm="12" md="4" lg="4"
												><b>{{ detail.labelData.label }}:</b>
											</b-col>
											<b-col v-else-if="dynamicDetail(detail, row.item)" sm="12" md="4" lg="4"
												><b>{{ getDetailLabel(detail, row.item) }} :</b>
											</b-col>
											<b-col v-else sm="12" md="4" lg="4" />
											<b-col sm="12" md="8" lg="8">
												<b-link
													target="_blank"
													v-bind:href="getDetailLink(detail, row.item)"
													v-on:click="trackAClick(detail, row.item)"
													>{{ row.item[detail.key] }}</b-link
												>
											</b-col>
										</b-row>
									</div>
									<b-card-text v-else class="text-left"> {{ row.item[detail.key] }}</b-card-text>
									<div v-if="row.item[detail.key]"></div>
								</div>
							</b-card-body>
						</b-card>
						<b-card v-if="detailConfiguration.type === 'horizontalImageCard'" no-body v-bind:style="cardStyle()">
							<b-row no-gutters>
								<b-col cols="12" sm="12" md="3" lg="2">
									<b-card-img
										v-bind:src="getImageUrlForDetail(row.item, detailConfiguration.image)"
										alt="Image"
										left
										class="rounded-0"
									></b-card-img>
								</b-col>
								<b-col cols="12" sm="12" md="9" lg="10">
									<b-card-body>
										<div v-for="detail in detailConfiguration.detailFields" v-bind:key="detail.key">
											<div v-if="detail.renderType === 'link'" class="text-left">
												<b-row v-if="row.item[detail.key]">
													<b-col v-if="detail.labelType" cols="12" sm="3" xl="2"
														><b>{{ getDetailLabel(detail, row.item) }} :</b>
													</b-col>
													<b-col v-else cols="12" sm="3" xl="2" />
													<b-col cols="12" sm="9" xl="10">
														<b-link
															target="_blank"
															v-bind:href="getDetailLink(detail, row.item)"
															v-on:click="trackAClick(detail, row.item)"
															>{{ row.item[detail.key] }}</b-link
														>
													</b-col>
												</b-row>
											</div>
											<div v-else-if="detail.renderType === 'iframeModal'" cols="12" sm="4">
												<iframe-modal
													v-bind:src="getDetailLink(detail, row.item)"
													v-bind:text="getDetailLabel(detail, row.item)"
													width="100%"
													height="600px"
													v-bind:title="getDetailLabel(detail, row.item)"
													v-bind:id="row.item[keyField]"
													v-bind:analyticsContext="analyticsContext"
												></iframe-modal>
											</div>
											<b-card-text v-else class="text-left"> {{ row.item[detail.key] }}</b-card-text>
											<div v-if="row.item[detail.key]"></div>
										</div>
									</b-card-body>
								</b-col>
							</b-row>
						</b-card>
					</div>
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
import IframeModal from "../components/IframeModal";
import { formatString } from "@/stringutilities";

import { log } from "@/logging";

const myLoggingName = "ArbitraryData";
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	props: {
		fields: Array,
		detailConfiguration: Object,
		source: String,
		configName: String,
		sortBy: String,
		searchOn: Boolean,
		styles: Object,
		keyField: String,
		analyticsContext: Object
	},
	data: () => {
		return {
			arbitraryData: [],
			isBusy: true,
			filter: "",
			siteConfig: null,
			clickAnalyticsContext: null
		};
	},
	name: "ArbitraryData",
	inject: ["authService", "protectedResource", "configService"],
	components: { IframeModal },
	mounted() {
		this.isBusy = true;
		this.dataProvider();
		let capturedVue = this;
		this.configService.getSiteConfig().then(config => {
			capturedVue.siteConfig = config;
		});
		this.clickAnalyticsContext = this.analyticsContext.addChild("click");
	},
	computed: {
		displayFields: function() {
			const rc = this.fields.map(i => i);
			//rc.push({ key: "actions", label: "Actions" });
			return rc;
		}
	},
	methods: {
		trackACellClick(url) {
			const values = new Map();
			let value = {
				type: "String",
				value: url
			};

			values.set("url", value);

			const event = this.clickAnalyticsContext.makeEvent("clicked", "event", values);
			this.clickAnalyticsContext.recordEvent(event);
		},
		trackAClick(detail, item) {
			const values = new Map();
			let value = {
				type: "String",
				value: this.getDetailLink(detail, item)
			};

			values.set("url", value);

			const event = this.clickAnalyticsContext.makeEvent("clicked", "event", values);
			this.clickAnalyticsContext.recordEvent(event);
		},
		clickHandler: function(item) {
			if (this.detailConfiguration.detailToggleOn) {
				if (this.detailConfiguration.detailFields && this.detailConfiguration.detailFields.length > 0) {
					this.$set(item, "_showDetails", !item._showDetails);
				} else {
					this.$set(item, "_showDetails", false);
				}
			}
		},
		getCellStyle() {
			if (this.detailConfiguration.detailToggleOn) {
				return { cursor: "pointer", textDecoration: "none" };
			} else {
				return {};
			}
		},
		// getRowClass(item, type) {
		// 	if (!item || (type !== "row" && type !== "row-details")) return;
		// 	return "arbitrary-data-row-class";
		// },
		/**
		 * Specifies whether the detail's label type is dynamic or not.
		 * It's dynamic if the labelType is appropriate and the item has a value for the field specified in detail.labelData.labelField
		 *
		 * Deprecated as getDetailLabel now handles all labelTypes
		 */
		dynamicDetail(detail, item) {
			return (detail.labelType === "dynamic" || detail.labelType === "dynamicLink") && item[detail.labelData.labelField];
		},
		getDetailLabel(detail, item) {
			switch (detail.labelType) {
				case "dynamicLink":
					switch (item[detail.labelData.labelField]) {
						case "website":
							return "website";
						case "web site":
						case "URL":
							return "Web Site";
						case "instagram":
							return "Instagram";
						case "twitter":
							return "Twitter";
						case "facebook":
							return "Facebook";
						case "linkedin":
							return "Linkedin";
						default:
							return "Link";
					}
				case "dynamic":
					return item[detail.labelData.labelField];
				case "static":
					return detail.labelData;
				default:
					return "Link";
			}
		},
		getDetailLink(detail, item) {
			let userName = "";
			switch (detail.labelType) {
				case "dynamicLink":
					switch (item[detail.labelData.labelField]) {
						case "instagram":
							userName = item[detail.key].replace("@", "");
							return `https://www.instagram.com/${userName}`;
						case "twitter":
							userName = item[detail.key].replace("@", "");
							return `https://www.twitter.com/${userName}`;
						case "facebook":
							userName = item[detail.key].replace("@", "");
							return `https://www.facebook.com/${userName}`;
						case "linkedin":
							userName = item[detail.key].replace("@", "");
							return `https://www.linkedin.com/in/${userName}`;
						case "website":
						case "web site":
						case "URL":
						default:
							return item[detail.key];
					}
				default:
					return item[detail.key];
			}
		},
		urlForImage(data) {
			if (data.item[data.field.urlTypeKey] === "explicit") {
				return data.value;
			}
			let url = "";
			if (this.siteConfig) {
				url =
					this.configService.addHostToUrl(this.siteConfig.common.staticResourceBaseUrlFormatTemplate) +
					formatString(this.siteConfig.common.staticResourceDefaultViewSegmentUrlFormatTemplate, {
						componentType: "arbitrarydata"
					}) +
					`${this.configName}itemimage${data.item[this.keyField]}.png`;
			}
			return url;
		},
		getImageUrlForDetail(item, configuration) {
			if (item[configuration.urlTypeKey] === "explicit") {
				log(myLoggingName, "explicit url is " + item[configuration.key]);
				return item[configuration.key];
			}
			let url = "";
			if (this.siteConfig) {
				url =
					this.configService.addHostToUrl(this.siteConfig.common.staticResourceBaseUrlFormatTemplate) +
					formatString(this.siteConfig.common.staticResourceDefaultViewSegmentUrlFormatTemplate, {
						componentType: "arbitrarydata"
					}) +
					`${this.configName}itemimage${item[this.keyField]}.png`;
			}
			return url;
		},
		parsePredefinedStyle(style) {
			let rc = {};
			if (style) {
				const predefined = {
					"--background-color": style.backgroundColour ?? "rgba(11, 153, 235, 0.877)",
					"--background-color-sm-size": style.backgroundColourSmallSize ?? "black"
				};
				rc = Object.assign(rc, predefined);
			}
			return rc;
		},
		parsePredefinedTableStyle(style) {
			let rc = {};
			if (style) {
				const predefined = {
					"--max-table-height": style.maxHeight ?? "300px"
				};
				rc = Object.assign(rc, predefined);
			}
			return rc;
		},
		tableAndRowStyle() {
			let style = this.parsePredefinedStyle(this.styles?.row?.styleOverride?.predefined);
			if (this.styles?.table?.styleOverride?.predefined) {
				style = Object.assign(style, this.parsePredefinedTableStyle(this.styles.table.styleOverride.predefined));
			}
			if (this.styles?.table?.styleOverride?.custom) {
				style = Object.assign(style, this.styles.table.styleOverride.custom);
			}
			return style;
		},
		cardStyle() {
			let style = this.parsePredefinedStyle(this.styles?.card?.styleOverride?.predefined);
			return style;
		},
		dataProvider: function() {
			this.isBusy = true;
			const capturedVue = this;
			this.authService
				.getToken()
				.then(async token => {
					return await this.protectedResource.getArbitraryData(token, capturedVue.source);
				})
				.then(data => {
					let rc = [];
					if (data) {
						rc = data
							// Include item if it has an atribute with a key that matches one of the required fields AND the corresponding
							// value is not null or empty
							.filter(item => {
								let attributes = item.attributes;
								return capturedVue.fields.some(field => {
									let test = attributes[field.key] && attributes[field.key].trim().length > 0;
									return test;
								});
							})
							.map(item => {
								// Map to fields - include all attributes as some may be displayed in the details
								let mappedItem = {};
								let attributes = item.attributes;
								for (const [key, value] of Object.entries(attributes)) {
									mappedItem[key] = value;
								}
								// Add fields required by b-table element
								mappedItem["_showDetails"] = capturedVue.detailConfiguration.detailToggleInitially;
								return mappedItem;
							});
					} else {
						log(myLoggingName, "dataProvider empty ");
					}
					capturedVue.isBusy = false;
					capturedVue.arbitraryData = rc;
				})
				.catch(err => {
					log(myLoggingName, "dataProvider error " + err);
					capturedVue.isBusy = false;
					capturedVue.arbitraryData = [];
				});
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.row-background {
	background-color: var(--background-color);
}

.card-background {
	background-color: var(--background-color);
}

.arbitrary-data-wrapper {
	max-width: 95%;
	display: block;
	margin: 0 auto;
	background-color: white;
	max-height: 80%;
}

.b-table-sticky-header {
	max-height: var(--max-table-height);
}

@media (max-width: $screen-mobile-max) {
	.arbitrary-data-wrapper {
		position: relative;
		width: 95%;
	}
	.row-background {
		background-color: var(--background-color-sm-size);
	}

	.card-background {
		background-color: var(--background-color-sm-size);
	}
}
</style>
