<template>
	<div class="general-page">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<div class="content-wrapper">
				<div class="player-wrapper" v-if="currentPlayer" :style="adjustPlayerWrapperStyleVariables()">
					<iframe v-if="playerTypeIs('simple-iframe')" v-bind="currentPlayer.playerParameters"></iframe>
					<video-js-player-wrapper
						v-else-if="playerTypeIs('video-js-player')"
						v-bind:config="currentPlayer.playerParameters"
						:key="playerRedrawKey"
					></video-js-player-wrapper>
					<svp-player v-else v-bind:playerElement="currentPlayer" v-bind:gistId="config.name" />
				</div>
				<div class="player-selectors">
					<player-picker
						class="player-selector"
						v-bind:config="config"
						v-bind:common="common"
						v-bind:resourceKeyRoot="'Main'"
						v-on:selectPlayer="selectPlayer"
						:text="text.shiftContext('playerPicker')"
					/>
					<closed-caption-picker
						class="player-selector"
						v-bind:config="config"
						v-bind:common="common"
						v-bind:resourceKeyRoot="'Main'"
						v-on:selectCC="selectCC"
						:text="text.shiftContext('ccPicker')"
					/>
				</div>
				<div v-if="isCCViewActive" class="cc-view-wrapper" :style="applyCCStyleVariables()">
					<iframe
						class="player-cc-view"
						v-if="isCCType('rawIFrame')"
						id="cciframe"
						title="title"
						width="100%"
						height="100%"
						:src="currentCC.config.url"
						:style="applyCCStyleVariables()"
						:key="captionRedrawKey"
					>
					</iframe>
					<iframe
						v-else-if="isCCType('configBoundIFrame')"
						v-bind="currentCC.config.attributes"
						:src="currentCC.config.url"
						:key="captionRedrawKey"
					>
					</iframe>
					<captions v-else-if="isCCType('internalService')" :config="captionsConfig(currentCC.config)"> </captions>
				</div>
				<div v-if="isHearMeCheerActive">
					<iframe
						title="HearMeCheer"
						:src="hearMeCheerUrl"
						width="400"
						height="125"
						frameborder="0"
						allow="microphone"
						id="hmc-app"
					></iframe>
				</div>
				<div
					v-if="isSliderVisible"
					class="slider-wrapper"
					:style="applyCCStyleVariables()"
					:aria-label="config.splideConfig.description"
				>
					<splide :options="config.splideConfig.options">
						<splide-slide v-for="item in config.splideConfig.images" v-bind:key="item.id">
							<!-- <img class="image" v-bind:src="item.src" v-on:click="clicked(item)" :alt="item.description" /> -->
							<div v-if="splideClickType(item) == 'modal'">
								<img
									class="image"
									v-bind:src="item.src"
									v-b-modal="'mainStageSplideModal' + item.id"
									:alt="item.description"
								/>
								<b-modal
									v-bind:id="'mainStageSplideModal' + item.id"
									v-bind="splideModelAttributes(item.click)"
									v-on:show="onModalShow(item)"
									v-on:hide="onModalHide(item)"
									hide-footer
								>
									<iframe v-bind:id="'mainStageSplideIframe' + item.id" v-bind="splideModalIframeAttributes(item.click)">
									</iframe>
									<!-- <iframe v-else id="iframe" width="100%" height="600px" v-bind:src="urlForModalSrc(item)"> </iframe> -->
									<!-- <iframe id="iframe" width="100%" height="800px" v-bind:src="urlForModalSrc(item)"> </iframe> -->
								</b-modal>
							</div>
							<img
								v-else-if="splideClickType(item) == 'newtab'"
								class="image"
								v-bind:src="item.src"
								v-on:click="splideImageClicked(item)"
								:alt="item.description"
							/>
							<img v-else class="image" v-bind:src="item.src" :alt="item.description" />
						</splide-slide>
					</splide>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
/* eslint-disable no-useless-escape */
import SvpPlayer from "../components/SvpPlayer";
import PlayerPicker from "../components/PlayerPicker";
import ClosedCaptionPicker, { ccOff } from "../components/ClosedCaptionPicker";
import Captions from "../components/captions/Captions.vue";

import { ValueType } from "../analytics/model/analyticsEvent";

import { log } from "@/logging";
import VideoJsPlayerWrapper from "@/components/VideoJsPlayerWrapper.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const myLoggingName = "MainStage";
const defaultCCHeight = 135;
const defaultCCPaddingAdjustment = defaultCCHeight + 100;

// text over image - https://css-tricks.com/text-blocks-over-image/

export default {
	name: "Main",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	data: () => ({
		currentPlayer: null,
		currentCC: null,
		ccContext: null,
		playerContext: null,
		analyticsContexts: {},
		playerRedrawKey: 0,
		captionRedrawKey: 0,
		options: {
			rewind: true,
			width: "100%",
			gap: "1rem",
			fixedWidth: 110,
			fixedHeight: 70
		}
	}),
	components: { Background, SvpPlayer, PlayerPicker, ClosedCaptionPicker, VideoJsPlayerWrapper, Splide, SplideSlide, Captions },
	inject: ["textService"],
	computed: {
		isCCViewActive() {
			return this.currentCC && this.currentCC.type != "Off";
		},
		isHearMeCheerActive() {
			return this.config.hearMeCheer && this.config.hearMeCheer.url;
		},
		hearMeCheerUrl() {
			return this.config.hearMeCheer.url;
		},
		isSliderVisible() {
			return this.config.splideConfig && this.config.splideConfig.isVisible;
		},
		text() {
			return this.textService.copy(this.config.text, ["root", this.$route.name]);
		}
	},
	methods: {
		captionsConfig(config) {
			return config?.config;
		},
		playerTypeIs(playerType) {
			const player = this.currentPlayer;
			log(myLoggingName, "playerTypeIs", player);
			return player.type === playerType;
		},
		selectPlayer(currentPlayer) {
			log(myLoggingName, "selectPlayer", currentPlayer);
			this.currentPlayer = currentPlayer;
			this.playerRedrawKey++;
			this.playerContext.egress("picked").then(() => {
				this.currentPlayer = currentPlayer;
				let values = new Map();
				let value = {
					type: ValueType.String,
					value: currentPlayer.key
				};
				values.set("type", value);
				// let valueArray = [];
				// values.forEach((v, k) => valueArray.push("key=" + k + ": value=" + v.value));
				// log(myLoggingName, "selectPlayer", "debug", "localValues", valueArray);

				this.playerContext.ingress(values);
			});
		},
		selectCC(ccConfig) {
			log(myLoggingName, "cc selected", ccConfig);
			if (ccConfig != ccOff) {
				this.currentCC = ccConfig;
			} else {
				this.currentCC = null;
			}
			this.captionRedrawKey++;
			this.ccContext.egress("picked").then(() => {
				let values = new Map();
				let value = {
					type: ValueType.String,
					value: ccConfig.key
				};
				values.set("type", value);
				value = {
					type: ValueType.String,
					value: this.text.get(ccConfig.name)
				};
				values.set("name", value);

				// let valueArray = [];
				// values.forEach((v, k) => valueArray.push("key=" + k + ": value=" + v.value));
				// log(myLoggingName, "selectCC", "debug", "localValues", valueArray);

				this.ccContext.ingress(values);
			});
		},
		applyCCStyleVariables() {
			let styleVars = {
				"--cc-view-height": "0px",
				"--cc-container-padding-adjustment": "0px"
			};
			if (this.currentCC) {
				styleVars = {
					"--cc-view-height": defaultCCHeight + "px",
					"--cc-container-padding-adjustment": defaultCCPaddingAdjustment + "px"
				};
				// override with config, if present
				if (this.config.styleVariables) {
					Object.keys(this.config.styleVariables).forEach(key => {
						styleVars["--" + key] = this.config.styleVariables[key];
					});
				}
			}
			return styleVars;
		},
		isCCType(type) {
			if (this.currentCC?.config?.type === type) {
				return true;
			}
			return false;
		},
		adjustPlayerWrapperStyleVariables() {
			let styleVars = {
				"--full-aspect-ratio-scale": "calc(9 / 16)",
				"--full-height": "0px",
				"--full-width": "100%",
				"--full-padding-bottom": "calc(var(--full-width) * var(--full-aspect-ratio-scale))",
				"--mobile-aspect-ratio-scale": "calc(9 / 16)",
				"--mobile-height": "0px",
				"--mobile-width": "100%",
				"--mobile-padding-bottom": "calc(var(--full-width) * var(--full-aspect-ratio-scale))"
			};
			const copy = {};
			Object.assign(copy, styleVars);
			if (this.currentPlayer.styleVariables?.mobile) {
				for (const key of Object.keys(styleVars)) {
					const configKey = "--" + key.substring("--mobile-".length);
					if (key.startsWith("--mobile-") && this.currentPlayer.styleVariables.mobile[configKey]) {
						copy[key] == this.currentPlayer.styleVariables.mobile[configKey];
						Object.defineProperty(copy, key, { value: this.currentPlayer.styleVariables.mobile[configKey] });
					}
				}
			}
			if (this.currentPlayer.styleVariables?.full) {
				for (const key of Object.keys(styleVars)) {
					const configKey = "--" + key.substring("--full-".length);
					if (key.startsWith("--full-") && this.currentPlayer.styleVariables.full[configKey]) {
						copy[key] == this.currentPlayer.styleVariables.full[configKey];
						Object.defineProperty(copy, key, { value: this.currentPlayer.styleVariables.full[configKey] });
					}
				}
			}
			styleVars = copy;
			return styleVars;
		},
		// Splide carousel methods.
		// Splide is the element type, carousel is it's function (internal name versus "externa/analytics" name))
		//
		// Determine which type of image functionality is active
		splideClickType(carouselImage) {
			// types that provide functionaltiy are one of "modal", "newtab"
			if (carouselImage.click) {
				return carouselImage.click.type;
			} else {
				// a splied image that does nothing when clicked
				return "legacy";
			}
		},
		// Handle a click image event - for newtab type
		splideImageClicked(carouselImage) {
			log(myLoggingName, "splideImageClicked", carouselImage.click);
			this.trackCarouselImageClick(carouselImage);
			window.open(carouselImage.click.url);
		},
		// Track a click image event - for newtab type
		trackCarouselImageClick(carouselImage) {
			const roomAnalyitcsContext = this.analyticsContext;
			const values = new Map();
			let url = carouselImage.click.url;
			let value = {
				type: "String",
				value: url
			};
			values.set("url", value);
			let name = this.determineCarouselAnalyticsName(carouselImage);
			value = {
				type: "String",
				value: name
			};
			values.set("name", value);
			const event = roomAnalyitcsContext.makeEvent("clicked", "event", values);
			roomAnalyitcsContext.recordEvent(event);
		},
		setAttributes(config, defaultValues) {
			// copy default
			const rc = Object.assign({}, defaultValues);
			// add config elements to copy and return
			Object.keys(config).forEach(key => {
				const value = config[key];
				log(myLoggingName, "setAttributes add", key, value);
				rc[key] = value;
			});
			return rc;
		},
		// Set the attributes for the splide model
		splideModelAttributes(clickConfig) {
			const defaultAttributes = { size: "lg" };
			return this.setAttributes(clickConfig.modalAttributes, defaultAttributes);
		},
		// Set the attributes for the iframe that is displayed when a "modal" splide image is clicked
		splideModalIframeAttributes(clickConfig) {
			const defaultAttributes = { width: "100%", height: "600px" };
			return this.setAttributes(clickConfig.iframeAttributes, defaultAttributes);
		},
		// Handle splide modal open event - send the appropriate ingress event to analytics
		onModalShow(carouselImage) {
			const id = this.determineCarouselAnalyticsKey(carouselImage);
			log(myLoggingName, "mounted Modal is about to be opened", { itemId: id });
			const context = this.analyticsContexts[id];
			context.ingress(new Map());
			return;
		},
		// Handle splide modal close event - send the appropriate ingress event to analytics
		onModalHide(carouselImage) {
			const id = this.determineCarouselAnalyticsKey(carouselImage);
			log(myLoggingName, "mounted Modal is about to be closed", { itemId: id });
			const context = this.analyticsContexts[id.toString()];
			context.egress("closed");

			return;
		},
		determineCarouselAnalyticsName(carouselImage) {
			let name = "MainStageCarousel" + carouselImage.id;
			if (carouselImage.click?.analyticsName) {
				name = carouselImage.click.analyticsName;
			}
			return name;
		},
		determineCarouselAnalyticsKey(carouselImage) {
			let name = "MainStageCarouselKey" + carouselImage.id;
			if (carouselImage.click?.analyticsName) {
				name = carouselImage.click.analyticsName;
			}
			return name;
		},
		addChildrenToAnalytics() {
			const capturedVue = this;
			// Create a child to track the duration spend in each splide carousel image modal
			if (this.config.splideConfig?.images) {
				this.config.splideConfig.images.forEach(carouselImage => {
					if (capturedVue.splideClickType(carouselImage) == "modal") {
						const name = capturedVue.determineCarouselAnalyticsName(carouselImage);
						const key = capturedVue.determineCarouselAnalyticsKey(carouselImage);
						capturedVue.analyticsContexts[key] = capturedVue.analyticsContext.addChild(name);
					}
				});
			}
		}
	},
	mounted() {
		this.ccContext = this.analyticsContext.addChild("cc");
		this.playerContext = this.analyticsContext.addChild("player");
		this.addChildrenToAnalytics();
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.content-wrapper {
	// $player-width-percent: 100%;
	// width: $player-width-percent;
	height: 100%;
	max-width: 1300px;
	box-sizing: content-box;
	margin: auto;
	//	padding-bottom: calc(#{$player-width-percent} * (9 / 16) );
}

.player-wrapper {
	--full-aspect-ratio-scale: calc(9 / 16);
	--full-height: 0px;
	--full-width: 100%;
	--full-padding-bottom: calc(
		var(--full-width) * var(--full-aspect-ratio-scale)
	); // padding MUST be equal to width: forces 16:9 aspect ratio
	position: relative;
	width: var(--full-width);
	height: var(--full-height);
	padding-bottom: var(--full-padding-bottom); // padding MUST be equal to width: forces 16:9 aspect ratio
}

@media (max-width: $screen-mobile-max) {
	.player-wrapper {
		--mobile-aspect-ratio-scale: calc(9 / 16);
		--mobile-height: 0px;
		--mobile-width: 100%;
		--mobile-padding-bottom: calc(
			var(--full-width) * var(--full-aspect-ratio-scale)
		); // padding MUST be equal to width: forces 16:9 aspect ratio
		position: relative;
		width: var(--mobile-width);
		height: var(--mobile-height);
		padding-bottom: var(--mobile-padding-bottom); // padding MUST be equal to width: forces 16:9 aspect ratio
		//		height: calc(-50px + 100vh);
		// padding-bottom: 0px;
	}
}

.player-selector {
	//flex: 0 30px;
	color: white;

	// position: relative;
	// left: -48%;
}

.player-selectors {
	position: relative;
	display: flex;
}

.cc-view-wrapper {
	padding-bottom: 40px;
}
.player-cc-view {
	height: var(--cc-view-height);
}

.image {
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
}
// .splide__slide.is-active {
// 	cursor: pointer;
// 	opacity: 0.7;
// }

// .image {
// 	position: relative;
// 	width: 100%; /* for IE 6 */
// }

// h2 {
//    position: absolute;
//    top: 20px;
//    left: 0;
//    width: 100%;
//    font-size: 1vw;
// }

// h2 span {
//    color: white;
//    font: bold 24px/45px Helvetica, Sans-Serif;
//    letter-spacing: -1px;
//    background: rgb(0, 0, 0); /* fallback color */
//    background: rgba(0, 0, 0, 0.7);
//    padding: 10px;
//    font-size: 1vw;
// }
// h2 span.spacer {
//    padding:0 5px;
// }
</style>
