<template>
	<div class="conversation-members-section">
		<section-header title="In This Room">
			<template v-if="this.closeable" v-slot:right>
				<a-icon type="close" v-on:click="clickedClose()" />
			</template>
		</section-header>
		<div v-if="this.userIds && this.userIds.length" class="conversation-member-list">
			<div v-for="(userId, index) in this.userIds" class="conversation-member-wrapper" :key="index">
				<user-cell :userId="userId" @video-request="onOpenRequestPrompt" :videoEnabled="config.videoEnabled" />
			</div>
		</div>
		<div v-else class="loading-spinner-wrapper">
			<loading-spinner />
		</div>

		<b-modal
			id="video-chat"
			size="xl"
			hide-footer
			no-close-on-backdrop
			no-close-on-esc
			centered
			v-on:hide="chatModalHidden()"
			v-on:show="chatModalShow()"
		>
			<template #modal-header="{ close }">
				<b-button size="sm" variant="outline" @click="close()">
					Close Video Chat
				</b-button>
			</template>
			<div class="player-wrapper">
				<jitsi-video-chat
					v-bind:config="jitsiServiceConfig"
					v-bind:jwt="connectionDetails.jwt"
					v-bind:roomName="connectionDetails.roomName"
					v-on:exit="finishConnection"
				/>
			</div>
		</b-modal>

		<b-modal id="video-chat-request" size="md" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
			<h3>Request video call with {{ this.getUserName(this.videoContactId) }}?</h3>
			<div class="button-ctn">
				<b-button variant="outline-secondary" @click="this.onStartCall">Yes</b-button>
				<b-button variant="outline-secondary" @click="onCancelCall">No</b-button>
			</div>
		</b-modal>

		<b-modal id="video-chat-incoming-request" size="md" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered>
			<h3>
				<span v-if="currentRequest"
					>{{ this.getUserName(currentRequest.hostId) }} wants to begin a video chat with you. Accept request?</span
				>
			</h3>
			<div class="button-ctn">
				<b-button variant="outline-secondary" type="button" v-on:click="acceptInviteToConnect(currentRequest)" value="Accept">
					Accept
				</b-button>

				<b-button variant="outline-secondary" type="button" v-on:click="declineInviteToConnect(currentRequest)" value="Decline">
					Decline
				</b-button>
			</div>
		</b-modal>

		<b-modal id="video-chat-waiting" class="video-chat-waiting" size="sm" hide-footer hide-header centered>
			<loading-spinner />
		</b-modal>
	</div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";

import SectionHeader from "./SectionHeader.vue";

import UserCell from "./UserCell.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import JitsiVideoChat from '@/components/videochat/JitsiVideoChat.vue';
import { log } from "@/logging";

const myLoggingName = "MembersSection";

export default Vue.extend({
	components: {JitsiVideoChat, SectionHeader, UserCell, LoadingSpinner},
	inject: [ "videoChatService", "textChatService" ],
	name: "MembersSection",
	props: {
		closeable: {
			type: Boolean,
			default: false
		},
		config: {
			type: Object
		},
		analyticsContext: Object
	},
	data: function () {
		// TODO test whether this is reactive to changes
		return {
			videoContactId: "",
			chatService: this.textChatService,
			localReactiveCopyOfVideoChatService: this.videoChatService,
			jitsiServiceConfig: {}, 
			requests: [],
			state: "idle",
			connectionDetails: {},
			videoChatUsers: [],
			request: null,
			myAnalyticsContext: {}
		};
	},
	computed: {
		conversationId() {
			return this.$store.state.kingEventsChat.selectedConversationId;
		},
		conversation() {
			return this.chatService.conversationDict[this.conversationId];
		},
		userIds() {
			// Only return ids of those that are in the specified conversation and are present in the desired locations
			const conversationUsers = this.conversation?.userIds;
			if (conversationUsers == undefined) {
				return undefined;
			} else {
				const checkForPresenceIn = this.config.checkForPresenceIn;
				const activeUsers = conversationUsers
					.map(i => this.chatService.userDict[i])
					.filter(i => i !== undefined)
					.filter(i => i.locations.filter(loc => checkForPresenceIn.find(j => j == loc)).length > 0);
				log(myLoggingName, "userIds", { conversationUsers: conversationUsers, activeUsers: activeUsers })
				return activeUsers.map(i => i.id);
			}
		},
		// TODO Deprecated - remove?
		isDoneLoading() {
			return this.chatService.isDoneLoading;
		},
		currentRequest: function () {
			if (this.requests.length == 0) {
				log(myLoggingName, "currentRequest length is 0");
			}
			return this.requests.length > 0 ? this.requests[0] : null;
		},
		currentUserName: function () {
			return this.chatService?.name;
		}


	},
	watch: {
	},
	mounted() {
		this.localReactiveCopyOfVideoChatService.setListener(this.handleEvent);
		this.transitToIdle();
		// Remove connect call when video chat properly 'hosted" in KingEventsChat?
		//this.connectToVideoChatServer();
		let captureVue = this;
		// this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
		// 	log(myLoggingName, "mounted Modal is about to be hidden (modalId, state):", { modalId: modalId, state: captureVue.state })
		// 	if (captureVue.state === "connected" && modalId === "video-chat") {
		// 		this.finishConnection();
		// 	}
		// })
		if (this.config.videoEnabled) {
			this.localReactiveCopyOfVideoChatService.config().then(c=> this.jitsiServiceConfig = c["serviceConfig"]);
		}
		this.myAnalyticsContext = this.analyticsContext?.addChild("videochat");

	},
	beforeDestroy() {
		// Remove when video chat properly 'hosted" in KingEventsChat?
		//this.videoChatService.disconnect();
		this.localReactiveCopyOfVideoChatService.deleteListener();
	},
	methods: {
		getUserName(id) {
			if (!id) return ""

			return this.chatService?.userDict[id]?.name || "";
		},
		chatModalHidden() {
		 	log(myLoggingName, "chat Modal is about to be hidden state:", { state: this.state })

			if (this.state === "connected") {
				this.finishConnection();
			}
			this.myAnalyticsContext.egress("closed");			
		},
		chatModalShow() {
		 	log(myLoggingName, "chat Modal is about to be hidden state:", { state: this.state })
			this.myAnalyticsContext?.ingress(new Map());			
		},

		clickedClose() {
			this.$store.commit("kingEventsChat/setIsMembersDrawerOpen", false);
		},
		onStartVideo() {
			this.$bvModal.hide('video-chat-waiting');
			this.$bvModal.show("video-chat");

		},
		onOpenRequestPrompt(value) {
			this.videoContactId = value
		    log(myLoggingName, "onOpenRequestPrompt", this.userIds)
			this.$bvModal.show("video-chat-request");
		},
		onStartCall() {
			this.inviteConnectById(this.videoContactId)
			this.$bvModal.hide("video-chat-request");
			this.$bvModal.show('video-chat-waiting');
		},
		onCancelCall() {
			this.$bvModal.hide("video-chat-request");
			this.videoContactId = "";
		},
		// VIDEO STUFF
		getAvailableUsers() {
			// May have to use computed or watcher. May also use other source of users?
			return this.localReactiveCopyOfVideoChatService.getOtherUsers();
		},
		inviteConnectById(id) {
			log(myLoggingName, 'inviteConnectById', id);
			this.state = "connecting";
			this.localReactiveCopyOfVideoChatService.inviteToConnect([id]);
		},
		inviteToConnect(user) {
			this.state = "connecting";
			this.localReactiveCopyOfVideoChatService.inviteToConnect([user.id]);
		},
		transitToIdle() {
			log(myLoggingName, "transitToIdle")
			this.$bvModal.hide("video-chat-request");
			this.$bvModal.hide('video-chat-waiting');
			this.$bvModal.hide("video-chat");
			this.state = "idle";
			this.connectionDetails = {};
		},
		transitToConnected(connectionDetails) {
			this.requests = [];
			this.connectionDetails = connectionDetails;
			this.state = "connected";
			this.onStartVideo();
		},
		acceptInviteToConnect(invite) {
			this.$bvModal.hide('video-chat-incoming-request');

			if (invite !== this.currentRequest) {
				log(myLoggingName, "acceptInviteToConnect mismatch", { invite: invite, currentRequest: this.currentRequest });
			} else {
				log(myLoggingName, "acceptInviteToConnect", invite);
			}
			this.transitToConnected({jwt: invite.authenticationToken, roomName: invite.sessionId});
		},
		openIncomingRequest() {

			this.$bvModal.show('video-chat-incoming-request');
		},
		joinExisting() {

			this.transitToConnected(this.connectionDetails);
		},
		declineInviteToConnect(invite) {
			this.$bvModal.hide('video-chat-incoming-request');
			this.requests = this.requests.filter(request => request.hostId !== invite.hostId);
		},
		finishConnection() {
			log(myLoggingName, "finishConnection called");
			this.transitToIdle();
		},
		handleEvent(event) {
			log(myLoggingName, "handleEvent", {  state: this.state, event: event });
			if (event.type === "VideoChatRequest") {
				switch (this.state) {

					case "connecting":
						log(myLoggingName, "handleEvent connecting handler");
						if (event.data.hostId === this.localReactiveCopyOfVideoChatService.currentUserId) {
							this.transitToConnected({
								jwt: event.data.authenticationToken,
								roomName: event.data.sessionId
							});
						} else {
							this.request = event.data;
							this.requests.push(event.data);
							this.openIncomingRequest();
						}
						break;
					case "connected":
						log(myLoggingName, "handleEvent connected handler");
						break;
					default:
						log(myLoggingName, "handleEvent default handler");
						if (event.data.hostId !== this.localReactiveCopyOfVideoChatService.currentUserId) {
							log(myLoggingName, "handleEvent default handler - processing");
							this.requests.push(event.data);
							this.request = event.data;
							this.openIncomingRequest();
						}
				}
			} else if (event.type === "UsersUpdated") {
				this.videoChatUsers = this.getAvailableUsers();
			} else if (event.type === "ErrorMessage") {
				switch (this.state) {
					case "connecting":
						log(myLoggingName, "handleEvent connecting handler");
						this.transitToIdle();
						break;
					default:
						log(myLoggingName, "handleEvent default handler");
				}
			} else if (event.type === "ConnectionClosed") {
				switch (this.state) {
					case "connected":
						// do nothing, auto reconnect
						log(myLoggingName, "handleEvent connected handler");
						break;
					case "connecting":
						// transit to idle as response may never arrive - may change this once video chat service supports stateful request management across connections.
						log(myLoggingName, "handleEvent connecting handler");
						this.transitToIdle();
						break;
					default:
						log(myLoggingName, "handleEvent default handler");
				}

			} else {
				log(myLoggingName, "handleEvent Unsupported event type. Doing nothing");
			}
		}
	}
});
</script>

<style lang="scss" scoped>
.conversation-members-section {
	min-width: 240px;
	height: 100%;

	display: grid;
	grid-template-rows: auto minmax(0, 1fr);
	grid-template-columns: 1fr;

	background-color: #e3e3e3;

	.conversation-member-list {
		flex-grow: 1;
		overflow-y: auto;
	}
}

.loading-spinner-wrapper {
	flex-grow: 1;
	//overflow-y: auto;
}


.conversation-member-wrapper {
	cursor: default;
	transition: background-color 0.4s;
}

.modal-content {
	h3 {
		margin-bottom: 20px;
		text-align: center;
	}
}

#video-chat {
	.modal-dialog {
		@media (min-width: 576px) {
			max-width: 95%;
		}
		@media (min-width: 992px) {
			max-width: 95%;
			.modal-body {
				min-height: 620px;
			}
		}
		@media (min-width: 1200px) {
			max-width: 1140px;
			.modal-body {
				min-height: 620px;
			}
		}
	}

	.modal-content {
		@media (max-width: 991px) {
			height: 95vh;
			.player-wrapper {
				#jitsi-chat {
					height: 75vh;
				}
			}
		}
	}
}

.modal-content {
	padding: 20px;
}

#video-chat-incoming-request,
#video-chat-request {
	.modal-content {
		.button-ctn {
			display: flex;
			justify-content: center;
		}

		button {
			margin: 0 10px;
			min-width: 150px;
			border-radius: 5px;
		}
	}
}

#video-chat-waiting {
	//background-color: #ff0000;
	.modal-content {
		background-color: transparent;
		border: none;
	}
}

.player-wrapper {
	position: relative;
	$player-width-percent: 100%;
	width: $player-width-percent;
	height: 0px;
	padding-bottom: calc(#{$player-width-percent} * (9 / 16)); // padding MUST be equal to width: forces 16:9 aspect ratio
}
</style>
