<template>
	<div class="general-page content-wrapper">
		<!-- <div class="page-contents">
			<div class="content-wrapper"> -->
		<iframe id="iframe" width="100%" height="100%" v-bind="config.iframe" v-bind:src="iframeUrl()"> </iframe>
		<!-- </div>
		</div> -->
	</div>
</template>
<script>
// import { log } from "@/logging";

// const myLoggingName = "VideoChat";

export default {
	name: "IframeModalPage",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	inject: ["authService", "configService"],

	mounted() {},
	beforeDestroy() {},
	methods: {
		iframeUrl() {
			let url = new URL(this.config.iframe.src);
			if (this.config.addAccessToken && this.authService.user?.signInUserSession?.refreshToken?.token) {
				url.searchParams.append("token", this.authService.user?.signInUserSession?.refreshToken?.token);
			}
			if (this.config.addHostToken) {
				url.searchParams.append("host", this.configService.hostName);
			}
			return url.toString();
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.content-wrapper {
	background: black;
	position: fixed;
	left: -2px;
	right: -2px;
	bottom: -2px;
	width: 101%;
}
</style>
