<template>
	<div class="passwordless-self-register-component">
		<div class="passwordless-self-register-wrapper">
			<form id="passwordlessSelfRegisterForm">
				<div class="title">
					{{
						text.getByKey("title", {
							default: "Register",
							en: "Register",
							fr: "Enregistrer"
						})
					}}
				</div>
				<br />
				<div class="supply-credentials" v-show="activeComponent === 'Credentials'">
					<div class="instructions">
						<strong>
							{{
								text.getByKey("instructions", {
									default: "Please start by entering your email address.",
									en: "Please start by entering your email address.",
									fr: "Veuillez commencer par saisir votre adresse de courriel."
								})
							}}
						</strong>
					</div>
					<br />
					<div class="form-group">
						<input
							class="form-control"
							type="email"
							autocomplete="username"
							id="emailInputRegister"
							v-bind:placeholder="
								text.getByKey('userName', {
									default: 'Email',
									en: 'Email',
									fr: 'E-mail'
								})
							"
							required
							v-model="userName"
						/>
						<slot name="userNameError">
							<div class="form-error" v-show="userNameError">{{ text.get(userNameError) }}</div>
						</slot>
					</div>

					<b-button type="button" v-on:click="gatheredCredentials" class="btn mr-1" variant="primary" value="Next">
						{{ nextButtonText }}
					</b-button>
				</div>

				<div class="supply-attributes" v-show="activeComponent === 'Attributes'">
					<user-attributes
						ref="attributes"
						v-bind:config="config.attributes"
						v-bind:common="common"
						v-bind:userData="userData"
						:text="text.shiftContext('userAttributes')"
					>
					</user-attributes>
					<div>
						<b-button type="button" class="btn mr-1" v-on:click="register" variant="primary" value="Register">
							{{
								text.getByKey("registerButtonText", {
									default: "Register",
									en: "Register",
									fr: "Enregistrer"
								})
							}}
						</b-button>
						<b-button type="button" v-on:click="back" class="btn mr-1" variant="secondary" value="Back">
							{{
								text.getByKey("backButtonText", {
									default: "Back",
									en: "Back",
									fr: "Retour"
								})
							}}
						</b-button>
					</div>
					<br />
					<need-help v-bind:common="common" :text="text.shiftContext('needHelp')" />
					<!-- <div id="signinHelp" class="instructions">
				Need help? Please contact
				<b-link v-bind:href="supportEmail">{{ supportHost }}</b-link>

				</div> -->
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import UserAttributes from "./userattribute/UserAttributes";
import NeedHelp from "./NeedHelp";
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			userName: "",
			activeComponent: "Credentials",
			userNameError: ""
		};
	},
	props: {
		config: Object,
		common: Object,
		text: Object
	},

	components: { UserAttributes, NeedHelp },
	name: "PasswordlessSelfRegister",
	//inject: ["languageService"],
	computed: {
		userData() {
			return { userName: this.userName };
		},
		nextButtonText() {
			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				return this.text.getByKey("nextButtonText", {
					default: "Next",
					en: "Next",
					fr: "Poursuivre"
				});
			} else {
				return this.text.getByKey("registerButtonText", {
					default: "Register",
					en: "Register",
					fr: "Enregistrer"
				});
			}
		}
		// 		supportEmail() {
		// 	return "mailto:" + (this.common.supportEmail ?? "support@kingevents.ca");
		// },
		// supportHost() {
		// 	return this.common.supportEmail ?? "support@kingevents.ca";
		// }
	},
	mounted() {
		this.activeComponent = "Credentials";
	},
	beforeUpdate() {
		// Likely unnecessary sanity check

		if (this.activeComponent !== "Credentials" && this.activeComponent !== "Attributes") {
			this.activeComponent = "Credentials";
		}
	},

	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after submit so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		}
	},

	methods: {
		initialState: function() {
			this.activeComponent = "Credentials";
		},
		back() {
			this.activeComponent = "Credentials";
			return;
		},
		gatheredCredentials() {
			this.userNameError = "";
			if (!this.userName || this.userName.length == 0) {
				this.userNameError = this.text.getByKey("userNameError", {
					default: "Please provide an email address",
					en: "Please provide an email address",
					fr: "Veuillez fournir une adresse de courriel"
				});
				this.activeComponent = "Credentials";
				return;
			}

			if (this.config.loginPasswordlessIfExists) {
				this.$emit("loginIfExists", { userName: this.userName });
			}
			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				this.activeComponent = "Attributes";
			} else {
				this.register();
			}
		},
		register() {
			let transformedAttributes = {};
			if (this.config.attributes?.fields && this.config.attributes.fields.length > 0) {
				if (!this.$refs.attributes) {
					return;
				}
				const attributes = this.$refs.attributes.transformAndValidate();
				if (!attributes) {
					return;
				}
				transformedAttributes = attributes.attributes;
			}
			this.$emit("input", { userName: this.userName, attributes: transformedAttributes });
			//this.activeComponent = "Credentials";
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.passwordless-self-register-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;

	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.passwordless-self-register-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
