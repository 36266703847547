import { Auth } from "aws-amplify";
import { log } from "@/logging";
import { formatString } from "@/stringutilities";
import { fromCognitoIdentityPool as FromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

const myLoggingName = "Auth";

const identityProviderFormat = "cognito-idp.{region}.amazonaws.com/{providerId}";

/**
 * Authentication service that manages the state of autentication sessions and provides methods for managing
 * credentials and dealing with authentication workflows
 *
 * Acts as an abstraction wrapper and client to external authentication service, currently Cognito, with enough
 * abstraction that minimal changes to the code that uses this service should required if the external service changes.
 *
 * Session state is stored in local storage. The login and methods set the user and token. The logout method clears them.
 * All other methods may or may not access the user and token in a readonly manner.
 *
 * See https://docs.amplify.aws/lib/auth/getting-started/q/platform/js
 *
 */
export class AuthService {
	constructor() {}

	/**
	 * The current jwt version of the idToken
	 */
	token = null;
	user = null;
	authConfig = null;
	forceAnonymous = false;

	/**
	 * @param {} authConfig
	 */
	init(authConfig, forceAnonymous) {
		this.authConfig = authConfig;
		let cognitoConfig = authConfig.serviceProviderConfig.configuration;
		this.forceAnonymous = forceAnonymous;
		Auth.configure(cognitoConfig);
	}

	isAnonymousMode() {
		return (
			this.forceAnonymous ||
			!this.authConfig ||
			!this.authConfig.serviceProviderConfig.configuration.Auth ||
			!this.authConfig.serviceProviderConfig.configuration.Auth.userPoolWebClientId
		);
	}

	isPasswordlessMode() {
		return this.authConfig.isPasswordless;
	}

	stubPassword() {
		if (this.isPasswordlessMode()) {
			return this.authConfig.stubPassword;
		}
		return null;
	}

	authed() {
		if (this.isAnonymousMode()) {
			return true;
		}
		try {
			this.user.signInUserSession.idToken.jwtToken;
			log(myLoggingName, "authed has token ");
			return true;
		} catch (err) {
			log(myLoggingName, "authed has no token", err);
			return false;
		}
	}

	validatePasswords(password1, password2) {
		let password1Error = [];
		let password2Error = null;
		if (password1 !== password2) {
			// password2Error = "New passwords do not match";
			password2Error = { key: "passwordsDoNotMatch", values: {} };
		}
		password1Error = this.validatePassword1(password1);
		return {
			password1Error,
			password2Error
		};
	}

	validatePassword(password, rule) {
		if (rule.type === "minLength") {
			if (password.length < rule.minRequired) {
				return false;
			}
			return true;
		}

		let count = 0;
		for (var counter = 0; counter < password.length; counter++) {
			const character = password.charAt(counter);
			switch (rule.type) {
				case "minRequiredLower":
					if (character == character.toLowerCase() && isNaN(character)) {
						count++;
					}
					break;
				case "minRequiredUpper":
					if (character == character.toUpperCase() && isNaN(character)) {
						count++;
					}
					break;
				case "minRequiredNumber":
					if (!isNaN(character)) {
						count++;
					}
					break;
				case "minRequiredInList":
					if (rule.required.includes(character)) {
						count++;
					}
					break;
				default:
					log(myLoggingName, "unsupported rule type", rule.type);
			}
		}
		if (count < rule.minRequired) {
			return false;
		}
		return true;
	}
	formatErrorMessage(rule) {
		let message = {};
		switch (rule.type) {
			case "minLength":
			case "minRequiredLower":
			case "minRequiredUpper":
			case "minRequiredNumber":
			case "minRequiredInList":
				//message = formatString(rule.messages.en, { required: rule.minRequired });
				message = { key: rule.type, value: { required: rule.minRequired } };
				break;
			default:
				message = { key: "unsupportedRuleType", value: {} };
				log(myLoggingName, "unsupported rule type", rule.type);
		}
		return message;
	}

	validatePassword1(password1) {
		const password1Error = [];

		for (const [key, value] of Object.entries(this.authConfig.passwordRules.rules)) {
			key;
			if (!this.validatePassword(password1, value)) {
				password1Error.push(this.formatErrorMessage(value));
				//password1Error.push({key: value.type, values: { value })
			}
		}
		return password1Error;
	}

	async auth() {
		this.user = null;
		this.token = null;
		if (this.isAnonymousMode()) {
			return true;
		}
		try {
			this.user = await Auth.currentAuthenticatedUser();
			this.token = this.user.signInUserSession.idToken.jwtToken;
			log(myLoggingName, "auth retrieved session");
			return true;
		} catch (err) {
			log(myLoggingName, "auth did not retrieve session", err);
			return false;
		}
	}

	/**
	 * Get the current jwtToken of the idToken, refreshing it first, if need be.
	 */
	async getToken() {
		if (this.isAnonymousMode()) {
			return null;
		}
		try {
			const session = await Auth.currentSession();
			this.token = session.idToken.jwtToken;
			return session.idToken.jwtToken;
		} catch (err) {
			log(myLoggingName, "getToken error " + err);
		}
	}

	async getIdentityPoolCredentials(token) {
		const identityProvider = formatString(identityProviderFormat, {
			region: this.authConfig.serviceProviderConfig.configuration.Auth.region,
			providerId: this.authConfig.serviceProviderConfig.configuration.Auth.userPoolId
		});
		const creds = new FromCognitoIdentityPool({
			client: new CognitoIdentityClient({
				region: this.authConfig.serviceProviderConfig.configuration.Auth.region
			}),
			identityPoolId: this.authConfig.serviceProviderConfig.identityPoolId,
			logins: {
				[identityProvider]: token
			}
		});
		return await creds();
	}

	/**
	 * Logs out current session and calls service to authenticate supplied credentials. Sets session state accordingly.
	 * Note that for some types of error, e.g. forced password changes, the user is set for use by followup methods in the
	 * workflows
	 * @param {*} userName
	 * @param {*} password
	 */
	async login(userName, password) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		await this.logout();
		let user = null;
		try {
			log(myLoggingName, "login called");
			user = await Auth.signIn(userName, password);
		} catch (err) {
			if (err.code && err.code === "PasswordResetRequiredException") {
				throw "PasswordResetRequiredException";
			} else if (err.code && err.code === "NotAuthorizedException") {
				throw "NotAuthorizedException";
			} else {
				log(myLoggingName, "login unknown error " + err);
				throw "UnkownError";
			}
		}
		if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
			this.user = user;
			log(myLoggingName, "login new password required. user set ");
			throw "NEW_PASSWORD_REQUIRED";
		} else {
			this.user = user;
			this.token = this.user.signInUserSession.idToken.jwtToken;
			log(myLoggingName, "login signin completed without exception. user and token set");
			return;
		}
	}

	// https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#forgotpassword
	async forgotPassword(userName, type) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		if (this.isPasswordlessMode()) {
			throw "PasswordlessMode";
		}
		try {
			log(myLoggingName, "forgotPassword called with " + userName);
			await Auth.forgotPassword(userName, { resetType: type });
			return;
		} catch (err) {
			log(myLoggingName, "forgotPassword error " + err);
			throw "UnkownError";
		}
	}

	async changePasswordWithCode(userName, code, password) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		if (this.isPasswordlessMode()) {
			throw "PasswordlessMode";
		}
		try {
			log(myLoggingName, "changePasswordWithCode called with " + userName);
			await Auth.forgotPasswordSubmit(userName, code, password);
			return;
		} catch (err) {
			log(myLoggingName, "changePasswordWithCode error " + err);
			throw "UnkownError";
		}
	}
	async changePassword(password) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		if (this.isPasswordlessMode()) {
			throw "PasswordlessMode";
		}
		// need to figure out errors and map them - for now return nothing
		//  e.g. need to change password
		// https://github.com/aws-amplify/amplify-js/blob/1795d461a5b6d2daee31d4e609d2d27fb5d37018/packages/aws-amplify-vue/src/components/authenticator/RequireNewPassword.vue
		// and
		// https://github.com/aws-amplify/amplify-js/blob/a047ce73abe98c3bf82e888c3afb4d2f911805f3/packages/aws-amplify-vue/src/components/authenticator/SignIn.vue
		try {
			log(myLoggingName, "changePassword called");
			const result = await Auth.completeNewPassword(this.user, password, {});
			this.user = result;
		} catch (err) {
			log(myLoggingName, "changePassword error " + err);
			throw "UnkownError";
		}
	}

	async signUp(username, attributes, password) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		// https://github.com/aws-amplify/amplify-js/blob/a047ce73abe98c3bf82e888c3afb4d2f911805f3/packages/aws-amplify-vue/src/components/authenticator/SignIn.vue
		try {
			log(myLoggingName, "signup called");
			let user = {
				attributes: {}
			};
			user.username = username;
			user.password = password;
			user.attributes = attributes;
			log(myLoggingName, "signUp  ", user.attributes, username);
			const result = await Auth.signUp(user);
			this.user = result.User;
			return result.userConfirmed;
		} catch (err) {
			log(myLoggingName, "signUp error ", err);
			if (err.name === "UsernameExistsException") {
				throw "UsernameExistsException";
			}
			throw "UnkownError";
		}
	}

	async passwordlessSignup(username, attributes) {
		await this.signUp(username, attributes, this.authConfig.stubPassword);
	}

	async confirmSignUp(username, code) {
		try {
			await Auth.confirmSignUp(username, code);
		} catch (err) {
			log(myLoggingName, "confirmSignUp error ", err);
			throw err;
		}
	}

	async passwordlessLogin(username) {
		if (this.isAnonymousMode()) {
			throw "AnonymousMode";
		}
		if (!this.isPasswordlessMode()) {
			throw "NotPasswordless";
		}
		const password = this.authConfig.stubPassword;
		//let loggedIn = false;
		try {
			await this.login(username, password);
			//	loggedIn = true;
		} catch (err) {
			log(myLoggingName, "passwordlessLogin, not authorized.", err);
			if (err !== "NotAuthorizedException") {
				throw "UnexpectedError";
			}
			return false;
		}
		// if (!loggedIn) {
		// 	await this.signUp(username, { email: username }, password);
		// 	await this.login(username, password);
		// }
		return true;
	}

	async logout() {
		if (this.isAnonymousMode()) {
			this.user = null;
			this.token = null;
			return true;
		}
		try {
			log(myLoggingName, "logout");
			this.user = null;
			this.token = null;
			await Auth.signOut();
			return true;
		} catch (e) {
			log(myLoggingName, "logout error", e);
			return false;
		}
	}
}

export const authService = new AuthService();
