<template>
	<!-- span -->
	<span v-if="languages && languages.length > 1" id="languagepicker">
		<span>
			<!-- <span> {{ selectedLanguage.name }} </span> -->
			<!-- style="font-size:large" -->
			<b-dropdown
				v-b-tooltip.hover.left="
					text.getByKey('toolTip', {
						default: 'Choose alternate language',
						en: 'Choose alternate language',
						fr: 'Choisissez une autre langue'
					})
				"
				:text="selectedLanguage.name"
				variant="link"
				right
			>
				<b-dropdown-item-button v-for="(value, index) in languages" v-bind:key="index" v-on:click="selectLanguage(value)">
					<span v-if="value.name === selectedLanguage.name">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							class="bi bi-check"
							viewBox="0 0 16 16"
						>
							<path
								d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
							/>
						</svg>
					</span>
					<span v-else><b-icon type="blank" aria-hidden="true"></b-icon></span>
					{{ value.name }}
				</b-dropdown-item-button>
			</b-dropdown>
		</span>
	</span>
	<!-- /span -->
</template>

<script>
import { BIcon } from "bootstrap-vue";
//import { formatString } from "@/stringutilities";
import { log } from "@/logging";

const myLoggingName = "LanguagePicker";

export default {
	name: "LanguagePicker",
	props: {
		languageOverride: null,
		text: Object
	},
	inject: ["languageService"],
	data: () => ({}),
	components: { BIcon },
	computed: {
		languages() {
			// key - how the browser identifies it
			// name
			return this.languageService.languages();
		},
		selectedLanguage() {
			//return this.languageService.getSelectedLanguage();
			return this.$store.state.kingEventsLanguage.language;
		}
	},
	methods: {
		selectLanguage(selectedKey) {
			log(myLoggingName, "player selected", selectedKey);
			if (selectedKey?.key) {
				this.languageService.setSelectedLanguage(selectedKey.key);
			}
		}
	},
	mounted() {
		//let capturedVue = this;
		this.selectLanguage(this.languageOverride);
	}
};
</script>
<style lang="scss">
#languagepicker {
	button:hover {
		color: var(--language-picker-hover-color);
	}
	button {
		text-decoration: none;
		color: var(--language-picker-color);
	}
}
</style>
<style scoped lang="scss">
@import "../scss/constants";
// https://stackoverflow.com/questions/51599545/how-can-i-change-variant-of-bootstrap-vue-button-style-checkboxes-when-clicked/51601312
// .my-buttons .active {
//     color: #fff !important;
//     background-color: #28a745 !important;
//     border-color: #28a745 !important;
//   }
</style>
