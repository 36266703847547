<template>
	<!-- <div> -->
	<div class="captionsContainer" :style="applyStyleVariables('captionsContainer')">
		<div
			ref="captionsArea"
			class="innerCaptionsContainer"
			aria-live="polite"
			aria-atomic="false"
			aria-relevant="additions"
			:style="applyStyleVariables('innerCaptionsContainer')"
		>
			<span v-for="word in words" :key="word.data.id" class="caption" :style="applyStyleVariables('caption')">{{
				word.data.text
			}}</span>
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
import { log } from "@/logging";
const logName = "CaptionsVue";
export default {
	name: "Captions",
	props: {
		config: Object
	},
	inject: ["captionsService"],
	computed: {
		words() {
			return this.$store.state.kingEventsCaptions.captions;
		}
	},
	watch: {
		words() {
			// scroll to bottom when a new caption arrives
			const element = this.$refs.captionsArea;

			if (element.lastElementChild) {
				this.$nextTick(() => {
					element.lastElementChild.scrollIntoView({ block: "end", scrollBehavior: "smooth" });
				});
			}
		},
		config(newConfig, oldConfig) {
			log(logName, "configChanged", { old: oldConfig, new: newConfig });
			this.captionsService.disconnect();
			this.captionsService.startWebSocket(newConfig.url, newConfig.lang);
		}
	},
	mounted() {
		log(logName, "mounted");
		this.captionsService.startWebSocket(this.config.url, this.config.lang);
	},
	destroyed() {
		log(logName, "destroyed");
		this.captionsService.disconnect();
	},
	methods: {
		applyStyleVariables(element) {
			const styleVars = {};
			const style = this.config?.style;
			let override = null;
			if (style) override = style[element];
			if (override) {
				Object.keys(override).forEach(key => {
					//					styleVars["--" + key] = override[key];
					styleVars[key] = override[key];
				});
			}
			if (override && element === "caption") {
				const overrides = this.config.style.captionAnimation;
				const backgroundColour = overrides?.unveilBackgroundColour ?? "red";
				styleVars["--unveil-background-color"] = backgroundColour;
				const delay = overrides?.delay ?? "10s";
				styleVars["--unveil-delay"] = delay;
			}
			return styleVars;
		}
	}
};
</script>
<style scoped lang="scss">
@import "../../scss/constants";
.captionsContainer {
	border: 1px solid black;
	overflow-clip-margin: content-box !important;
	border-width: 2px;
	border-style: inset;
	border-color: initial;
	background-color: black;
	font-family: Helvetica;
	font-size: 12pt;
	color: white;
	overflow: auto;
	height: 135px;
}
.innerCaptionsContainer {
}
.caption {
	animation-duration: var(--unveil-delay);
	animation-name: newcaptionunveil;
	width: auto;
	margin: 0 auto;
	float: left;
	padding: 0 0.5em 0 0;
	text-align: left;
}

@keyframes newcaptionunveil {
	from {
		visibility: hidden;
	}

	89% {
		visibility: hidden;
	}
	90% {
		visibility: visible;
		background-color: var(-unveil-background-color);
	}
	to {
		visibility: visible;
	}
}
</style>
