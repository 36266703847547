<template>
	<span class="pending-task-component">
		<span v-if="pendingType === 'splash'" class="bg-holder">
			<div class="overlay">
				<img class="bg" v-bind:src="imageUrl" />
			</div>
			<div class="splash-wrapper">
				<div v-if="pendingComponentState === 'awaitingTask'" class="splash-item">
					Loading Venue Hall. Please wait.
				</div>
				<div v-else-if="pendingComponentState === 'awaitingUser'" class="splash-item active-item" v-on:click="splashComplete">
					Welcome to the Venue Hall. Please click to enter.
				</div>
				<div v-else></div>
			</div>
		</span>
		<span v-else-if="pendingType === 'spinner'">
			<span v-if="pendingComponentState === 'awaitingTask'">
				<b-spinner variant="primary" label="Loading" style="position: absolute; top: calc(50%)"></b-spinner>
			</span>
			<div v-else-if="pendingComponentState === 'awaitingUser'" style="display: flex; justify-content: center;">
				<div class="splash-wrapper">
					<div class="splash-item active-item" v-on:click="splashComplete">
						Welcome to the Venue Hall. Please click to enter.
					</div>
				</div>
			</div>
			<div v-else></div>
		</span>
		<span v-else> </span>
	</span>
</template>

<script>
export default {
	props: {
		config: Object,
		isTaskComplete: Boolean
	},
	name: "PendingTask",
	data: () => {
		return {
			splashed: false
		};
	},
	computed: {
		pendingType() {
			return this.config?.type || "spinner";
		},
		imageUrl() {
			return this.config?.splashConfig.url;
		},
		isPendingComponentInAutoTransitMode() {
			if (!this.config || this.config.autoTransit === undefined) {
				return true;
			}
			return this.config.autoTransit;
		},
		pendingComponentState() {
			if (!this.isTaskComplete) {
				return "awaitingTask";
			}
			if (!this.isPendingComponentInAutoTransitMode && !this.splashed) {
				return "awaitingUser";
			}
			this.splashComplete();
			return "done";
		}
	},
	inject: [],
	methods: {
		splashComplete: function() {
			if (this.isTaskComplete) {
				this.$emit("splashComplete");
			}
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.splash-wrapper {
	min-width: 600px;
	min-height: 200px;
	display: block;
	position: absolute;
	top: calc(50%);
	padding: 50px;
}

.splash-item {
	min-width: 350px;
	background-color: rgba(11, 153, 235, 0.7);
	color: white;
	padding: 15px;
	margin: 10px;
	text-align: center;
}

.active-item {
	background-color: rgba(11, 153, 235, 0.877);
	cursor: pointer;
	&:hover {
		background-color: rgba(11, 153, 235, 0.993);
	}
}

@media (max-width: $screen-mobile-max) {
	.splash-wrapper {
		width: 100%;
		top: 65px;
		position: relative;
	}

	.splash-item {
		background-color: black;
	}
	.active-item {
		cursor: pointer;
	}
	.overlay {
		display: none;
	}
}
</style>
