<template>
	<div class="general-page" :style="applyStyleVariables()">
		<background :src="config.bg" :mobileSrc="config.mobileBg" v-on:click="turnPlayerOff()" />
		<div class="page-contents">
			<div class="list-wrapper">
				<div class="table-list">
					<template v-for="table in config.tables">
						<span v-if="!isValidModal(table)" class="table-item-wrapper" :key="table.id">
							<img
								v-if="table.img"
								:src="text.get(urlForImage(table))"
								class="table-image hoverable"
								v-on:click="clickHandler(table)"
								v-bind:alt="text.get(itemTitle(table))"
							/>
							<div
								v-else-if="!table.urlText"
								class="table-item hoverable"
								:style="applyStyleVariablesToIndividualTable(table)"
								v-on:click="clickHandler(table)"
								:aria-label="text.get(itemTitle(table))"
								role="link"
							>
								<div v-if="isTableTitleAnArray(table.title)">
									<div v-for="(title, index) in table.title" v-bind:key="index" class="table-title">
										{{ text.get(title) }}
									</div>
								</div>
								<div v-else-if="config.autoTitle">
									<div class="table-title">Table #{{ table.id.toString() }}</div>
								</div>
								<div v-else>
									<div class="table-title"></div>
								</div>
								<div v-for="(description, index) in table.descriptions" v-bind:key="index" class="table-description">
									{{ text.get(description) }}
								</div>
							</div>
							<div v-else class="table-item" :style="applyStyleVariablesToIndividualTable(table)">
								<div class="table-title">
									<div v-if="isTableTitleAnArray(table.title)">
										<div v-for="(title, index) in table.title" v-bind:key="index" class="table-title">
											{{ text.get(title) }}
										</div>
									</div>
									<div v-else-if="config.autoTitle">
										<div class="table-title">Table #{{ table.id.toString() }}</div>
									</div>
									<div v-else>
										<div class="table-title"></div>
									</div>
								</div>
								<!-- JM ked-197 this table description section had an extra DIV from all the other table description sections -->
								<div v-for="(description, index) in table.descriptions" v-bind:key="index" class="table-description">
									{{ text.get(description) }}
								</div>
								<div
									class="table-item-button hoverable"
									v-on:click="clickHandler(table)"
									:aria-label="text.get(itemTitle(table))"
									role="link"
								>
									{{ text.get(table.urlText) }}
								</div>
							</div>
						</span>
						<span v-else class="table-item-wrapper" :key="table.id">
							<img
								v-if="table.img"
								v-b-modal="'tablehall' + config.name + 'modal' + table.id"
								:src="text.get(urlForImage(table))"
								class="table-image hoverable"
								v-bind:alt="text.get(itemTitle(table))"
							/>
							<div
								v-else-if="!table.urlText"
								class="table-item hoverable"
								v-b-modal="'tablehall' + config.name + 'modal' + table.id"
								:style="applyStyleVariablesToIndividualTable(table)"
								:aria-label="text.get(itemTitle(table))"
								role="button"
							>
								<div v-if="isTableTitleAnArray(table.title)">
									<div v-for="(title, index) in table.title" v-bind:key="index" class="table-title">
										{{ text.get(title) }}
									</div>
								</div>
								<div v-else-if="config.autoTitle">
									<div class="table-title">Table #{{ table.id.toString() }}</div>
								</div>
								<div v-else>
									<div class="table-title"></div>
								</div>
								<div v-for="(description, index) in table.descriptions" v-bind:key="index" class="table-description">
									{{ text.get(description) }}
								</div>
							</div>
							<div v-else class="table-item" :style="applyStyleVariablesToIndividualTable(table)">
								<div class="table-title">
									<div v-if="isTableTitleAnArray(table.title)">
										<div v-for="(title, index) in table.title" v-bind:key="index" class="table-title">
											{{ text.get(title) }}
										</div>
									</div>
									<div v-else-if="config.autoTitle">
										<div class="table-title">Table #{{ table.id.toString() }}</div>
									</div>
									<div v-else>
										<div class="table-title"></div>
									</div>
								</div>
								<div v-for="(description, index) in table.descriptions" v-bind:key="index" class="table-description">
									{{ text.get(description) }}
								</div>
								<div
									class="table-item-button hoverable"
									v-b-modal="'tablehall' + config.name + 'modal' + table.id"
									:aria-label="text.get(itemTitle(table))"
									role="button"
								>
									{{ text.get(table.urlText) }}
								</div>
							</div>
							<b-modal
								v-if="!loadingS3Data"
								v-bind:id="'tablehall' + config.name + 'modal' + table.id"
								v-bind:size="modalSize(table.modal)"
								v-on:show="onModalShow(table)"
								v-on:hide="onModalHide(table)"
								hide-footer
							>
								<!-- <div>{{'HI!!!' + id}}</div> -->
								<img v-if="table.modal.type === 'image'" :src="text.get(urlForModalSrc(table))" />
								<video-js-player-wrapper
									v-else-if="table.modal.type === 'videojsplayer'"
									v-bind:config="configForJSPlayer(table)"
								></video-js-player-wrapper>
								<iframe
									v-else-if="table.modal.type === 'iframe'"
									id="iframe"
									width="100%"
									height="600px"
									v-bind:src="text.get(urlForModalSrc(table))"
								>
								</iframe>
								<iframe v-else id="iframe" width="100%" height="600px" v-bind:src="text.get(urlForModalSrc(table))">
								</iframe>
							</b-modal>
						</span>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
import { formatString } from "@/stringutilities";
import { log } from "@/logging";
import VideoJsPlayerWrapper from "@/components/VideoJsPlayerWrapper.vue";

/* eslint-disable no-useless-escape */
const myLoggingName = "TableHall";

export default {
	name: "TableHall",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	components: { Background, VideoJsPlayerWrapper },
	data: () => ({
		siteConfig: null,
		modalSrc: {},
		lastS3Retrieval: new Date(),
		loadingS3Data: false,
		analyticsContexts: {}
	}),
	inject: ["configService", "s3Utilities", "authService", "textService"],
	computed: {
		text() {
			return this.textService.copy(this.config.text, ["root", this.$route.name]);
		}
	},
	methods: {
		isValidModal(table) {
			let rc = table.modal && (table.modal.src || table.modal.type === "videojsplayer");
			return rc;
		},
		isTableTitleAnArray(title) {
			return title && Array.isArray(title);
		},
		itemTitle(table) {
			let rc = "";
			//check to see if the table.title exists AND is not an empty array string
			if (this.isTableTitleAnArray(table.title) && table.title.length > 0) {
				rc = table.title.join();
			} else if (this.config.autoTitle) {
				rc = "Table #" + table.id.toString();
			}
			return rc;
		},
		analyticsItemName(table) {
			let rc = "";
			//check to see if the table.analyticsChildName exists AND is not an empty string
			if (table.analyticsChildName && table.analyticsChildName.length > 0) {
				rc = table.analyticsChildName;
			} else if (this.isTableTitleAnArray(table.title) && table.title.length > 0) {
				rc = table.title.join();
			} else {
				rc = "Table #" + table.id.toString();
			}
			return rc;
		},
		clickHandler(table) {
			const url = table.url;
			if (url.internalName) {
				this.$router.replace({ name: url.internalName });
			} else {
				this.trackClick(table);
				window.open(this.text.get(url));
			}
		},
		urlForImage(item) {
			if (item.img?.url) {
				return item.img.url;
			}
			let url = "";
			if (this.siteConfig) {
				url =
					this.configService.addHostToUrl(this.siteConfig.common.staticResourceBaseUrlFormatTemplate) +
					formatString(this.siteConfig.common.staticResourceDefaultViewSegmentUrlFormatTemplate, {
						componentType: "tablehall"
					}) +
					`${this.config.name}itemimage${item.id}.png`;
			}
			return url;
		},
		// TODO create background task to refresh tokens so we don't have to wait for the request to complete when this method is called
		/**
		 * Asynchronously get credentials and authenticated S3 URls
		 */
		determineModalSrcs() {
			const capturedVue = this;
			const tables = this.config.tables;
			this.lastS3Retrieval = new Date();
			this.loadingS3Data = true;
			this.authService.getToken().then(async token => {
				if (token) {
					const credentials = await this.authService.getIdentityPoolCredentials(token);
					for (const tableKey in tables) {
						const table = tables[tableKey];
						const modal = table.modal;
						if (modal) {
							const src = modal.src;
							if (src && src.type === "S3") {
								capturedVue.modalSrc[table.id] = await this.s3Utilities.getObjectUrl(credentials, src.url, 3600);
								log(myLoggingName, "Added S3 modal URL", table.id, src, capturedVue.modalSrc[table.id]);
							}
						}
					}
				}
				capturedVue.lastS3Retrieval = new Date();
				capturedVue.loadingS3Data = false;
			});
		},
		urlForModalSrc(table) {
			// TODO s3 URLS might not be retrieved yet - deal with it.
			if (table.modal?.src?.type === "S3") {
				log(myLoggingName, "Retrieving S3 modal URL", table.id);
				return this?.modalSrc[table.id] ?? "";
			} else if (table.modal?.src?.type === "URL") {
				return table.modal.src.url;
			} else {
				return table.modal.src;
			}
		},
		configForJSPlayer(table) {
			return table.modal.playerParameters;
		},
		applyStyleVariables() {
			if (!this.config?.styleVariables) return {};
			const styleVars = {
				"--table-max-width": "200px",
				"--num-columns": 3,
				"--column-spacing": "20px",
				"--row-spacing": "20px",
				"--item-width": "180px",
				"--item-height": "180px",
				"--translate-x": "0px",
				"--translate-y": "0px",
				"--translate-small-x": "0px",
				"--translate-small-y": "0px",
				"--button-padding": "10px 30px",
				"--button-margin": "0px 20%"
			};
			Object.keys(this.config.styleVariables).forEach(key => {
				styleVars["--" + key] = this.config.styleVariables[key];
			});
			return styleVars;
		},
		applyStyleVariablesToIndividualTable(table) {
			if (!table?.styleVariables) return {};
			const styleVars = {};
			Object.keys(table.styleVariables).forEach(key => {
				styleVars["--" + key] = table.styleVariables[key];
			});
			return styleVars;
		},
		modalSize(tableConfig) {
			// eslint-disable-next-line prettier/prettier
			if (tableConfig?.size ) {
				return tableConfig.size;
			} else {
				return "lg";
			}
		},
		onModalShow(itemConfig) {
			const id = "tablehall" + this.config.name + "modal" + itemConfig.id;
			log(myLoggingName, "mounted Modal is about to be opened", { itemId: id });
			const context = this.analyticsContexts[id.toString()];
			context.ingress(new Map());
		},
		onModalHide(itemConfig) {
			const id = "tablehall" + this.config.name + "modal" + itemConfig.id;
			log(myLoggingName, "mounted Modal is about to be closed", { itemId: id });
			const context = this.analyticsContexts[id.toString()];
			context.egress("closed");
		},

		addChildrenToAnalytics() {
			const capturedVue = this;
			this.config.tables.forEach(table => {
				let name = capturedVue.analyticsItemName(table);
				if (name === "") {
					name = table.id.toString();
				}

				if (!this.isValidModal(table)) {
					const key = table.id.toString();
					capturedVue.analyticsContexts[key] = capturedVue.analyticsContext.addChild(name);
				} else {
					const id = "tablehall" + this.config.name + "modal" + table.id;
					capturedVue.analyticsContexts[id] = capturedVue.analyticsContext.addChild(name);
				}
			});
		},
		trackClick(room) {
			const roomAnalyitcsContext = this.analyticsContexts[room.id];
			const values = new Map();
			const event = roomAnalyitcsContext.makeEvent("clicked", "event", values);
			roomAnalyitcsContext.recordEvent(event);
		}
	},
	created() {
		this.determineModalSrcs();
	},
	mounted() {
		let capturedVue = this;
		this.configService.getSiteConfig().then(config => {
			capturedVue.siteConfig = config;
		});
		this.addChildrenToAnalytics();
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.list-wrapper {
	position: relative;
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table-list {
	position: relative;

	display: grid;
	grid-template-columns: repeat(var(--num-columns), 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: var(--column-spacing);
	grid-row-gap: var(--row-spacing);
	justify-items: center;

	transform: translateX(var(--translate-x)) translateY(var(--translate-y));
}

.table-item-wrapper {
	width: var(--item-width);
	height: var(--item-height);
	max-width: var(--table-max-width);
}

.table-item {
	width: 100%;
	//	max-width: var(--table-max-width);
	position: relative;
	padding: 10px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	background: linear-gradient(to top right, var(--primary-colour), var(--primary-gradient-colour));
	color: var(--primary-text-colour);

	border-radius: 4px;
	box-shadow: 1px 1px 3px 1px rgba($color: #000000, $alpha: 0.25);
}

.table-title {
	font-size: 20px;
	font-weight: 600;
	&:not(:only-child) {
		margin-bottom: 10px;
	}
}

.table-descriptions {
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.table-image {
	object-fit: contain;
	//	background-color: rgba(129, 137, 143, 0.2);
	width: var(--item-width);
	height: var(--item-height);
	border-radius: 2px;
}

.table-item-button {
	//margin-bottom: $breakout-item-padding;

	position: relative;
	padding: var(--button-padding);
	margin: var(--button-margin);
	box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.3);
	border-radius: 3px;

	background: linear-gradient(to top right, var(--secondary-colour), var(--secondary-gradient-colour));
	color: var(--secondary-text-colour);
}

@media (max-width: $screen-mobile-max) {
	.table-list {
		grid-template-columns: 1fr;
		transform: translateX(var(--translate-small-x)) translateY(var(--translate-small-y));
	}
}
</style>
