<template>
	<div id="timer" class="content-wrapper" v-bind:style="applyStyleVariables()">
		<div class="container">
			<div class="title-item">
				{{
					text.getByKey("title", {
						default: "Time Remaining",
						en: "Time Remaining",
						fr: "Temps Restant"
					})
				}}
			</div>
			<span class="time-item">{{ displayDays }}</span>
			<span class="time-item">{{ displayHours }}</span>
			<span class="time-item">{{ displayMinutes }}</span>
			<span class="time-item">{{ displaySeconds }} </span>
			<span class="unit-container">
				{{
					text.getByKey("days", {
						default: "days",
						en: "days",
						fr: "journées"
					})
				}}</span
			>
			<span class="unit-container">{{
				text.getByKey("hours", {
					default: "hours",
					en: "hours",
					fr: "heures"
				})
			}}</span>
			<span class="unit-container">{{
				text.getByKey("minutes", {
					default: "minutes",
					en: "minutes",
					fr: "minutes"
				})
			}}</span>
			<span class="unit-container">{{
				text.getByKey("seconds", {
					default: "seconds",
					en: "seconds",
					fr: "seconds"
				})
			}}</span>
		</div>
	</div>
</template>

<script>
// Another option - https://medium.com/js-dojo/how-to-create-an-animated-countdown-timer-with-vue-89738903823f - but it doesn't scale
const millisPerSecond = 1000;
const millisPerMinute = millisPerSecond * 60;
const millisPerHour = millisPerMinute * 60;
const millisPerDay = millisPerHour * 24;
const defaultStyle = {
	opacity: "1",
	"primary-text-colour": "white",
	"title-font-size": "1em",
	"value-font-size": "4vh",
	"unit-font-size": "1em",
	"row-gap": "2px",
	"column-gap": "15px"
};

export default {
	name: "CountDownTimer",
	props: {
		config: Object
	},
	data: () => ({
		displayDays: 0,
		displayHours: 0,
		displayMinutes: 0,
		displaySeconds: 0
		//	displayDateEnd: 0
	}),
	computed: {
		text() {
			return this.textService.copy(this.config.text);
		}
	},

	mounted() {
		this.setValues();
		this.showRemaining();
	},
	inject: ["textService"],
	methods: {
		applyStyleVariables() {
			const styleVars = {};
			Object.keys(defaultStyle).forEach(key => {
				styleVars["--" + key] = defaultStyle[key];
			});
			if (this.config?.styleVariables) {
				Object.keys(this.config.styleVariables).forEach(key => {
					styleVars["--" + key] = this.config.styleVariables[key];
				});
			}
			return styleVars;
		},
		setValues() {
			const now = new Date();
			const end = new Date(this.config.eventTime);
			const distance = end.getTime() - now.getTime();

			if (distance < 0) {
				this.displayMinutes = 0;
				this.displaySeconds = 0;
				this.displayHours = 0;
				this.displayDays = 0;
			} else {
				const days = Math.floor(distance / millisPerDay);
				const hours = Math.floor((distance % millisPerDay) / millisPerHour);
				const minutes = Math.floor((distance % millisPerHour) / millisPerMinute);
				const seconds = Math.floor((distance % millisPerMinute) / millisPerSecond);
				this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
				this.displaySeconds = seconds < 10 ? "0" + seconds : seconds;
				this.displayHours = hours < 10 ? "0" + hours : hours;
				this.displayDays = days < 10 ? "0" + days : days;
			}
			return distance;
		},
		/**
		 * Set up a task that calculates the different between the current time and the specified time, once per second, and updates the display based on that difference.
		 *
		 * If there current time is greater than the specified time, set the display to 0's and stop the tasks.
		 */
		showRemaining() {
			const timer = setInterval(() => {
				const distance = this.setValues();
				if (distance < 0) {
					clearInterval(timer);
				}
			}, 1000);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.content-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--primary-colour);
	color: var(--primary-text-colour);
	border-radius: 10px;
	opacity: var(--opacity);
	overflow: scroll;
}
.container {
	position: relative;
	display: grid;
	height: 100%;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 0.7fr;
	row-gap: var(--row-gap);
	column-gap: var(--column-gap);
	justify-items: center;
}
.title-item {
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 5;
	align-self: self-end;
	font-size: var(--title-font-size);
}
.time-item {
	grid-row-start: 2;
	grid-row-end: 3;
	align-self: center;
	font-size: var(--value-font-size);
}
.unit-container {
	color: var(--primary-text-colour);
	align-self: self-start;
	font-size: var(--unit-font-size);
}
</style>
