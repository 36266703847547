import { render, staticRenderFns } from "./MessagesSection.vue?vue&type=template&id=26f6c1ac&scoped=true&"
import script from "./MessagesSection.vue?vue&type=script&lang=ts&"
export * from "./MessagesSection.vue?vue&type=script&lang=ts&"
import style0 from "./MessagesSection.vue?vue&type=style&index=0&id=26f6c1ac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f6c1ac",
  null
  
)

export default component.exports