<template>
	<div>
		<div v-if="mounted && ccDisplayOrder && ccDisplayOrder.length > 1">
			<div>
				<b-dropdown
					v-b-tooltip.hover.right="
						text.getByKey('toolTip', {
							default: 'Choose closed captioning',
							en: 'Choose closed captioning',
							fr: 'Choisissez le sous-titrage'
						})
					"
					variant="outline"
				>
					<template #button-content>
						<!-- <b-icon icon="display"></b-icon> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							v-bind:fill="pickerIconColour"
							class="bi bi-badge-cc"
							viewBox="0 0 16 16"
						>
							<path
								d="M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z"
							/>
							<path
								d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"
							/>
						</svg>
					</template>
					<b-dropdown-item-button v-for="(value, index) in ccDisplayOrder" v-bind:key="index" v-on:click="selectCC(value)">
						<span v-if="value.name === selectedCC.name">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-check"
								viewBox="0 0 16 16"
							>
								<path
									d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
								/>
							</svg>
						</span>
						<span v-else><b-icon type="blank" aria-hidden="true"></b-icon></span>
						{{ text.get(value.name) }}
					</b-dropdown-item-button>
				</b-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
import { log } from "@/logging";
import { BIcon } from "bootstrap-vue";

const myLoggingName = "ClosedCaptionPicker";
const ccResourceKey = "CCEntry";
const ccKeyOff = "Off";
const ccNameOff = "Off";
const ccTypeOff = "Off";
export const ccOff = { type: ccTypeOff, key: ccKeyOff, name: ccNameOff };
const ccOffOrderEntry = { name: ccNameOff, key: ccKeyOff };
export default {
	name: "ClosedCaptionPicker",
	props: {
		config: Object,
		common: Object,
		resourceKeyRoot: String,
		text: Object
	},
	inject: ["authService", "protectedResource", "configService"],
	data: () => ({
		mounted: false,
		selectedCC: null,
		ccElements: [],
		ccDisplayOrder: [],
		pickerIconColour: "currentColor"
	}),
	components: { BIcon },
	methods: {
		selectCC(selectedItem) {
			log(myLoggingName, "cc selected", selectedItem);
			this.selectedCC = selectedItem;
			this.$emit(
				"selectCC",
				this.ccElements.find(e => e.key === this.selectedCC.key)
			);
		},
		determineFillColor() {
			if (!this.config?.ccConfig?.styleVariables) {
				const globalStyle = this.common.globalStyleVariables;
				if (globalStyle && globalStyle["primary-text-colour"]) {
					this.pickerIconColour = globalStyle["primary-text-colour"];
				}
			} else {
				if (this.config.ccConfig.styleVariables.pickerIconColour) {
					this.pickerIconColour = this.config.ccConfig.styleVariables.pickerIconColour;
				}
			}
		}
	},
	mounted() {
		this.determineFillColor();
		let capturedVue = this;
		const protectedResourceKey = this.resourceKeyRoot + capturedVue.config.name;
		this.authService
			.getToken()
			.then(async token => {
				return await this.protectedResource.getProtectedConfigurationData(token);
			})
			.then(data => {
				return data;
			})
			.then(data => {
				// Copy Cc configs from the passed in data that are referenced in the config ccDisplayOrder to the activeCCs and local copy of ccDisplayOrder.
				//
				let activeCCs = [];
				if (capturedVue.config.ccConfig?.ccDisplayOrder) {
					for (const orderEntry of capturedVue.config.ccConfig.ccDisplayOrder) {
						if (orderEntry.key === ccKeyOff) {
							log(myLoggingName, "mounted - Added the Off entry", orderEntry);
							let transformedCCOff = {
								type: ccOff.type,
								key: ccOff.key,
								name: orderEntry.name
							};
							capturedVue.ccElements.push(transformedCCOff);
							let transformedCCOffEntry = {
								name: orderEntry.name,
								key: ccOffOrderEntry.key
							};
							activeCCs.push(transformedCCOffEntry);
						} else {
							let ccConfig = {};

							// Get the cc configuration from the protected resource
							ccConfig.config = data[protectedResourceKey][ccResourceKey][orderEntry.key];
							if (ccConfig.config) {
								ccConfig.key = orderEntry.key;
								ccConfig.name = orderEntry.name;
								capturedVue.ccElements.push(ccConfig);
								log(myLoggingName, "mounted - added", orderEntry);
								activeCCs.push(orderEntry);
							} else {
								log(myLoggingName, "mounted - did not add", orderEntry);
							}
						}
					}
					capturedVue.ccDisplayOrder = activeCCs;
					capturedVue.mounted = true;
					if (activeCCs.length > 0) {
						capturedVue.selectCC(activeCCs[0]);
						log(myLoggingName, "mounted - assigned default selected CC ", capturedVue.selectedCC);
					} else {
						log(myLoggingName, "mounted - no active players for ", capturedVue.config.ccConfig.ccDisplayOrder);
					}
					if (activeCCs.length < capturedVue.config.ccConfig.ccDisplayOrder) {
						log(myLoggingName, "mounted - activeCCs does not match ordered ccs ", {
							active: activeCCs,
							ordered: capturedVue.config.ccConfig.ccDisplayOrder
						});
					}
				}
			});
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";
// https://stackoverflow.com/questions/51599545/how-can-i-change-variant-of-bootstrap-vue-button-style-checkboxes-when-clicked/51601312
// .my-buttons .active {
//     color: #fff !important;
//     background-color: #28a745 !important;
//     border-color: #28a745 !important;
//   }
</style>
