<template>
	<div class="admin-password-reset-component">
		<div class="admin-password-reset-wrapper">
			<form id="adminPasswordResetForm">
				<div class="title">Admin Password Reset</div>
				<br />
				<div class="instructions">
					{{
						text.getByKey("instructions", {
							default:
								"An administrator initiated a password reset for your account. Please enter your email address to reset your password.",
							en:
								"An administrator initiated a password reset for your account. Please enter your email address to reset your password.",
							fr:
								"Un administrateur a lancé une réinitialisation du mot de passe pour votre compte. Veuillez saisir votre adresse e-mail pour réinitialiser votre mot de passe."
						})
					}}
				</div>
				<br />
				<div class="form-group">
					<input
						class="form-control"
						type="email"
						autocomplete="username"
						id="emailInputAdminReset"
						placeholder="Email"
						v-model="userName"
						required
					/>
					<div class="form-error" v-show="userNameError">{{ userNameError }}</div>
				</div>
				<button
					type="button"
					v-on:click="requestPasswordReset"
					class="btn btn-outline-secondary userPasswordResetForm-submit"
					value="Start Password Reset"
				>
					{{
						text.getByKey("startPasswordReset", {
							default: "Start Password Reset",
							en: "Start Password Reset",
							fr: "Démarrer la réinitialisation du mot de passe"
						})
					}}
				</button>
				<br /><br />
				<button
					type="button"
					v-on:click="cancel"
					class="btn btn-outline-secondary userPasswordResetForm-cancel"
					value="Cancel Request"
				>
					{{
						text.getByKey("cancelRequest", {
							default: "Back",
							en: "Back",
							fr: "Retour"
						})
					}}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
// See https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue, if the required attribute is not suitable
export default {
	data() {
		return {
			userName: "",
			userNameError: ""
		};
	},
	props: {
		code: String,
		//i18n: Object,
		text: Object
	},
	//	inject: ["languageService"],
	watch: {
		userName: function(newValue, oldValue) {
			// Clear out errors after changes so as not to bug user while re-entering
			if (newValue != oldValue) {
				this.userNameError = "";
			}
		}
	},
	name: "UserPasswordReset",
	methods: {
		requestPasswordReset: function() {
			if (this.userName.length === 0) {
				this.userNameError = this.text.getByKey("userNameRequiredError", {
					default: "User name required",
					en: "User name required",
					fr: "Nom d'utilisateur requis"
				});
				return;
			}
			const { userName } = this;
			this.$emit("requestPasswordReset", { userName, code: this.code });
		},
		cancel: function() {
			this.$emit("cancel");
		}
	}
};
</script>
<style scoped lang="scss">
@import "../scss/constants";

.user-password-reset-wrapper {
	max-width: 100%;
	display: block;
	margin: 0 auto;
	.title {
		font-size: large;
	}
	.instructions {
		font-size: small;
	}
	.form-error {
		font-size: small;
		color: red;
	}
}

@media (max-width: $screen-mobile-max) {
	.user-password-reset-wrapper {
		position: relative;
		width: 80%;
		display: block;
		margin: 0 auto;
		.title {
			font-size: large;
		}
		.instructions {
			font-size: small;
		}
		.form-error {
			font-size: small;
			color: red;
		}
	}
}
</style>
