<template>
	<div class="general-page" :style="applyStyleVariables()">
		<background :src="config.bg" :mobileSrc="config.mobileBg" />
		<div class="page-contents">
			<div v-if="!activePlayerElement" class="video-library-list">
				<div
					v-for="item in config.items"
					:key="item.id"
					class="video-library-item hoverable"
					:style="applyStyleVariablesToIndividualItem(item)"
					v-on:click.stop="turnPlayerOn(item)"
				>
					<div class="item-title">{{ item.title ? item.title : "Item # " + item.id }}</div>
					<div v-for="description in item.descriptions" v-bind:key="description">
						<div class="item-description">{{ description }}</div>
					</div>
				</div>
			</div>
			<div v-else class="player-wrapper">
				<div class="off-button hoverable" v-on:click="turnPlayerOff()">◄ Return to Library</div>
				<svp-player v-bind:playerElement="activePlayerElement" v-bind:gistId="'VideoLibrary' + config.libraryId" />
			</div>
		</div>
	</div>
</template>

<script>
import Background from "../components/Background";
/* eslint-disable no-useless-escape */
import SvpPlayer from "../components/SvpPlayer";
import { log } from "@/logging";

const myLoggingName = "VideoLibrary";
export default {
	name: "VideoLibrary",
	props: {
		config: Object,
		common: Object,
		analyticsContext: Object
	},
	inject: ["authService", "protectedResource"],
	components: { Background, SvpPlayer },
	data: () => {
		return {
			resources: {},
			activePlayerElement: null,
			playerElements: [],
			mounted: false,
			analyticsContexts: {}
		};
	},
	methods: {
		turnPlayerOn(item) {
			let playerElement = this.playerElements.find(i => i.resource.name === item.resourceId);
			this.activePlayerElement = playerElement;
			const context = this.analyticsContexts[playerElement.resource.name];
			context.ingress(new Map());
		},
		turnPlayerOff() {
			const context = this.analyticsContexts[this.activePlayerElement.resource.name];
			context.egress("closed");
			this.activePlayerElement = null;
		},
		clipExistsForItem(item) {
			const rc = this.playerElements.filter(i => i.resource.name === item.resourceId).length > 0;
			if (!rc && this.mounted) {
				log(myLoggingName, `clipsExistsForItem - No clip found for ${item.id}`);
			}
			return rc;
		},
		applyStyleVariables() {
			if (!this.config?.styleVariables) return {};
			const styleVars = {};
			Object.keys(this.config.styleVariables).forEach(key => {
				styleVars["--" + key] = this.config.styleVariables[key];
			});
			return styleVars;
		},
		applyStyleVariablesToIndividualItem(item) {
			if (!item?.styleVariables) return {};
			const styleVars = {};
			Object.keys(item.styleVariables).forEach(key => {
				styleVars["--" + key] = item.styleVariables[key];
			});
			return styleVars;
		},
		addChildrenToAnalytics() {
			this.config.items.forEach(item => {
				const key = item.resourceId.toString();
				const name = item.title ? item.title : "Item # " + item.id;
				this.analyticsContexts[key] = this.analyticsContext.addChild(name);
			});
		}
	},
	mounted() {
		let capturedVue = this;
		this.addChildrenToAnalytics();
		this.authService
			.getToken()
			.then(async token => {
				return await this.protectedResource.getProtectedConfigurationData(token);
			})
			.then(data => {
				const prefix = "Library" + capturedVue.config.libraryId;
				const resources = data[prefix];
				capturedVue.resources = {
					clipData: resources
				};
				// for each item in the data, find the corresponding item in the config items and create a player
				capturedVue.playerElements = resources.map(i => {
					const rc = {
						elementId: prefix + i.name,
						resource: i,
						playerConfig: capturedVue.config.items.find(j => i.name === j.resourceId)
					};
					return rc;
				});
				capturedVue.mounted = true;
			});
	}
};
</script>

<style lang="scss" scoped>
@import "../scss/constants";

.video-library-list {
	position: relative;
	width: 100%;
	height: 100%;

	max-width: 900px;
	margin: 0 auto;

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-items: center;
	align-items: center;
}

.video-library-item {
	width: 100%;
	max-width: 440px;
	position: relative;
	padding: 16px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: linear-gradient(to top right, var(--primary-colour), var(--primary-gradient-colour));
	color: var(--primary-text-colour);

	border-radius: 4px;
	box-shadow: 1px 1px 3px 1px rgba($color: #000000, $alpha: 0.25);
}

.item-title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}

.player-wrapper {
	width: 100%;
	max-width: 1000px;
	margin: 0px auto; //horizontal centering

	display: flex;
	flex-direction: column;
}

.off-button {
	margin-right: auto;
	margin-bottom: 10px;
	padding: 10px 30px;

	border-radius: 4px;
	color: var(--primary-text-colour);
	background: linear-gradient(to top right, var(--primary-colour), var(--primary-gradient-colour));

	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: $screen-mobile-max) {
	.video-library-list {
		grid-template-columns: 1fr;
	}
}
</style>
